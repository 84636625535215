import { Tooltip } from 'antd';
import Iconfont from '../../component/Iconfont';
import { permissionGuard } from '../../permission';

interface ModifyTemplateButtonProps {
  onClick: () => void;
}
function ModifyTemplateButton(props: ModifyTemplateButtonProps) {
  return (
    <Tooltip title="编辑识别信息">
      <Iconfont
        type="iconedit"
        className="cursor-pointer"
        onClick={props.onClick}
      />
    </Tooltip>
  );
}

export default permissionGuard(ModifyTemplateButton);
