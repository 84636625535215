import { Form, Input, message, Modal, Select } from "antd";
import {
  TextFeatureItem,
  type Category,
  type CustomTemplateItem,
} from "../../index.d";
import TemplateIcon from "../../component/TemplateIcon";
import CategoryDropdownRender from "../template/CategoryDropdownRender";
import {
  getCategory,
  updateCustomTemplateInfo,
  updateTemplateBasicInfo,
} from "../../api";
import { useEffect, useState } from "react";
import { CustomTemplateType } from "ai-constants";
import { generateKey } from "../template/keyUtils";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface EditCustomTemplateModalProps {
  visible: boolean;
  item: CustomTemplateItem;
  onCancel: () => void;
  onSave: (item: CustomTemplateItem) => void;
}

export default function EditCustomTemplateModal(
  props: EditCustomTemplateModalProps
) {
  const { visible, item, onCancel, onSave } = props;

  const [form] = Form.useForm();
  const [textFeatureList, setTextFeatureList] = useState<TextFeatureItem[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      temp_name: item.temp_name,
      temp_type: item.temp_type,
    });
    setTextFeatureList(
      item.keyword_list.map((text) => ({ _key: generateKey(), text }))
    );
  }, [item, form]);

  function addTextFeature() {
    setTextFeatureList((list) => [...list, { _key: generateKey(), text: "" }]);
  }

  function updateTextFeature(target: TextFeatureItem) {
    const newList = textFeatureList.map((item) => ({ ...item }));
    const index = newList.findIndex((item) => item._key === target._key);
    if (index !== -1) {
      newList[index] = target;
      setTextFeatureList(newList);
    }
  }

  function removeTextFeature(target: TextFeatureItem) {
    setTextFeatureList((list) =>
      list.filter((item) => item._key !== target._key)
    );
  }

  async function onFinish(value: any) {
    setLoading(true);
    const newItem = { ...item, ...value };
    newItem.keyword_list = textFeatureList.map((item) => item.text);
    const res = await updateCustomTemplateInfo({
      ...value,
      keyword_list: newItem.keyword_list,
    });

    if (res && res.errcode === 0) {
      onCancel();
      onSave(newItem);
      message.success("保存成功");
    } else {
      message.error("保存失败");
    }
    setLoading(false);
  }

  return (
    <Modal
      onOk={() => form.submit()}
      onCancel={onCancel}
      confirmLoading={loading}
      visible={visible}
      title="修改模板信息"
      width="560px"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
      >
        <Form.Item
          label="模板名称"
          name="temp_name"
          rules={[
            {
              required: true,
              message: "请输入模板名称!",
            },
          ]}
        >
          <Input disabled placeholder="请输入模板名称" />
        </Form.Item>

        <Form.Item name="temp_type" label="模板分类">
          <Select className="w-full">
            {CustomTemplateType.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="text-gray-700  border-2 border-dashed border-gray-700 hover:text-primary hover:border-primary mb-4">
          <div
            className="text-center cursor-pointer"
            style={{ lineHeight: "36px" }}
            onClick={() => addTextFeature()}
          >
            <PlusOutlined className="text-base" />
            &nbsp; 添加特征
          </div>
        </div>
        {textFeatureList.length > 0 && <div className="mb-4">包含文本</div>}
        <div
          className="overflow-y-auto  bg-white"
          style={{ maxHeight: `calc(100vh - 358px)` }}
        >
          <ul>
            {textFeatureList.map((item) => (
              <li
                key={item._key}
                className="flex items-center mb-2 text-red-500"
              >
                <Input
                  value={item.text}
                  onChange={(e) =>
                    updateTextFeature({ text: e.target.value, _key: item._key })
                  }
                />
                <CloseCircleOutlined
                  className="px-2 "
                  onClick={() => removeTextFeature(item)}
                />
              </li>
            ))}
          </ul>
        </div>
      </Form>
    </Modal>
  );
}
