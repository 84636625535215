import { Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import type { AiTemplate } from '../index.d';
import { getTemplateIcon } from '../utils';
import Iconfont from './Iconfont';

interface AiTemplateCardProps {
  item: AiTemplate;
  showModal: (item: AiTemplate) => void;
  feedback: (item: AiTemplate) => void;
}

export default function AiTemplateCard(props: AiTemplateCardProps) {
  const navigate = useNavigate();
  const { item, showModal, feedback } = props;
  const [type, bg] = getTemplateIcon(item.icon);
  return (
    <div className="bg-white mb-4 mr-4 rounded-sm border border-gray-300 card">
      <div className="flex content p-6 ">
        <div className="h-12 rounded p-1 mr-4" style={{ backgroundColor: bg }}>
          <Iconfont type={type} />
        </div>
        <div>
          <div className="text-base text-gray-800 mb-2 font-medium">
            {item.name}
          </div>
          <div style={{ height: 66 }} className="text-gray-600 overflow-hidden">
            {item.description}
          </div>
        </div>
      </div>
      <div className="flex bg-gray-100 h-10 text-center">
        <Action
          tooltip="调用记录"
          iconType="iconlist"
          last={false}
          onClick={() =>
            navigate(`/general-records/${item.id}?name=${item.name}`)
          }
        />
        <Action
          tooltip="调用模板"
          iconType="iconlink"
          last={false}
          onClick={() => showModal(item)}
        />
        <Action
          tooltip="问题反馈"
          iconType="icondialog"
          last={true}
          onClick={() => feedback(item)}
        />
      </div>
    </div>
  );
}

interface ActionProps {
  last: boolean;
  tooltip: string;
  iconType: string;
  onClick: () => void;
}
function Action(props: ActionProps) {
  const { last, tooltip, iconType, onClick } = props;
  const cls = ['flex-1', 'leading-10'];
  if (!last) {
    cls.push('border-r');
    cls.push('border-gray-300');
  }
  return (
    <div className={cls.join(' ')}>
      <Tooltip title={tooltip}>
        <Iconfont type={iconType} onClick={onClick} />
      </Tooltip>
    </div>
  );
}
