import type { TableOpticalCharacterRecognitionResult } from './index.d';
import { withPrefix } from './http';
import { getAuth } from 'utils/auth';

let tableOpticalCharacterRecognitionResults: TableOpticalCharacterRecognitionResult[] =
  [];

export function clearOpticalCharacterRecognitionResults() {
  tableOpticalCharacterRecognitionResults = [];
}

export function getTableOpticalCharacterRecognitionResult(
  pageIndex: number,
  tableIndex: number,
  boxNumber: number
) {
  const target = tableOpticalCharacterRecognitionResults.find(
    (item) =>
      item.pageIndex === pageIndex &&
      item.tableIndex === tableIndex &&
      item.boxNumber === boxNumber
  );
  if (target) {
    return target.result;
  }

  return false;
}

export function setTableOpticalCharacterRecognitionResult(
  pageIndex: number,
  tableIndex: number,
  boxNumber: number,
  result: string
) {
  const target = getTableOpticalCharacterRecognitionResult(
    pageIndex,
    tableIndex,
    boxNumber
  );
  if (target) {
    return;
  }

  const payload = {
    pageIndex,
    tableIndex,
    boxNumber,
    result,
  };

  tableOpticalCharacterRecognitionResults.push(payload);
}

export async function getOCR(blob: Blob, name = 'feature.png') {
  const file = new File([blob], name);

  const fd = new FormData();
  fd.append('file', file);
  const h = new Headers();
  const auth = getAuth();
  if (auth?.token) {
    h.append('Authorization', `Bearer ${auth.token}`);
  }
  const res = await fetch(withPrefix('/ocr/byte'), {
    method: 'POST',
    headers: h,
    body: fd,
  });
  return res.json();
}
