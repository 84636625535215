import { Rect } from '../../index.d';
import { STROKE } from './constants';

interface TableRectProps {
  rect: Rect;
  id: string;
  show: boolean;
  onClick: () => void;
}
export default function TableRect(props: TableRectProps) {
  const { show, rect, id, onClick } = props;
  const stroke = show ? 'transparent' : STROKE;
  const rectColor = show ? 'text-green-200' : 'text-transparent';
  const cls = ['text-opacity-50 fill-current cursor-pointer'];
  cls.push(rectColor);
  return (
    <rect
      id={id}
      width={rect.w}
      height={rect.h}
      x={rect.x}
      y={rect.y}
      stroke={stroke}
      className={cls.join(' ')}
      onClick={onClick}
      strokeWidth="2"
    ></rect>
  );
}
