import { useRequest } from "ahooks";
import { PageContainer } from "@ant-design/pro-layout";
import {
  Divider,
  Spin,
  Tabs,
  Form,
  Input,
  Select,
  Modal,
  Upload,
  message,
  Button,
} from "antd";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  AiTemplate,
  Category,
  TemplateItem,
  CustomTemplateItem as CustomTemplateItemType,
  CustomTemplateData,
} from "../../index.d";
import {
  callOCR,
  capabilityList,
  getCustomTemplateList,
  deleteCategory,
  getCategory,
  getTaskResult,
  templateList,
  updateCategory,
  customTemplateOcrTest,
  tableTemplateOcrTest,
} from "../../api";
import AiTemplateCard from "../../component/AiTemplateCard";
import LogoutButton from "../../component/LogoutButton";
import TagRadio from "../../component/TagRadio";
import {
  TAB_PANE_CUSTOM,
  TAB_PANE_GENERAL,
  TAB_PANE_INDEX,
  TAB_PANE_TABLE,
} from "ai-constants";
import AddTemplateButton from "./AddTemplateButton";
import CustomTemplateItem from "./CustomTemplateItem";
import TableTemplateItem from "./TableTemplateItem";
import EditTemplateModal from "./EditTemplateModal";
import ManageCategory, { ManageCategoryContent } from "./ManageCategory";
import FeedbackModal from "./FeedbackModal";
import HomePage from "./HomePage";
import {
  CUSTOM_OCR_ACTIVITY,
  CUSTOM_OCR_API,
  ocr_api,
  ocr_handbook,
} from "./utils";
import http, { isOk, post } from "../../http";
import {
  getCustomTemplateListFromResponse,
  getFileType,
  stringify,
} from "../../utils";
import { uploadApi } from "../../config";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useDebounce } from "ahooks";
import UploadButton, { UploadText } from "../../component/UploadButton";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { getToken } from "utils/auth";
import { getUploadResponseBody } from "../../upload";
import "./index.css";
import { Permission } from "../../permission";
import { useSearchParams } from "react-router-dom";
import { generateKey } from "../template/keyUtils";
import EditCustomTemplateModal from "./EditCustomTemplateModal";

async function testGeneralItem(url: string, payload: any) {
  return http.post(url, payload);
}

interface AiPowerProps {
  //
}
export default function AiPower(props: AiPowerProps) {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  const [categoryForm] = Form.useForm();

  const queryTaskResultTimerId = useRef<NodeJS.Timeout | null>(null);
  const customTemplateItemRef = useRef<CustomTemplateItemType | null>(null);
  const tableTemplateItemRef = useRef<TemplateItem | null>(null);
  /*============================== State ==============================*/

  const [customTemplateItem, setCustomTemplateItem] =
    useState<CustomTemplateItemType>({
      _key: generateKey(),
      temp_name: "",
      temp_type: "",
      keyword_list: [],
      data_list: [],
      img_height: 0,
      img_width: 0,
      img_url: "",
    });
  const [showGeneralItemTestButton, setShowGeneralItemTestButton] =
    useState(true);

  const [feedbackPayload, setFeedbackPayload] = useState({ id: "1", type: -1 });

  const [templateItem, setTemplateItem] = useState<TemplateItem>({
    template_classify: -1,
    name: "",
    icon: "",
    description: "",
    isDeactivate: 1,
    templateSign: "",
  });

  const [categoryModalVisible, setCategoryModalVisible] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);

  const [customTemplateEditModalVisible, setCustomTemplateEditModalVisible] =
    useState(false);

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

  const [activeKey, setActiveKey] = useState(TAB_PANE_INDEX);

  const [queryName, setQueryName] = useState("");

  const debouncedQueryName = useDebounce(queryName, { wait: 200 });

  const [customQueryName, setCustomQueryName] = useState("");
  const debouncedCustomQueryName = useDebounce(customQueryName, { wait: 200 });

  const [query, setQuery] = useState<{ classifyId?: number; status: number }>({
    status: -1,
  });

  const [category, setCategory] = useState<Category[]>([]);

  const [generalList, setGeneralList] = useState<any[]>([]);
  const [tableTemplateList, setTableTemplateList] = useState<TemplateItem[]>(
    []
  );
  const [customTemplateList, setCustomTemplateList] = useState<
    CustomTemplateItemType[]
  >([]);

  const customTemplateFilterList = useMemo(() => {
    let reg = new RegExp(debouncedCustomQueryName, "i");
    return customTemplateList.filter((item) => reg.test(item.temp_name));
  }, [customTemplateList, debouncedCustomQueryName]);

  // const [hasUserCategory, setHasUserCategory] = useState(false);

  // const [customItem, setCustomItem] = useState<TemplateItem>({
  //   template_classify: -1,
  //   name: '',
  //   icon: '',
  //   description: '',
  //   isDeactivate: 1,
  //   templateSign: '',
  // });

  const [generalItem, setGeneralItem] = useState<AiTemplate>({
    id: "",
    name: "",
    icon: "",
    url: "",
    description: "",
  });

  const [testGeneralFileList, setTestGeneralFileList] = useState<UploadFile[]>(
    []
  );

  const [testGeneralItemResult, setTestGeneralItemResult] = useState({
    text: "",
  });

  const [testCustomFileList, setTestCustomFileList] = useState<UploadFile[]>(
    []
  );

  const [testCustomItemResult, setTestCustomItemResult] = useState({
    text: "",
  });

  const [generalItemModalVisible, setShowGeneralItemModalVisible] =
    useState(false);

  const [customItemModalVisible, setCustomItemVisible] = useState(false);

  const [tableItemModalVisible, setTableItemVisible] = useState(false);

  const [showCustomItemTestButton, setShowCustomItemTestButton] =
    useState(true);

  const [testCustomItemUploading, setTestCustomItemUploading] = useState(false);
  const [testGeneralItemUploading, setTestGeneralItemUploading] =
    useState(false);

  /*============================== Request ==============================*/

  const tableTemplateListRequest = useRequest(
    () =>
      templateList({
        classifyId: query.classifyId,
        templateName: debouncedQueryName,
        templateStatus: query.status,
      }),
    {
      refreshDeps: [query, debouncedQueryName],
    }
  );

  const customTemplateListRequest = useRequest(
    () => getCustomTemplateList({ name: debouncedCustomQueryName }),
    { refreshDeps: [] }
  );

  const generalListRequest = useRequest(capabilityList);

  const categoryRequest = useRequest(getCategory);

  const testGeneralItemRequest = useRequest(testGeneralItem, { manual: true });

  const testCustomItemRequest = useRequest(callOCR, { manual: true });

  const updateCategoryRequest = useRequest(updateCategory, { manual: true });

  /*============================== Effect ==============================*/

  useEffect(() => {
    setActiveKey(tab || TAB_PANE_GENERAL);
  }, [tab]);

  useEffect(() => {
    if (isOk(categoryRequest.data)) {
      // setHasUserCategory(categoryRequest.data.data.list.length > 1);
      const category = [...categoryRequest.data.data.list];
      setCategory(category);
    }
  }, [categoryRequest.data]);

  useEffect(() => {
    if (isOk(generalListRequest.data)) {
      setGeneralList(generalListRequest.data.data.list);
    }
  }, [generalListRequest.data]);

  useEffect(() => {
    if (isOk(tableTemplateListRequest.data)) {
      setTableTemplateList(tableTemplateListRequest.data.data.list);
    }
  }, [tableTemplateListRequest.data]);

  useEffect(() => {
    if (isOk(customTemplateListRequest.data)) {
      const { data } = customTemplateListRequest.data;

      const list = getCustomTemplateListFromResponse(data);
      setCustomTemplateList(list);
    }
  }, [customTemplateListRequest.data]);

  useEffect(() => {
    if (isOk(testCustomItemRequest.data)) {
      const res = testCustomItemRequest.data.data;
      const text = res.text ? res.text : stringify(res.results);

      setTestCustomItemResult({ text });
    }
  }, [testCustomItemRequest.data]);

  useEffect(() => {
    if (isOk(testGeneralItemRequest.data)) {
      const res = testGeneralItemRequest.data.data;
      const text = res.text ? res.text : stringify(res.results);

      setTestCustomItemResult({ text });
    }
  }, [testGeneralItemRequest.data]);

  useEffect(() => {
    if (!generalItemModalVisible) {
      setTestGeneralFileList([]);
      setTestGeneralItemResult({ text: "" });
      setShowGeneralItemTestButton(true);
    }
  }, [generalItemModalVisible]);

  useEffect(() => {
    if (!customItemModalVisible) {
      setTestCustomFileList([]);
      setTestCustomItemResult({ text: "" });
      setShowCustomItemTestButton(true);
    }
  }, [customItemModalVisible]);

  /*============================== Handler ==============================*/
  function onTabChange(key: string) {
    setActiveKey(key);
    setSearchParams({ tab: key });
  }
  function handleUse() {
    setActiveKey("1");
    setSearchParams({ tab: "1" });
  }
  // function handleOpenAPI(){

  // }

  function showModal(item: any) {
    setGeneralItem(item);
    setShowGeneralItemModalVisible(true);
  }

  function showFeedbackModal(item: any) {
    // TODO:
    setFeedbackModalVisible(true);
    setFeedbackPayload({ id: item.id, type: 1 });
  }

  function onQueryNameChange(e: ChangeEvent<HTMLInputElement>) {
    setQueryName(e.target.value);
  }

  function onQueryChange(value: number) {
    const newQuery = { ...query };
    newQuery.status = value;

    setQuery(newQuery);
  }

  function onCategoryChange(id: any) {
    if (query.classifyId === id) {
      return;
    }

    const newQuery = { ...query };

    newQuery.classifyId = id;

    setQuery(newQuery);
  }

  function onCategoryEdit(item: Category) {
    setCategoryModalVisible(true);
    categoryForm.setFieldsValue({
      id: item.id,
      classifyName: item.name,
      description: item.description,
    });
  }

  function onCategoryDelete(item: Category) {
    Modal.confirm({
      title: `确定要删除分类 ${item.name} 吗`,
      content: (
        <span>
          删除后该分类信息将无法恢复
          <br />
          同时该分类下的 {item.templateCount} 个模版将移动到默认分类
        </span>
      ),
      cancelText: "取消",
      okText: "确定",
      okType: "danger",
      icon: <CloseCircleOutlined />,
      onOk: async function onOk() {
        const res = await deleteCategory({ id: item.id });
        if (isOk(res)) {
          if (item.id === query.classifyId) {
            setQuery({ status: query.status });
          }

          message.success("删除成功");

          const newCategory = category.filter((i) => i.id !== item.id);

          setCategory(newCategory);
        } else {
          message.error("删除失败");
        }
      },
    });
  }

  function saveCustomTemplate(target: CustomTemplateItemType) {
    const newList = customTemplateList.map((i) => ({ ...i }));
    const index = newList.findIndex((item) => item._key === target._key);
    if (index !== -1) {
      newList[index] = target;
      setCustomTemplateList(newList);
    }
  }

  function saveTemplate(item: TemplateItem) {
    const newTableTemplateList = tableTemplateList.map((i) => ({ ...i }));
    const index = newTableTemplateList.findIndex(
      (i) => i.templateSign === item.templateSign
    );
    if (index !== -1) {
      newTableTemplateList[index] = Object.assign(
        newTableTemplateList[index],
        item
      );
      setTableTemplateList(newTableTemplateList);
    }
  }

  function addTableTemplate() {
    navigate("/template/table/new");
  }

  function addCustomTemplate() {
    navigate("/template/custom/new");
  }

  async function onCategoryNameModify(values: any) {
    // TODO:
    const res = await updateCategoryRequest.runAsync(values);

    if (isOk(res)) {
      message.success("修改成功");
      setCategoryModalVisible(false);
      const newCategory = category.map((i) => ({ ...i }));
      const target = newCategory.find((i) => i.id === values.id);
      if (target) {
        target.name = values.classifyName;
        setCategory(newCategory);
      }
    }
  }

  function onCustomItemTestClick() {
    setShowCustomItemTestButton(false);
  }

  function onGeneralItemTestClick() {
    setShowGeneralItemTestButton(false);
  }

  async function queryTaskResult(id: string) {
    const res = await getTaskResult(id);
    if (isOk(res)) {
      const data = res.data;
      if (data.status === "SUCCESS" || data.results) {
        setTestCustomItemResult({ text: stringify(data.results) });
        setTestCustomItemUploading(false);
        return;
      }
      queryTaskResultTimerId.current = setTimeout(() => {
        queryTaskResult(id);
      }, 1e4);
    } else {
      message.error(res.errmsg);
      setTestCustomItemUploading(false);
    }
  }

  async function handleTableTemplateItemTest(info: UploadChangeParam) {
    if (!tableTemplateItemRef.current) return;
    const { file, fileList } = info;
    setTestCustomFileList(fileList);
    if (file.status === "done") {
      const response = file.response;
      if (isOk(response)) {
        setTestCustomItemUploading(true);
        const uploadResponse = getUploadResponseBody(response.data);
        const res = await tableTemplateOcrTest({
          templateSign: tableTemplateItemRef.current.templateSign,
          file_type: getFileType(uploadResponse.url),
          file_url: uploadResponse.url,
        });

        if (isOk(res)) {
          queryTaskResult(res.data.task_id);
        } else {
          setTestCustomItemUploading(false);
          message.error(res.errmsg || "识别失败");
        }
      }
    }
  }

  async function handleCustomItemTestFileUpload(info: UploadChangeParam) {
    if (!customTemplateItemRef.current) return;
    const { file, fileList } = info;
    setTestCustomFileList(fileList);
    if (file.status === "done") {
      const response = file.response;
      if (isOk(response)) {
        setTestCustomItemUploading(true);
        const uploadResponse = getUploadResponseBody(response.data);
        const res = await customTemplateOcrTest({
          img_url: uploadResponse.url,
          temp_type: customTemplateItemRef.current.temp_type,
          temp_id: customTemplateItemRef.current.temp_id as string,
        });

        if (isOk(res)) {
          setTestCustomItemResult({text: stringify(res.data)});
          setTestCustomItemUploading(false);
        } else {
          setTestCustomItemUploading(false);
          message.error(res.errmsg || "识别失败");
        }
      }
    }
  }

  async function handleGeneralItemTestFileUpload(info: UploadChangeParam) {
    const { file, fileList } = info;

    setTestGeneralFileList(fileList);

    if (file.status === "done") {
      if (isOk(file.response)) {
        setTestGeneralItemUploading(true);
        const data = file.response.data;
        const uploadResponse = getUploadResponseBody(data);
        const payload = {
          url: uploadResponse.url,
          realtime: 1, // ocr 接口属于队列任务，新增 realtime 确保测试接口实现响应
        };

        let url = generalItem.url;

        const request = await fetch(url, post(payload, getToken()));
        const res = await request.json();
        if (isOk(res)) {
          let text = res.data.text
            ? res.data.text
            : stringify(res.data.results);
          setTestGeneralItemResult({ text });
        } else {
          message.error(res.errmsg || "识别失败");
        }
        setTestGeneralItemUploading(false);
      }
    }
  }

  function onCustomTemplateDelete(item: CustomTemplateItemType) {
    const newCustomTemplateList = customTemplateList.filter(
      (i) => i._key !== item._key
    );
    setCustomTemplateList(newCustomTemplateList);
  }

  function onTemplateDelete(item: TemplateItem) {
    const newTableTemplateList = tableTemplateList.filter(
      (i) => i.templateSign !== item.templateSign
    );
    setTableTemplateList(newTableTemplateList);
  }

  function onCustomTemplateModify(item: CustomTemplateItemType) {
    customTemplateItemRef.current = item;
    setCustomTemplateItem({ ...item });
    setCustomTemplateEditModalVisible(true);
  }

  function onTemplateModify(item: TemplateItem) {
    setTemplateItem(item);
    setEditModalVisible(true);
  }

  function toggleTemplateActive(item: TemplateItem) {
    const newTableTemplateList = tableTemplateList.map((i) => ({ ...i }));

    const target = newTableTemplateList.find(
      (i) => i.templateSign === item.templateSign
    );

    if (target) {
      target.isDeactivate = item.isDeactivate === 0 ? 1 : 0;

      setTableTemplateList(newTableTemplateList);
    }
  }

  function onCustomTemplateTest(item: CustomTemplateItemType) {
    customTemplateItemRef.current = item;
    setCustomItemVisible(true);
  }

  function onTemplateTest(item: TemplateItem) {
    setTemplateItem(item);
    setTableItemVisible(true);
  }

  function addCategory(item: Category) {
    const newCategory = category.map((i) => ({ ...i }));
    newCategory.push(item);
    setCategory(newCategory);
  }

  // const spinning = tableTemplateListRequest.loading;
  const spinning =
    tableTemplateListRequest.loading || generalListRequest.loading;
  return (
    <div style={{ backgroundColor: "#F0F2F5", minHeight: "100vh" }}>
      <div className="-mx-6 px-6">
        <Spin spinning={spinning}>
          <Tabs activeKey={activeKey} onChange={onTabChange} id="ai-template">
            <Tabs.TabPane tab="首页" key={TAB_PANE_INDEX}>
              <HomePage onUse={handleUse}></HomePage>
            </Tabs.TabPane>
            <Tabs.TabPane tab="通用模板" key={TAB_PANE_GENERAL}>
              <div className="flex flex-wrap px-6">
                <ul className="flex flex-wrap">
                  {generalList.map((i) => (
                    <AiTemplateCard
                      key={i.id}
                      item={i}
                      showModal={showModal}
                      feedback={showFeedbackModal}
                    />
                  ))}
                </ul>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="自定义模板" key={TAB_PANE_CUSTOM}>
              <div className="px-6">
                <div className="bg-white p-6">
                  {/* <div className="mb-4 flex overflow-hidden">
                    <div
                      style={{
                        lineHeight: "32px",
                        minWidth: "70px",
                        marginRight: "24px",
                      }}
                    >
                      所属分类：
                    </div>
                    <TagRadio
                      className="flex-1"
                      value={query.classifyId}
                      onChange={onCategoryChange}
                      expandable
                    >
                      {[{ id: -1, name: "全部" }].concat(category).map((i) => (
                        <TagRadio.Option key={i.id} value={i.id}>
                          {i.name}
                        </TagRadio.Option>
                      ))}
                      {category.length === 1 ? null : (
                        <ManageCategory
                          mode={Permission.MANAGE}
                          content={
                            <ManageCategoryContent
                              onDelete={onCategoryDelete}
                              onEdit={onCategoryEdit}
                              category={category}
                            />
                          }
                        />
                      )}
                    </TagRadio>
                  </div>
                  <Divider className="normal" /> */}
                  <div className="flex flex-wrap template-search-form">
                    <Form.Item label="模板名称">
                      <Input
                        placeholder="请输入"
                        onChange={(e) => setCustomQueryName(e.target.value)}
                        style={{ width: 272 }}
                        allowClear={false}
                        value={customQueryName}
                      />
                    </Form.Item>
                    {/* <Form.Item label="模板状态">
                      <Select
                        placeholder="搜索状态"
                        value={query.status}
                        style={{ width: 272 }}
                        onChange={onQueryChange}
                      >
                        <Select.Option value={-1}>全部</Select.Option>
                        <Select.Option value={0}>启用</Select.Option>
                        <Select.Option value={1}>禁用</Select.Option>
                      </Select>
                    </Form.Item> */}
                  </div>
                </div>
                <div className="mt-6">
                  <ul className="flex flex-wrap">
                    <AddTemplateButton
                      mode={Permission.MANAGE}
                      onClick={addCustomTemplate}
                    />
                    {customTemplateFilterList.map((i) => (
                      <li key={i.temp_id}>
                        <CustomTemplateItem
                          onTemplateTest={onCustomTemplateTest}
                          onTemplateModify={onCustomTemplateModify}
                          // toggleTemplateActive={toggleTemplateActive}
                          onTemplateDelete={onCustomTemplateDelete}
                          item={i}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="表格模板" key={TAB_PANE_TABLE}>
              <div className="px-6">
                <div className="bg-white p-6">
                  <div className="mb-4 flex overflow-hidden">
                    <div
                      style={{
                        lineHeight: "32px",
                        minWidth: "70px",
                        marginRight: "24px",
                      }}
                    >
                      所属分类：
                    </div>
                    <TagRadio
                      className="flex-1"
                      value={query.classifyId}
                      onChange={onCategoryChange}
                      expandable
                    >
                      {[{ id: -1, name: "全部" }].concat(category).map((i) => (
                        <TagRadio.Option key={i.id} value={i.id}>
                          {i.name}
                        </TagRadio.Option>
                      ))}
                      {category.length === 1 ? null : (
                        <ManageCategory
                          mode={Permission.MANAGE}
                          content={
                            <ManageCategoryContent
                              onDelete={onCategoryDelete}
                              onEdit={onCategoryEdit}
                              category={category}
                            />
                          }
                        />
                      )}
                    </TagRadio>
                  </div>
                  <Divider className="normal" />
                  <div className="flex flex-wrap template-search-form">
                    <Form.Item label="模板名称">
                      <Input
                        placeholder="请输入"
                        onChange={onQueryNameChange}
                        style={{ width: 272 }}
                        allowClear={false}
                        value={queryName}
                      />
                    </Form.Item>
                    <Form.Item label="模板状态">
                      <Select
                        placeholder="搜索状态"
                        value={query.status}
                        style={{ width: 272 }}
                        onChange={onQueryChange}
                      >
                        <Select.Option value={-1}>全部</Select.Option>
                        <Select.Option value={0}>启用</Select.Option>
                        <Select.Option value={1}>禁用</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="mt-6">
                  <ul className="flex flex-wrap">
                    <AddTemplateButton
                      mode={Permission.MANAGE}
                      onClick={addTableTemplate}
                    />
                    {tableTemplateList.map((i) => (
                      <li key={i.templateSign}>
                        <TableTemplateItem
                          onTemplateTest={onTemplateTest}
                          onTemplateModify={onTemplateModify}
                          toggleTemplateActive={toggleTemplateActive}
                          onTemplateDelete={onTemplateDelete}
                          item={i}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </div>

      <EditCustomTemplateModal
        onSave={saveCustomTemplate}
        onCancel={() => setCustomTemplateEditModalVisible(false)}
        visible={customTemplateEditModalVisible}
        item={customTemplateItem}
      />

      <EditTemplateModal
        onSave={saveTemplate}
        onCancel={() => setEditModalVisible(false)}
        addCategory={addCategory}
        category={category}
        visible={editModalVisible}
        item={templateItem}
      />

      <FeedbackModal
        onHide={() => setFeedbackModalVisible(false)}
        feedbackPayload={feedbackPayload}
        visible={feedbackModalVisible}
      />

      <Modal
        width="560px"
        title="修改模板分类"
        okText="保存"
        visible={categoryModalVisible}
        onCancel={() => setCategoryModalVisible(false)}
        onOk={() => categoryForm.submit()}
        confirmLoading={updateCategoryRequest.loading}
      >
        <Form form={categoryForm} onFinish={onCategoryNameModify}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="description">
            <Input />
          </Form.Item>
          <Form.Item
            label="分类名称"
            name="classifyName"
            rules={[{ required: true, message: "请输入分类名称" }]}
          >
            <Input placeholder="请输入分类名称" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width="560px"
        title="调用模板"
        onCancel={() => setShowGeneralItemModalVisible(false)}
        footer={
          <Button onClick={() => setShowGeneralItemModalVisible(false)}>
            关闭
          </Button>
        }
        visible={generalItemModalVisible}
      >
        <div>
          <div className="mb-2">通过以下两种方式使用「{generalItem.name}」</div>
          <div className="mb-2">
            <span className="inline-block h-6 w-6 mr-2 leading-6 text-white text-center bg-primary  rounded-full">
              1
            </span>
            在建模器中直接使用 Activity 使用
            <a
              rel="noreferrer"
              target="_blank"
              href={ocr_handbook(generalItem.id)}
              className="text-primary ml-2"
            >
              使用手册
            </a>
          </div>
          <div className="mb-2">
            <span className="inline-block h-6 w-6 mr-2 leading-6 text-white text-center bg-primary  rounded-full">
              2
            </span>
            调用指定接口使用
            <a
              rel="noreferrer"
              target="_blank"
              href={ocr_api(generalItem.id)}
              className="text-primary ml-2"
            >
              接口文档
            </a>
          </div>

          {showGeneralItemTestButton ? (
            <div className="ml-8">
              <button className="text-primary" onClick={onGeneralItemTestClick}>
                测试接口
              </button>
            </div>
          ) : (
            <div className="bg-gray-200 p-2 ml-8">
              <div>
                <div className="mb-2 text-gray-700">
                  上传识别图片（支持PNG、JPG、JPEG、BMP，大小不超过4M）：
                </div>
                <div>
                  <Upload
                    fileList={testGeneralFileList}
                    listType="picture-card"
                    onChange={handleGeneralItemTestFileUpload}
                    action={uploadApi}
                    headers={{ Authorization: `Bearer ${getToken()}` }}
                    maxCount={1}
                  >
                    <div>
                      <UploadButton loading={testGeneralItemUploading} />
                      <UploadText loading={testGeneralItemUploading} />
                    </div>
                  </Upload>
                </div>
                <Spin spinning={testGeneralItemUploading}>
                  <div>
                    <div className="text-gray-700 mb-2">识别结果：</div>
                    <Input.TextArea
                      rows={6}
                      readOnly
                      value={testGeneralItemResult.text}
                    />
                  </div>
                </Spin>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="调用模板"
        visible={tableItemModalVisible}
        onCancel={() => setTableItemVisible(false)}
        width="560px"
        footer={
          <Button onClick={() => setTableItemVisible(false)}>关闭</Button>
        }
      >
        <div className="mb-2">通过以下两种方式使用「{templateItem.name}」</div>
        <div className="mb-2">
          <span className="inline-block h-6 w-6 mr-2 leading-6 text-white text-center bg-primary  rounded-full">
            1
          </span>
          在建模器中直接使用 Activity 使用
          <a
            rel="noreferrer"
            target="_blank"
            href={CUSTOM_OCR_ACTIVITY}
            className="text-primary ml-2"
          >
            使用手册
          </a>
        </div>
        <div className="mb-2">
          <span className="inline-block h-6 w-6 mr-2 leading-6 text-white text-center bg-primary  rounded-full">
            2
          </span>
          调用指定接口使用
          <a
            rel="noreferrer"
            target="_blank"
            href={CUSTOM_OCR_API}
            className="text-primary ml-2"
          >
            接口文档
          </a>
        </div>
        <div className="mb-2 ml-8">
          <span>模板ID：</span>
          <span>{templateItem.templateSign}</span>
        </div>
        {showCustomItemTestButton ? (
          <div className="ml-8">
            <button className="text-primary" onClick={onCustomItemTestClick}>
              测试接口
            </button>
          </div>
        ) : (
          <div className="bg-gray-200 p-2 ml-8">
            <div className="mb-2 text-gray-700">
              上传识别文件（支持PNG、PDF、JPG格式）：
            </div>
            <div>
              <Upload
                fileList={testCustomFileList}
                onChange={handleTableTemplateItemTest}
                action={uploadApi}
                headers={{ Authorization: `Bearer ${getToken()}` }}
                maxCount={1}
              >
                <Button className="flex items-center mb-2">
                  <UploadOutlined />
                  上传文件
                </Button>
              </Upload>
            </div>
            <Spin spinning={testCustomItemUploading}>
              <div className="mb-2">识别结果：</div>
              <Input.TextArea
                className="ant-input-add-padding"
                readOnly
                rows={6}
                value={testCustomItemResult.text}
              />
            </Spin>
          </div>
        )}
      </Modal>
      <Modal
        title="调用模板"
        visible={customItemModalVisible}
        onCancel={() => setCustomItemVisible(false)}
        width="560px"
        footer={
          <Button onClick={() => setCustomItemVisible(false)}>关闭</Button>
        }
      >
        <div className="">
          <div className="mb-2 text-gray-700">
            上传识别文件（支持PNG、JPG格式）：
          </div>
          <div>
            <Upload
              fileList={testCustomFileList}
              onChange={handleCustomItemTestFileUpload}
              action={uploadApi}
              headers={{ Authorization: `Bearer ${getToken()}` }}
              maxCount={1}
            >
              <Button className="flex items-center mb-2">
                <UploadOutlined />
                上传文件
              </Button>
            </Upload>
          </div>
          <Spin spinning={testCustomItemUploading}>
            <div className="mb-2">识别结果：</div>
            <Input.TextArea
              className="ant-input-add-padding"
              readOnly
              rows={6}
              value={testCustomItemResult.text}
            />
          </Spin>
        </div>
      </Modal>
    </div>
  );
}
