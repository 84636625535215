import { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { register, login } from '../../../api';
import { setAuth } from 'utils/auth';
import { useNavigate } from 'react-router';

interface UserRegisterProps {}
export default function UserRegister(props: UserRegisterProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function handleSubmit(values: { username: string; password: string }) {
    try {
      setLoading(true);
      const res = await register(values);
      if (res.errcode === 0) {
        const loginRes = await login(values);
        if (loginRes.errcode === 0) {
          setAuth({
            user_id: loginRes.data.user_id,
            token: loginRes.data.access_token,
          });
          navigate('/');
        }
      }
    } catch (error) {}
    setLoading(false);
  }

  return (
    <div className="login-background flex h-screen">
      <div className="mx-auto" style={{ marginTop: '106px', width: '432px' }}>
        <div className="flex justify-center mb-3">
          <img
            style={{ width: '48px', height: '43.5px' }}
            src="/favicon.png"
            alt="logo"
          />
          <span
            style={{ fontSize: '30px' }}
            className="ml-4 text-gray-800 font-bold"
          >
            智慧流程 释放潜能
          </span>
        </div>
        <div className="text-gray-600 text-center mb-8">
          为企业数字化、智能化转型赋能，打开进入智慧时代的大门
        </div>
        <div
          className="rounded"
          style={{
            boxShadow: '0 0 4px 2px #d9d9d9',
            paddingBottom: '40px',
            paddingTop: '24px',
          }}
        >
          <h1 className="mb-4 text-center text-2xl gray-text-200">AI power</h1>
          <div style={{ width: '368px' }} className="mb-8 mx-auto">
            <span className="pb-2 text-green-500 border-b-2  border-green-500">
              账号注册
            </span>
          </div>
          <div className="mx-auto" style={{ width: '368px' }}>
            <Form onFinish={handleSubmit}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入账号' }]}
              >
                <Input
                  style={{ height: '40px' }}
                  placeholder="用户名"
                  prefix={<UserOutlined className="gray-text-50" />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password
                  style={{ height: '40px' }}
                  placeholder="密码"
                  prefix={<LockOutlined className="gray-text-50" />}
                />
              </Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                style={{ height: '40px' }}
                className="text-base w-full"
                type="primary"
              >
                注册
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
