import { Input, Select } from 'antd';
import { ChangeEvent } from 'react';
import type { HeadFootText } from '../index.d';
import {
  FOOT_LOCATION,
  HEAD_LOCATION,
  TEXT_CONTAIN,
  TEXT_EQUAL,
} from '../pages/template/constants';
import { getHeadFootTitle } from '../pages/template/utils';
import Iconfont from './Iconfont';

interface TableHeadFootFeatureItemProps {
  item: HeadFootText;
  setItem: (item: HeadFootText) => void;
  removeItem: (item: HeadFootText) => void;
}

export default function TableHeadFootFeatureItem(
  props: TableHeadFootFeatureItemProps
) {
  const { item, setItem, removeItem } = props;

  function onDataTypeChange(value: any) {
    const newItem = { ...item };
    newItem.data_type = value;
    setItem(newItem);
  }

  function onTextChange(e: ChangeEvent<HTMLInputElement>) {
    const newItem = { ...item };
    newItem.text = e.target.value;
    setItem(newItem);
  }

  function onLocationChange(value: any) {
    const newItem = { ...item };
    newItem.location = value;
    setItem(newItem);
  }

  return (
    <div className="flex items-center mb-2">
      <div className="w-32">
        <Select
          onChange={onLocationChange}
          value={item.location}
          className="w-full"
        >
          <Select.Option value={HEAD_LOCATION}>
            {getHeadFootTitle(HEAD_LOCATION)}
          </Select.Option>
          <Select.Option value={FOOT_LOCATION}>
            {getHeadFootTitle(FOOT_LOCATION)}
          </Select.Option>
        </Select>
      </div>
      <div className="w-32 ml-2">
        <Select
          onChange={onDataTypeChange}
          value={item.data_type}
          className="w-full"
        >
          <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
          <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
        </Select>
      </div>
      <div className="w-32 ml-2">
        <Input onChange={onTextChange} value={item.text} />
      </div>
      <div className="ml-2">
        <button className="text-red-500" onClick={() => removeItem(item)}>
          <Iconfont className="px-1" type="iconclose-circle-o" />
        </button>
      </div>
    </div>
  );
}
