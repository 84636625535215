import { withPrefix } from './http';

export const uploadApi = withPrefix('/file/upload');

if (process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_TASK_RESULT_API === undefined) {
    throw new Error('请在 env 文件设置 REACT_APP_TASK_RESULT_API');
  }
}

export const taskResultAPI = process.env.REACT_APP_TASK_RESULT_API || '';

export const TABLE_RECOGNITION_TOOLTIP = '在模板文件中点击表格，然后开始配置';
