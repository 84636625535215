import { MouseEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Circle, Rect, SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import { classOfPoints, directions, getPointsArray } from '../pointUtils';
import Iconfont from './Iconfont';
import {
  NONE_FEATURES,
  PICTURE_FEATURES,
  STROKE,
  TABLE_FEATURES,
  TEXT_FEATURES,
} from '../pages/template/constants';
import { toInteger } from 'utils/math';
import { initSVG } from '../svg';
import type {
  FeatureImage,
  AreaText,
  SegmentationTable,
  Rect as SvgRect,
} from '../index.d';
import {
  getAreaTextRectId,
  getFeatureImageRectId,
  getImageRatio,
  translateToSVG,
} from '../utils';
import TableRect from '../pages/template/TableRect';
import { enableTemplateTableFeature } from '../featureFlags';

interface TemplateFragmentProps {
  src: string;
  fragments: any[];
  tables: SegmentationTable[];
  featureFragments: FeatureImage[];
  areaTextFragments: AreaText[];
  captureType: string;
  enableCapture: boolean;
  tableInfoListIdSet: Set<string>;
  onTableClick: (index: number, img: ImageData) => void;
  onCaptureStop: () => void;
  onCaptureStart: () => void;
  onCaptureConfirm: (data: ImageData, point: number[]) => void;
}

export default function TemplateFragment(props: TemplateFragmentProps) {
  const {
    src,
    tables,
    tableInfoListIdSet,
    featureFragments,
    areaTextFragments,
    captureType,
    enableCapture,
    onTableClick,
    onCaptureStart,
    onCaptureStop,
    onCaptureConfirm,
  } = props;
  const ref = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const resizeRef = useRef<string | null>(null);

  const movingRef = useRef({ moving: false, x: 0, y: 0 });

  const actionRef = useRef<HTMLDivElement>(null);

  const observerRef = useRef<MutationObserver | null>(null);

  const shape = useRef<Rect | null>(null);

  const pointsRef = useRef<Circle[] | null>(null);

  const [ratio, setRatio] = useState(1);

  const rects = tables.map((t) => translateToSVG(t.table, ratio));

  function resetCropBox() {
    if (actionRef.current) {
      actionRef.current.style.display = 'none';
    }

    setT({
      created: false,
      creating: false,
      x1: -4,
      y1: -4,
      x2: 0,
      y2: 0,
      w: 0,
      h: 0,
    });
  }

  function handleConfirm() {
    const ctx = canvasRef.current?.getContext('2d');

    if (ctx && ref.current) {
      const ratio = ref.current.clientWidth / ref.current.naturalWidth;
      const img = ctx.getImageData(t.x1, t.y1, t.w, t.h);
      onCaptureConfirm(img, [
        toInteger(t.x1 / ratio),
        toInteger(t.y1 / ratio),
        toInteger(t.x2 / ratio),
        toInteger(t.y2 / ratio),
      ]);

      resetCropBox();
    }
  }

  function handleCancel() {
    onCaptureStop();
    resetCropBox();
  }

  const [t, setT] = useState({
    created: false,
    creating: false,
    x1: -4,
    y1: -4,
    x2: 0,
    y2: 0,
    w: 0,
    h: 0,
  });

  const originalClickPoint = useRef({ x: 0, y: 0 });

  const draw = useRef(SVG());

  const fragmentsRef = useRef(SVG());

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    let cursor = 'auto';
    draw.current.removeClass('bg-gray-500');
    if (captureType === TEXT_FEATURES || captureType === PICTURE_FEATURES) {
      cursor = 'crosshair';
      draw.current.addClass('bg-gray-500');
    }
    wrapperRef.current.style.cursor = cursor;
  }, [captureType]);

  useEffect(() => {
    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    function handleCancel(e: KeyboardEvent) {
      if (e.repeat) {
        return;
      }

      if (e.key === 'Escape') {
        if (actionRef.current) {
          actionRef.current.style.display = 'none';
        }

        setT({
          created: false,
          creating: false,
          x1: -4,
          y1: -4,
          x2: 0,
          y2: 0,
          w: 0,
          h: 0,
        });
      }
    }

    document.addEventListener('keydown', handleCancel);

    return () => {
      document.removeEventListener('keydown', handleCancel);
    };
  }, []);

  // Init rect shape and points

  useEffect(() => {
    shape.current = draw.current.rect();
    const g = [];

    for (let i = 0; i < classOfPoints.length; i += 1) {
      const cls = classOfPoints[i];
      const direction = directions[i];

      const circle = draw.current.circle(7);
      circle.stroke(STROKE).fill('white').center(-1, -1).addClass(cls);
      circle.on('mousedown', (e) => {
        e.preventDefault();
        e.stopPropagation();
        resizeRef.current = direction;
      });

      circle.on('mouseup', (e) => {
        e.preventDefault();
        e.stopPropagation();
        resizeRef.current = null;
      });
      g.push(circle);
    }

    observerRef.current = new MutationObserver(() => {
      if (shape.current && pointsRef.current && actionRef.current) {
        const bbox = shape.current.bbox();
        const points = getPointsArray(bbox);

        actionRef.current.style.top = `${bbox.y2 + 2}px`;
        actionRef.current.style.left = `${bbox.x2 - 45}px`;
        actionRef.current.style.display = 'block';

        for (let i = 0; i < points.length; i += 1) {
          const point = points[i];
          const c = pointsRef.current[i];

          c.center(point[0], point[1]);
        }
      }
    });

    observerRef.current.observe(shape.current.node, { attributes: true });

    shape.current.on('mousedown', (e) => {
      movingRef.current.moving = true;
      // @ts-ignore
      movingRef.current.x = e.clientX;
      // @ts-ignore
      movingRef.current.y = e.clientY;
    });

    pointsRef.current = g;
  }, []);

  useEffect(() => {
    function callback() {
      resizeRef.current = null;
      movingRef.current.moving = false;
    }
    window.addEventListener('mouseup', callback);

    return () => {
      window.removeEventListener('mouseup', callback);
    };
  }, []);

  function handleClick(e: MouseEvent) {
    if (captureType === NONE_FEATURES || captureType === TABLE_FEATURES) {
      return;
    }

    if (!enableCapture) {
      return;
    }

    if (t.creating && pointsRef.current) {
      setT({ ...t, created: true, creating: false });

      return;
    }

    if (t.created) {
      return;
    }

    if (wrapperRef.current && shape.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      const nt = { ...t };

      nt.x1 = e.clientX - rect.left;
      nt.y1 = e.clientY - rect.top;

      originalClickPoint.current.x = nt.x1;
      originalClickPoint.current.y = nt.y1;

      nt.creating = true;

      onCaptureStart();

      shape.current
        .move(nt.x1, nt.y1)
        .fill(STROKE)
        .attr({ 'fill-opacity': 0.5 });

      setT(nt);
    }
  }

  function handleMove(e: MouseEvent) {
    if (t.creating) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (rect) {
        const nt = { ...t };

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (x > originalClickPoint.current.x) {
          nt.x1 = originalClickPoint.current.x;
          nt.x2 = x;
        } else {
          nt.x2 = originalClickPoint.current.x;
          nt.x1 = x;
        }

        if (y > originalClickPoint.current.y) {
          nt.y1 = originalClickPoint.current.y;
          nt.y2 = y;
        } else {
          nt.y2 = originalClickPoint.current.y;
          nt.y1 = y;
        }

        nt.w = nt.x2 - nt.x1;

        nt.h = nt.y2 - nt.y1;

        setT(nt);
      }
    }

    if (resizeRef.current !== null) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (rect) {
        const nt = { ...t };
        switch (resizeRef.current) {
          case 'lt':
            nt.x1 = e.clientX - rect.left;
            nt.y1 = e.clientY - rect.top;
            break;
          case 'l':
            nt.x1 = e.clientX - rect.left;
            break;
          case 'lb':
            nt.x1 = e.clientX - rect.left;
            nt.y2 = e.clientY - rect.top;
            break;
          case 't':
            nt.y1 = e.clientY - rect.top;
            break;
          case 'b':
            nt.y2 = e.clientY - rect.top;
            break;
          case 'rt':
            nt.x2 = e.clientX - rect.left;
            nt.y1 = e.clientX - rect.top;
            break;
          case 'r':
            nt.x2 = e.clientX - rect.left;
            break;
          case 'rb':
            nt.x2 = e.clientX - rect.left;
            nt.y2 = e.clientY - rect.top;
            break;
          default:
            break;
        }

        nt.w = nt.x2 - nt.x1;
        nt.h = nt.y2 - nt.y1;

        setT(nt);
      }
    }

    if (movingRef.current.moving) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      const dx = e.clientX - movingRef.current.x;
      const dy = e.clientY - movingRef.current.y;

      movingRef.current.x = e.clientX;
      movingRef.current.y = e.clientY;

      const nt = { ...t };

      nt.x1 = nt.x1 + dx;
      nt.x2 = nt.x2 + dx;
      nt.y1 = nt.y1 + dy;
      nt.y2 = nt.y2 + dy;

      if (nt.x1 <= 0) {
        nt.x1 = 0;
      }

      if (nt.y1 <= 0) {
        nt.y1 = 0;
      }

      if (nt.x2 > rect.width) {
        nt.x2 = rect.width;
        nt.x1 = nt.x2 - nt.w;
      }

      if (nt.y2 > rect.height) {
        nt.y2 = rect.height;
        nt.y1 = nt.y2 - nt.h;
      }

      setT(nt);
    }
  }

  useEffect(() => {
    shape.current?.move(t.x1, t.y1);
    shape.current?.size(t.w, t.h);
  }, [t]);

  function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
    const { currentTarget } = e;
    const ratio = getImageRatio(currentTarget);

    setRatio(ratio);

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ref.current) {
        canvasRef.current.height = ref.current.height;
        canvasRef.current.width = ref.current.width;

        ctx?.drawImage(
          ref.current,
          0,
          0,
          ref.current.width,
          ref.current.height
        );
      }
    }

    if (wrapperRef.current && ref.current) {
      initSVG(
        fragmentsRef.current,
        wrapperRef.current,
        ref.current.clientWidth,
        ref.current.clientHeight
      );
      initSVG(
        draw.current,
        wrapperRef.current,
        ref.current.clientWidth,
        ref.current.clientHeight
      );
    }
  }

  function handleTableRectClick(index: number, rect: SvgRect) {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const img = ctx.getImageData(rect.x, rect.y, rect.w, rect.h);
      onTableClick(index, img);
    }
    // TODO: select table
  }

  const cls = ['absolute w-full h-full top-0'];
  if (captureType !== TEXT_FEATURES && captureType !== PICTURE_FEATURES) {
    cls.push('z-20');
  }

  return (
    <div
      className="relative overflow-hidden"
      ref={wrapperRef}
      onClick={handleClick}
      onMouseMove={handleMove}
    >
      <canvas className="w-full" ref={canvasRef} />
      <img
        onLoad={onImageLoad}
        className="block w-full max-w-full absolute z-0 top-0"
        crossOrigin="anonymous"
        ref={ref}
        src={src}
        alt=""
      />
      <svg className={cls.join(' ')}>
        {areaTextFragments.map((f) => (
          <rect
            key={f._key}
            id={getAreaTextRectId(f._key)}
            width={(f.area[2] - f.area[0]) * ratio}
            height={(f.area[3] - f.area[1]) * ratio}
            x={f.area[0] * ratio}
            y={f.area[1] * ratio}
            className="text-opacity-50 text-green-200 fill-current cursor-pointer"
            stroke="transparent"
          ></rect>
        ))}
        {featureFragments.map((f) => (
          <rect
            key={f._key}
            id={getFeatureImageRectId(f._key)}
            width={(f.box[2] - f.box[0]) * ratio}
            height={(f.box[3] - f.box[1]) * ratio}
            x={f.box[0] * ratio}
            y={f.box[1] * ratio}
            className="text-opacity-50 text-green-200 fill-current cursor-pointer"
            stroke="transparent"
          ></rect>
        ))}
        {enableTemplateTableFeature &&
          rects.map((rect, index) => (
            <TableRect
              key={tables[index].id}
              id={tables[index].id}
              rect={rect}
              show={tableInfoListIdSet.has(tables[index].id)}
              onClick={() => handleTableRectClick(index, rect)}
            />
          ))}
      </svg>

      <div className="absolute z-10 text-white hidden" ref={actionRef}>
        <button
          onClick={handleCancel}
          style={{ marginRight: 1 }}
          className="bg-green-500 px-1"
        >
          <Iconfont type="iconGroup56" />
        </button>
        <button onClick={handleConfirm} className="bg-green-500 px-1">
          <Iconfont type="iconGroup55" />
        </button>
      </div>
    </div>
  );
}
