import { Input } from 'antd';
import { ChangeEvent } from 'react';
import type { CustomTableConfig } from '../index.d';
import Iconfont from './Iconfont';

interface CustomConfigProps {
  item: CustomTableConfig;
  setItem: (item: CustomTableConfig) => void;
  removeItem: (item: CustomTableConfig) => void;
}
export default function CustomConfigItem(props: CustomConfigProps) {
  const { item, setItem, removeItem } = props;

  function onKeyChange(e: ChangeEvent<HTMLInputElement>) {
    const newItem = { ...item };
    newItem.key = e.target.value;

    setItem(newItem);
  }

  function onContentChange(e: ChangeEvent<HTMLInputElement>) {
    const newItem = { ...item };
    newItem.changeless_content = e.target.value;

    setItem(newItem);
  }
  return (
    <div className="flex items-center mb-2">
      <div className="w-32">
        <Input onChange={onKeyChange} value={item.key} />
      </div>
      <div className="w-32 ml-2">
        <Input onChange={onContentChange} value={item.changeless_content} />
      </div>
      <div className="ml-2">
        <button className="text-red-500" onClick={() => removeItem(item)}>
          <Iconfont className="px-1" type="iconclose-circle-o" />
        </button>
      </div>
    </div>
  );
}
