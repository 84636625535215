import { Steps as AntdSteps } from 'antd';

interface StepsProps {
  current: number;
}
export default function Steps(props: StepsProps) {
  return (
    <AntdSteps current={props.current}>
      <AntdSteps.Step title="填写基本信息" />
      <AntdSteps.Step title="上传文件配置模板特征" />
      <AntdSteps.Step title="配置识别信息" />
      <AntdSteps.Step title="完成" />
    </AntdSteps>
  );
}
