import { Modal, Form, Upload, Input, message, Button } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import type { Feedback } from "../../index.d";
import { feedback } from "../../api";
import UploadButton from "../../component/UploadButton";
import { uploadApi } from "../../config";
import { getToken } from "utils/auth";

interface FeedbackModalProps {
  onHide: () => void;
  visible: boolean;
  feedbackPayload: {
    type: number;
    id: string;
  };
}

export default function FeedbackModal(props: FeedbackModalProps) {
  const { visible, feedbackPayload } = props;
  const { type: template_type, id: template_id } = feedbackPayload;

  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  // const [thumbUrl, setThumbUrl] = useState('');

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  function onChange(info: UploadChangeParam<UploadFile>) {
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.thumbUrl) {
        // setThumbUrl(info.file.thumbUrl);
      }
      setUploading(false);
    }
  }

  async function onFinish(values: any) {
    setConfirmLoading(true);
    try {
      const file_url = values.file.file.response.data.file_url;
      const payload: Feedback = {
        user_id: 1,
        template_id,
        template_type,
        file_url,
        remarks: values.remarks,
      };

      const res = await feedback(payload);
      if (res && res.errcode === 0) {
        message.success("反馈成功");
      } else {
        message.error("反馈失败");
      }
      props.onHide();
    } catch (error) {}

    setConfirmLoading(false);
  }

  const type = template_type === 1 ? "picture-card" : "text";

  function UploadAction() {
    if (type === "picture-card") {
      return (
        <div>
          <UploadButton loading={uploading} />
          <UploadText loading={uploading} />
        </div>
      );
    }
    return <Button loading={uploading}>上传文件</Button>;
  }
  return (
    <Modal
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => form.submit()}
      width="560px"
      title="问题反馈"
      onCancel={props.onHide}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          valuePropName="file"
          name="file"
          label="问题图片"
          rules={[{ required: true, message: "请上传问题图片" }]}
        >
          <Upload
            accept=".jpg,.pdf,.png,.jpeg,.bmp"
            action={uploadApi}
            headers={{ Authorization: `Bearer ${getToken()}` }}
            listType={type}
            maxCount={1}
            onChange={onChange}
          >
            <UploadAction />
          </Upload>
        </Form.Item>
        <Form.Item name="remarks" label="问题描述" rules={[{ required: true }]}>
          <Input.TextArea placeholder="描述您遇到的问题" rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function UploadText(props: { loading: boolean }) {
  const { loading } = props;
  const text = loading ? "上传中" : "上传图片";
  return <div className="mt-2">{text}</div>;
}
