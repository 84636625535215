import { SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRef } from 'react';
import type { FeatureImage, AreaText, SegmentationTable } from '../../index.d';
import { ImageIndicator } from '../../component/ImageIndicator';
import TemplateFragment from '../../component/TemplateFragment';
import { IMAGE_OBSERVER_CLASS } from 'ai-constants';

interface TemplateFileListProps {
  list: string[];
  filename: string;
  captureType: string;
  currentCapturePage: number;
  featureImageList: FeatureImage[];
  areaTextList: AreaText[];
  tableCount: number;
  tableList: SegmentationTable[][];
  tableInfoListIdSet: Set<string>;
  handleTableClick: (
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) => void;
  onCaptureConfirm: (data: ImageData, point: number[], page: number) => void;
  setCurrentCapturePage: (page: number) => void;
  onNext: () => void;
  reUpload: () => void;
  openAdvanceSetting: () => void;
  onPrevious: () => void;
}
export default function TemplateFileList(props: TemplateFileListProps) {
  const {
    list,
    tableList,
    filename,
    captureType,
    featureImageList,
    areaTextList,
    tableCount,
    tableInfoListIdSet,
    onNext,
    onPrevious,
    reUpload,
    handleTableClick,
    onCaptureConfirm,
    setCurrentCapturePage,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="w-full bg-white relative">
      <ImageIndicator
        tableCount={tableCount}
        filename={filename}
        list={list}
        container={ref}
      />
      <div className="overflow-hidden">
        <div
          ref={ref}
          style={{
            maxHeight: `calc(100vh - 320px)`,
            minHeight: 'calc(100vh - 320px)',
          }}
          className="mx-auto overflow-auto"
        >
          <ul style={{ width: 1024 }} className="mx-auto mb-0">
            {list.map((i, index) => (
              <li
                className={IMAGE_OBSERVER_CLASS}
                key={i}
                data-src={i}
                data-page={index + 1}
              >
                <TemplateFragment
                  tableInfoListIdSet={tableInfoListIdSet}
                  onCaptureConfirm={(data, point) => {
                    setCurrentCapturePage(-1);
                    onCaptureConfirm(data, point, index);
                  }}
                  onTableClick={(tableIndex, img) =>
                    handleTableClick(index, tableIndex, img)
                  }
                  onCaptureStop={() => setCurrentCapturePage(-1)}
                  onCaptureStart={() => setCurrentCapturePage(index)}
                  enableCapture={
                    props.currentCapturePage === index ||
                    props.currentCapturePage === -1
                  }
                  tables={tableList[index] || []}
                  captureType={captureType}
                  fragments={[]}
                  featureFragments={featureImageList.filter(
                    (i) => i.page === index
                  )}
                  areaTextFragments={areaTextList.filter(
                    (i) => i.page === index
                  )}
                  src={i}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sticky left-0 bottom-0 w-full flex">
        <div
          className="flex h-12 items-center px-6 bg-white w-full bottom-0"
          style={{ boxShadow: '0 -2px 4px 0 #ccc' }}
        >
          <span className="flex-1 ">
            <Button className="text-gray-700" onClick={reUpload} type="text">
              <UploadOutlined />
              重新上传
            </Button>
          </span>
          <span>
            <Button onClick={onPrevious} className="mr-4 text-gray-700">
              上一步
            </Button>
            <Button type="primary" onClick={onNext}>
              下一步
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
