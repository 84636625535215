import { Progress, Upload } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useState } from 'react';
import { FileResponse } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { uploadApi } from '../../config';
import { getToken } from 'utils/auth';

interface UploadTemplateProps {
  onFileUploaded: (fileRes: FileResponse, name: string) => void;
}

export default function UploadTemplate(props: UploadTemplateProps) {
  const { onFileUploaded } = props;
  const [file, setFile] = useState<UploadFile<any>>({
    uid: 'xx',
    name: 'template',
  });

  function onChange(info: UploadChangeParam<UploadFile>) {
    setFile(info.file);
    const { status, response } = info.file;
    if (status === 'done' && response.errcode === 0) {
      onFileUploaded(response.data, file.name);
    }
  }

  const loading = file.status === 'uploading';

  const error = file.status === 'error';

  return (
    <Upload.Dragger
      onChange={onChange}
      showUploadList={false}
      action={uploadApi}
      headers={{ Authorization: `Bearer ${getToken()}` }}
    >
      <div className="">
        <UploadText error={error} loading={loading} percent={file.percent} />
        <Description />
      </div>
    </Upload.Dragger>
  );
}

function UploadText(props: {
  error: boolean;
  loading: boolean;
  percent?: number;
}) {
  const { loading, percent, error } = props;
  if (loading) {
    return (
      <div className="mb-10">
        <Progress
          type="circle"
          strokeColor="#1EBCB2"
          percent={Math.round(percent || 0)}
        />
        <div className="text-base mt-4">文件上传中...</div>
      </div>
    );
  }

  const text = error ? '上传失败，请重试' : '上传文件模板';

  return (
    <div className="mb-10">
      <Iconfont
        type="iconGroup54"
        className="p-4"
        style={{ fontSize: '72px', color: 'rgba(0,0,0,.45' }}
      />
      <div className="text-base">{text}</div>
    </div>
  );
}

function Description() {
  return (
    <section>
      <h1 className="text-base mb-4">说明</h1>
      <h2 className="mb-2">上传模板文件要求</h2>
      <p className="text-gray-600" style={{ marginBottom: 16 }}>
        文件类型支持PDF、JPG、PNG、BPM。转存件或有明显分割线的表格，识别效果更优。
      </p>
      <h2 className="mb-2">关于高级设置</h2>
      <p className="text-gray-600" style={{ marginBottom: 16 }}>
        对于识别效果不佳的文档，可以尝试调整高级设置的参数（具体参数的说明见具体设置），提高文档的识别度。
      </p>
    </section>
  );
}
