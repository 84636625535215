import { Button,Row,Col } from "antd"
interface HomeProps {
  onUse: () => void;
}
export default function HomePage(props: HomeProps) {
  function handleOpen(){
    window.location.href = '/ai/api/doc'
  }
  return (
   <div className="bg-white p-2">
    <main className="text-center">
      <h1 className="text-6xl">智能OCR</h1>
      <div className="mx-auto w-1/2 mt-8">
        <p className="mb-4">OCR：文字识别，对图片中的文字进行检测与识别，支持中文、英文。<br />
                                      提供两种不同类型的OCR。<br />
                                      1.开箱即用的各种OCR能力，包括通用OCR、身份证OCR、火车票OCR等等。直接获取API文档即可调用接口获得AI能力。<br />
                                      2.模板OCR。基于自研的、业界领先的图像处理、表格分割和文字识别技术，通过自定义配置，提供模板分类及模板识别的功能，并整合多种预置能力，提供多场景的解决方案，高效、低成本的对固定版式的卡证票据、PDF文档、表格文档等进行自动分类及全场景结构化识别。
</p>
        <Button type="primary" onClick={props.onUse}>立即使用</Button>
      </div>
    </main>
    <article className="px-8 mt-12">
      <h1 className="text-4xl text-center">功能介绍</h1>
      <Row className="px-16" gutter={48}>
        <Col className="gutter-row"  md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">多种预置能力</div>
          <div className="leading-6">内置通用文字识别、身份证识别、火车票识别等多种常用AI能力，快速集成并解决各种场景需求。</div>
        </Col>
        <Col className="gutter-row" md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">自定义配置</div>
          <div className="leading-6">针对各种不同类型的文档，支持自定义识别模板配置，操作简单、方便快捷。</div>
        </Col>
        <Col className="gutter-row" md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">全场景适配</div>
          <div className="leading-6">支持任意固定版式卡证、票据、PDF文档的模板制作，实现结构化识别，应用场景广泛</div>
        </Col>
        <Col className="gutter-row mt-2" md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">表格结构化</div>
          <div className="leading-6">自研的优秀图像处理算法，高效、准确的识别表格，实现表格信息的结构化</div>
        </Col>
        <Col className="gutter-row mt-2" md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">文档级结构化</div>
          <div className="leading-6">支持各种PDF文档级别的结构化</div>
        </Col>
        <Col className="gutter-row mt-2" md={8} sm={12} xs={24}>
          <div className="text-2xl py-4">模板管理</div>
          <div className="leading-6">支持对模板的新增、修改、删除、查询等。展示模版调用记录，方便分析模板使用情况</div>
        </Col>
      </Row>
    </article>
    <footer className="text-center px-8 my-12">
      <h1 className="text-4xl text-center">使用方式</h1>
      <div>
        <p className="mb-6 text-gray-700">提供在线服务接口，可直接调用API进行集成应用。</p>
        <Button type="primary" onClick={handleOpen}>API文档</Button>
      </div>
    </footer>
   </div>
  );
}
