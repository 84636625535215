const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
export function toBlobPromise(data) {
    return new Promise((resolve, reject) => {
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = data.width;
            canvas.height = data.height;
            ctx.putImageData(data, 0, 0);
            canvas.toBlob((blob) => resolve(blob));
            return;
        }
        reject('No canvas context');
    });
}
export function toBlob(data, callback) {
    if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = data.width;
        canvas.height = data.height;
        ctx.putImageData(data, 0, 0);
        canvas.toBlob(callback);
    }
}
export function toDataUrl(data) {
    if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = data.width;
        canvas.height = data.height;
        ctx.putImageData(data, 0, 0);
        return canvas.toDataURL();
    }
}
