import { useRef } from "react";
import { OcrText } from "../../index.d";
import { ImageIndicator } from "../../component/ImageIndicator";
import { IMAGE_OBSERVER_CLASS } from "ai-constants";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

interface CustomTemplateFileListProps {
  list: string[];
  filename: string;
//   captureType: string;
//   currentCapturePage: number;
//   ocrTextList: OcrText[];
//   onCaptureConfirm: (data: ImageData, point: number[], page: number) => void;
//   setCurrentCapturePage: (page: number) => void;
  onNext: () => void;
  reUpload: () => void;
  onPrevious: () => void;
}
export default function CustomTemplateFileList(
  props: CustomTemplateFileListProps
) {
  const {
    list,
    filename,
    // captureType,
    // currentCapturePage,
    // ocrTextList,
    // onCaptureConfirm,
    // setCurrentCapturePage,
    onNext,
    onPrevious,
    reUpload,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="w-full bg-white relative">
      <ImageIndicator filename={filename} list={list} container={ref} />
      <div className="overflow-hidden">
        <div
          ref={ref}
          style={{
            maxHeight: `calc(100vh - 320px)`,
            minHeight: "calc(100vh - 320px)",
          }}
          className="mx-auto overflow-auto"
        >
          <ul style={{ width: 1024 }} className="mx-auto mb-0">
            {list.map((i, index) => (
              <li
                className={IMAGE_OBSERVER_CLASS}
                key={i}
                data-src={i}
                data-page={index + 1}
              >
                <div className="relative overflow-hidden">
                  <img
                    className="block w-full max-w-full"
                    crossOrigin="anonymous"
                    src={i}
                    alt=""
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sticky left-0 bottom-0 w-full flex">
        <div
          className="flex h-12 items-center px-6 bg-white w-full bottom-0"
          style={{ boxShadow: '0 -2px 4px 0 #ccc' }}
        >
          <span className="flex-1 ">
            <Button className="text-gray-700" onClick={reUpload} type="text">
              <UploadOutlined />
              重新上传
            </Button>
          </span>
          <span>
            <Button onClick={onPrevious} className="mr-4 text-gray-700">
              上一步
            </Button>
            <Button type="primary" onClick={onNext}>
              下一步
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
