import { RecoilRoot } from "recoil";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AiPower from "./pages/aiPower";
import Template from "./pages/template";
import EditTemplate from "./pages/template/edit";
import Records from "./pages/records";
import Record from "./pages/records/record";
import CommanderRecord from "./pages/records/CommanderRecord";
import SSO from "./pages/sso";
import UserLogin from "./pages/user/login";
import UserRegister from "./pages/user/register";
import GeneralRecords from "./pages/records/GeneralRecords";
import Auth from "./pages/auth";
import CustomTemplate from "./pages/template/CustomTemplate";
import CustomTemplateEdit from "./pages/template/CustomTemplateEdit";

function App() {
  return (
    <RecoilRoot>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/sso" element={<SSO />}></Route>
          <Route path="/register" element={<UserRegister />}></Route>
          <Route path="/login" element={<UserLogin />} />
          <Route path="/template/table/:sign/edit" element={<EditTemplate />} />
          <Route
            path="/template/new"
            element={<Navigate to="/template/table/new" replace={true} />}
          />
          <Route path="/template/table/new" element={<Template />} />
          <Route path="/template/custom/:sign/edit" element={<CustomTemplateEdit />} />
          <Route path="/template/custom/new" element={<CustomTemplate />} />
          <Route path="/records/:id" element={<Records />} />
          <Route path="/record/:id" element={<Record />} />
          <Route path="/general-records/:id" element={<GeneralRecords />} />
          <Route path="/" element={<AiPower />}></Route>
        </Routes>
      </Router>
    </RecoilRoot>
  );
}

export default App;
