import type { Svg } from '@svgdotjs/svg.js';

export function initSVG(
  svg: Svg,
  wrapper: HTMLElement,
  width: number,
  height: number
) {
  svg.addTo(wrapper);
  svg.addClass('absolute top-0');
  svg.size(width, height);
}

export const STROKE = '#1EBCB2';
