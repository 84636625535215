const tableRecognitionRatioCache = new Map<string, number>();

export function getTableRecognitionRatio(url: string) {
  return tableRecognitionRatioCache.get(url);
}

export function setTableRecognitionRatio(url: string, ratio: number) {
  tableRecognitionRatioCache.set(url, ratio);
}

export function clearTableRecognitionRatio() {
  tableRecognitionRatioCache.clear();
}
