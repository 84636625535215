// 开启模板表格特征 - https://www.tapd.cn/55211521/prong/stories/view/1155211521001002357?url_cache_key=from_url_story_list_c97fb31d5f970ed398e8179d02b660d4&action_entry_type=story_tree_list
export const enableTemplateTableFeature = false;

// 开启识别结果删除功能
export const enableRecordResultRemoveFeature = false;

export const enableLogoutFeature = false;

// 在 commander 开启
export const enablePermissionCheck = true;
