import { UploadOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import {
  Form,
  message,
  Alert,
  Spin,
  Modal,
  Select,
  Input,
  Upload,
  Button,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useState, useRef, useEffect } from 'react';
import {
  TemplateInfo,
  TableInfoPayload,
  SegmentationResponse,
  SegmentationTable,
  ImageDataWrapper,
  FeatureImage,
  AreaText,
  FilenameText,
  TableSegmentationConfig,
  FileResponse,
  TemplatePayload,
  TableFeature,
} from '../../index.d';
import { segment, saveTemplate, getTaskResult, callOCR } from '../../api';
import { toDataUrl, toBlobPromise } from 'utils/canvasUtils';
import AdvancedSettings from '../../component/AdvancedSettings';
import { Panel } from 'components';
import { TABLE_RECOGNITION_TOOLTIP, uploadApi } from '../../config';
import { useManipulatingState } from 'hooks';
import { clearOpticalCharacterRecognitionResults, getOCR } from '../../ocr';
import {
  DEFAULT_ADVANCED_SETTINGS,
  isSegmentationEqual,
} from '../../segmentation';
import { handleBlobUpload } from '../../upload';
import {
  scrollIntoView,
  getTableRecognitionItemId,
  getTemplateTableId,
  getFileType,
  getFilenameTextItemId,
  getAreaTextItemId,
  getFeatureImageItemId,
  stringify,
} from '../../utils';
import {
  NONE_FEATURES,
  NO_CONFIG,
  PICTURE_FEATURES,
  TEXT_FEATURES,
  FILE_FEATURES,
  TABLE_CONFIG_MODE_CUSTOM,
  TEXT_CONTAIN,
  TEXT_EQUAL,
  TABLE_FEATURES,
  TABLE_CELL,
} from './constants';
import { clearTableRecognitionRatio } from './ratioCache';
import SetupTableFileList from './SetupTableFileList';
import TableRecognitionInfo from './TableRecognitionInfo';
import TableRecognitionModal from './TableRecognitionModal';
import TemplateFeatures from './TemplateFeatures';
import TemplateFileList from './TemplateFileList';
import { TemplateInfoComponent } from './TemplateInfo';
import TemplateSaved from './TemplateSaved';
import UploadTemplate from './UploadTemplate';
import { log } from '@bewd/logger';
import { generateKey } from './keyUtils';
import Steps from './Stepts';
import { getToken } from 'utils/auth';
import TableFeatureExtractModal from './TableFeatureExtractModal';
import FileInfoFeatureModal from './FileInfoFeatureModal';
import { isOk } from '../../http';

export default function Template() {
  /*============================== Ref ==============================*/
  const alertMessage = useRef(TABLE_RECOGNITION_TOOLTIP);

  const capturePageIndexRef = useRef(-1);

  /*============================== State ==============================*/
  const [tableFeatureExtractModalVisible, setTableFeatureExtractModalVisible] =
    useState(false);

  const [advancedSettingVisible, setAdvancedSettingVisible] = useState(false);

  const [advancedSetting, setAdvancedSetting] = useState(
    DEFAULT_ADVANCED_SETTINGS
  );

  const [testTemplateVisible, setTestTemplateVisible] = useState(false);
  const [testFiles, setTestFiles] = useState<UploadFile<any>[]>([]);

  const [testFileResult, setTestFileResult] = useState('');
  const [filenameRef] = Form.useForm();
  const [areaRef] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const [spinning, setSpinning] = useState(false);

  const [saving, setSaving] = useState(false);

  const [querying, setQuerying] = useState(false);

  const queryingTimer = useRef<NodeJS.Timeout | null>(null);

  const [sign, setSign] = useState('');

  const [filename, setFilename] = useState('');

  const templateInfo = useRef<TemplateInfo>({
    name: '',
    icon: '',
    template_classify: 0,
    description: '',
  });

  const [currentTableInfoPayload, setCurrentTableInfoPayload] =
    useState<TableInfoPayload>({
      _key: -1,
      table_name: '',
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: '',
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });

  const [
    tableInfoPayloadList,
    setTableInfoPayloadList,
    addTableInfoPayload,
    removeTableInfoPayload,
  ] = useManipulatingState<TableInfoPayload>([]);
  const segmentResult = useRef<SegmentationResponse>({
    file_type: 1,
    origin_file_url: '',
    image_url_list: [],
    page_table_list: [],
    table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
  });

  const [
    tableFeatureList,
    setTableFeatureList,
    addTableFeature,
    removeTableFeature,
  ] = useManipulatingState<TableFeature>([]);
  const [currentTableFeature, setCurrentTableFeature] = useState<TableFeature>({
    _key: -1,
    page_index: 0,
    table_index: 0,
    cell_text_list: [],
    head_foot_text_list: [],
  });

  const [captureType, setCaptureType] = useState(NONE_FEATURES);
  const [currentCapturePage, setCurrentCapturePage] = useState(-1);

  const [areaImage, setAreaImage] = useState('');

  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [textModalVisible, setTextModalVisible] = useState(false);
  const [list, setList] = useState<string[]>([]);
  const [tableList, setTableList] = useState<SegmentationTable[][]>([[]]);

  const [tableInfo, setTableInfo] = useState<SegmentationTable>({
    id: '',
    table: [],
    table_boxes: [[]],
  });

  const imageDataRef = useRef<ImageDataWrapper[]>([]);

  const pointRef = useRef<number[]>([]);

  const [tableConfigVisible, setTableConfigVisible] = useState(false);

  const [tableConfigMode, setTableConfigMode] = useState(NO_CONFIG);

  const [currentPageSrc, setCurrentPageSrc] = useState('');

  const [pageIndex, setPageIndex] = useState(0);

  const [tableIndex, setTableIndex] = useState(0);

  const [uploaded, setUploaded] = useState(false);

  const [tableCount, setTableCount] = useState(0);

  const [showAddTableConfigInfo, setShowAddTableConfigInfo] = useState(false);

  const [
    featureImageList,
    setFeatureImageList,
    addFeatureImage,
    removeFeatureImageList,
  ] = useManipulatingState<FeatureImage>([]);

  const [areaTextList, setAreaTextList, addAreaText, removeAreaText] =
    useManipulatingState<AreaText>([]);

  const [
    filenameTextList,
    setFilenameTextList,
    addFilenameText,
    removeFilenameText,
  ] = useManipulatingState<FilenameText>([]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < tableList.length; i += 1) {
      count += tableList[i].length;
    }

    setTableCount(count);
    log('table count', count);
  }, [tableList]);

  function beginTableConfigAdd(mode: number) {
    alertMessage.current = TABLE_RECOGNITION_TOOLTIP;
    setTableConfigMode(mode);
    setShowAddTableConfigInfo(true);
  }

  function handleTableConfigCancel() {
    setTableConfigVisible(false);
    setTableConfigMode(NO_CONFIG);
  }

  function addImageData(
    pageIndex: number,
    tableIndex: number,
    data: ImageData
  ) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );

    if (target) {
      return;
    }

    const payload = {
      pageIndex,
      tableIndex,
      data,
      uploaded: false,
      url: toDataUrl(data) || '',
    };

    imageDataRef.current.push(payload);
  }

  function getThumbnailUrl(pageIndex: number, tableIndex: number) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );
    if (target) {
      return target.url;
    }
    return '';
  }

  function onTableInfoPayloadAdd(payload: TableInfoPayload) {
    const targetIndex = tableInfoPayloadList.findIndex(
      (item) => item._key === payload._key
    );
    if (targetIndex !== -1) {
      log(payload);

      const newTableInfoPayloadList = tableInfoPayloadList.map((i) => ({
        ...i,
      }));
      newTableInfoPayloadList[targetIndex] = payload;
      setTableInfoPayloadList(newTableInfoPayloadList);
      return;
    }
    payload.page_index = pageIndex;
    payload.table_index = tableIndex;
    payload.thumbnail_image_url = getThumbnailUrl(pageIndex, tableIndex);

    addTableInfoPayload(payload);
    setTimeout(() => {
      scrollIntoView(getTableRecognitionItemId(payload._key));
    }, 0);
  }

  function onTestTemplateModalCancel() {
    if (queryingTimer.current) {
      clearTimeout(queryingTimer.current);
    }
    setTestTemplateVisible(false);
  }

  async function handleSegment(
    type: number,
    url: string,
    config: TableSegmentationConfig
  ) {
    try {
      setSpinning(true);
      const result: {
        errcode: number;
        data: { results: SegmentationResponse };
      } = await segment({
        file_type: type,
        file_url: url,
        table_segmentation_config: config,
      });
      if (result.errcode === 0) {
        const { results } = result.data;
        setList(results.image_url_list);
        setTableList(
          results.page_table_list.map((i, pageIndex) =>
            i.map((t, tableIndex) => ({
              ...t,
              id: getTemplateTableId(`${pageIndex}-${tableIndex}`),
            }))
          )
        );
        setAdvancedSetting(config);
        setTableInfoPayloadList([]);
        setUploaded(true);
        clearOpticalCharacterRecognitionResults();
        segmentResult.current = {
          file_type: results.file_type,
          origin_file_url: results.origin_file_url,
          image_url_list: results.image_url_list,
          page_table_list: results.page_table_list,
          table_segmentation_config: results.table_segmentation_config,
        };
      }
    } catch (error) {}
    setSpinning(false);
  }

  async function onFileUploaded(fileRes: FileResponse, name: string) {
    setFilename(name);
    setAreaTextList([]);
    setFeatureImageList([]);
    setFilenameTextList([]);
    handleSegment(
      getFileType(fileRes.file_url),
      fileRes.file_url,
      advancedSetting
    );
  }

  function onAdvancedUpdate(config: TableSegmentationConfig) {
    setAdvancedSettingVisible(false);
    if (isSegmentationEqual(config, advancedSetting)) {
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
      }, 50);
      return;
    }
    handleSegment(
      segmentResult.current.file_type,
      segmentResult.current.origin_file_url,
      config
    );
  }

  async function handleTemplateSave() {
    try {
      // set loading
      // upload feature images

      if (tableInfoPayloadList.length === 0) {
        throw new Error('表格识别信息不能为空');
      }

      setSaving(true);

      for (let i = 0; i < featureImageList.length; i += 1) {
        const item = featureImageList[i];
        const result = await toBlobPromise(item.imageData);
        if (result) {
          item.url = await handleBlobUpload(result);
        }
      }

      for (let i = 0; i < tableInfoPayloadList.length; i += 1) {
        const item = tableInfoPayloadList[i];
        const target = imageDataRef.current.find(
          (i) =>
            i.pageIndex === item.page_index && i.tableIndex === item.table_index
        );

        if (target) {
          if (!target.uploaded) {
            const blob = await toBlobPromise(target.data);
            if (blob) {
              target.url = await handleBlobUpload(blob);
              target.uploaded = true;
            }
          }
          item.thumbnail_image_url = target.url;
        }
      }

      const areaTextListPayload = areaTextList.map((i) => ({
        data_type: i.data_type,
        text: i.text,
        area: i.area,
        page: i.page,
      }));

      const payload: TemplatePayload = {
        ...templateInfo.current,
        filename,
        file_type: segmentResult.current?.file_type,
        origin_file_url: segmentResult.current.origin_file_url,
        image_url_list: segmentResult.current.image_url_list,
        table_segmentation_config:
          segmentResult.current.table_segmentation_config,
        template_features: {
          feature_image_list: featureImageList.map((i) => ({
            url: i.url,
            page: i.page,
            box: i.box,
          })),
          area_text_list: areaTextListPayload,
          filename_text_list: filenameTextList,
          table_features_list: tableFeatureList,
        },
        table_info: tableInfoPayloadList,
      };

      const res = await saveTemplate(payload);

      if (res && res.errcode === 0) {
        setSign(res.data.templateSign);
        setCurrent(3);
        message.success(res.errmsg);
      } else {
        throw new Error(res.errmsg);
      }
    } catch (error: any) {
      message.error(error.message);
    }

    setSaving(false);
    clearTableRecognitionRatio();
  }

  async function queryTaskResult(id: string) {
    const res = await getTaskResult(id);
    if (res && res.errcode === 0) {
      if (res.data.status === 'SUCCESS' || res.data.results) {
        setTestFileResult(stringify(res.data.results));
        setQuerying(false);
        return;
      }
      queryingTimer.current = setTimeout(() => {
        queryTaskResult(id);
      }, 1e4);
    } else {
      setQuerying(false);
      message.error(res.errmsg);
    }
  }

  async function onTestFileUploaded(fileRes: FileResponse) {
    setQuerying(true);
    try {
      const response = await callOCR({
        templateSign: sign,
        file_type: getFileType(fileRes.file_url),
        file_url: fileRes.file_url,
      });
      if (response && response.errcode === 0) {
        queryTaskResult(response.data.task_id);
      }
    } catch (error) {}
  }

  function handleFilenameTextAdd(e: any) {
    const payload = { ...e, key: generateKey() };
    addFilenameText(payload);
    filenameRef.resetFields();
    setTimeout(() => {
      scrollIntoView(getFilenameTextItemId(payload.key));
    }, 0);
    setFileModalVisible(false);
  }

  function handleFilenameTextRemove(key: number) {
    removeFilenameText((item) => item._key !== key);
  }

  function handleTableFeatureRemove(key: number) {
    removeTableFeature((item) => item._key !== key);
  }

  function handleAreaTextAdd(e: any) {
    const payload = {
      ...e,
      page: capturePageIndexRef.current,
      area: pointRef.current,
      dataUrl: areaImage,
      _key: generateKey(),
    };
    addAreaText(payload);
    setTimeout(() => {
      scrollIntoView(getAreaTextItemId(payload.key));
    }, 0);
    setTextModalVisible(false);
  }

  function handleAreaTextRemove(key: number) {
    removeAreaText((item) => item._key !== key);
  }

  async function onAreaTextAdd(dataUrl: string, data: ImageData) {
    let text = '';
    try {
      // try extract text from image
      const blob = await toBlobPromise(data);
      if (blob) {
        const res = await getOCR(blob);
        if (isOk(res)) {
          text = res.data.results;
        }
      }
    } catch (error) {}
    areaRef.setFieldsValue({ text });
    setTextModalVisible(true);
    setAreaImage(dataUrl);
  }

  function handleFeatureImageRemove(key: number) {
    removeFeatureImageList((item) => item._key !== key);
  }

  function onCaptureConfirm(data: ImageData, point: number[], page: number) {
    const dataUrl = toDataUrl(data);
    if (!dataUrl) {
      message.error('Unknow Error');
      return;
    }
    pointRef.current = point;

    if (captureType === PICTURE_FEATURES) {
      const payload = {
        dataUrl,
        hover: false,
        page: page,
        box: point,
        url: '',
        imageData: data,
        _key: generateKey(),
      };

      addFeatureImage(payload);

      setTimeout(() => {
        scrollIntoView(getFeatureImageItemId(payload._key));
      }, 0);
    } else if (captureType === TEXT_FEATURES) {
      onAreaTextAdd(dataUrl, data);
      capturePageIndexRef.current = page;
    }

    setCaptureType(NONE_FEATURES);
  }

  function next(info: TemplateInfo) {
    templateInfo.current = info;
    setCurrent(1);
  }

  function handleTableEdit(src: string, pageIndex: number, tableIndex: number) {
    setCurrentPageSrc(src);
    setPageIndex(pageIndex);
    setTableIndex(tableIndex);
    setTableInfo(tableList[pageIndex][tableIndex]);
  }

  function tableConfigStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (tableConfigMode === NO_CONFIG) {
      return;
    }

    setShowAddTableConfigInfo(false);

    setCurrentTableInfoPayload({
      _key: -1,
      table_name: '',
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: '',
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });
    const src = list[pageIndex];
    if (src !== undefined) {
      setTableConfigVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  function handleTemplateFeatureAdd(captureType: string) {
    // TODO:
    if (captureType === TABLE_FEATURES) {
      alertMessage.current = '请选择表格';
      setShowAddTableConfigInfo(true);
    }

    if (captureType === PICTURE_FEATURES || captureType === TEXT_FEATURES) {
      alertMessage.current = '在模板文件中框选图片';
      setShowAddTableConfigInfo(true);
      setTimeout(() => {
        setShowAddTableConfigInfo(false);
      }, 3e3);
    }

    if (captureType === FILE_FEATURES) {
      if (filenameTextList.length > 0) {
        // limit to one, may remove later
        message.info('已设置文件信息特征');
        return;
      }
      setFileModalVisible(true);
    }

    setCaptureType(captureType);
  }

  function openAdvanceSetting() {
    setAdvancedSettingVisible(true);
    // TODO:
  }

  function onTableInfoEdit(item: TableInfoPayload) {
    setCurrentTableInfoPayload(item);
    const target = item.table_configs.find(
      (i) => i.mode !== TABLE_CONFIG_MODE_CUSTOM
    );
    if (!target) {
      return;
    }

    const src = list[item.page_index];
    if (!src) {
      return;
    }

    setTableConfigVisible(true);

    setTableConfigMode(target.mode);

    handleTableEdit(src, item.page_index, item.table_index);
    // TODO:
  }

  function onTableInfoRemove(item: TableInfoPayload) {
    removeTableInfoPayload((i) => i._key !== item._key);
  }

  function handleTableFeatureExtractModalCancel() {
    setTableFeatureExtractModalVisible(false);
  }

  function onTableFeatureAdd(payload: TableFeature) {
    const index = tableFeatureList.findIndex((i) => i._key === payload._key);

    if (index !== -1) {
      const newTableFeatureList = tableFeatureList.map((i) => ({ ...i }));
      newTableFeatureList[index] = payload;
      setTableFeatureList(newTableFeatureList);
      return;
    }

    payload.page_index = pageIndex;
    payload.table_index = tableIndex;

    addTableFeature(payload);
  }

  function handleTableClick(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (captureType === TABLE_FEATURES) {
      tableFeatureStart(pageIndex, tableIndex, img);
      return;
    }
  }

  function tableFeatureStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    setShowAddTableConfigInfo(false);
    setCurrentTableFeature({
      _key: -1,
      page_index: 0,
      table_index: 0,
      cell_text_list: [],
      head_foot_text_list: [],
    });

    const src = list[pageIndex];
    if (src !== undefined) {
      setTableFeatureExtractModalVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  const tableInfoListId = tableInfoPayloadList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableFeatureListId = tableFeatureList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableFeatureListIdSet = new Set(tableFeatureListId);

  const tableInfoListIdSet = new Set(tableInfoListId);

  return (
    <PageContainer
      style={{ backgroundColor: '#F0F2F5', minHeight: '100vh' }}
      title="新建表格模板"
    >
      <div className="fixed w-full left-0 top-4">
        <div className="flex justify-center">
          {showAddTableConfigInfo && (
            <Alert
              className="mx-auto"
              message={alertMessage.current}
              type="info"
              showIcon
              closable
              onClose={() => setShowAddTableConfigInfo(false)}
            />
          )}
        </div>
      </div>
      <div className="py-10 px-32 bg-white">
        <Steps current={current} />
      </div>
      <Panel active={current === 0}>
        <div className="bg-white pb-10">
          <div style={{ width: 472 }} className="mx-auto">
            <TemplateInfoComponent next={next} />
          </div>
        </div>
      </Panel>
      <Panel active={current === 1}>
        <Spin spinning={spinning}>
          <div
            className={`${uploaded ? 'flex' : 'hidden'} overflow-hidden mt-6`}
          >
            <TemplateFileList
              tableList={tableList}
              tableCount={tableCount}
              tableInfoListIdSet={tableFeatureListIdSet}
              featureImageList={featureImageList}
              areaTextList={areaTextList}
              onNext={() => setCurrent(2)}
              reUpload={() => setUploaded(false)}
              openAdvanceSetting={openAdvanceSetting}
              onPrevious={() => setCurrent(0)}
              setCurrentCapturePage={setCurrentCapturePage}
              currentCapturePage={currentCapturePage}
              onCaptureConfirm={onCaptureConfirm}
              handleTableClick={handleTableClick}
              captureType={captureType}
              list={list}
              filename={filename}
            />
            <TemplateFeatures
              areaTextList={areaTextList}
              featureImageList={featureImageList}
              filenameTextList={filenameTextList}
              tableFeatureList={tableFeatureList}
              onFeatureImageRemove={handleFeatureImageRemove}
              onAreaTextRemove={handleAreaTextRemove}
              onFilenameTextRemove={handleFilenameTextRemove}
              onTableFeatureRemove={handleTableFeatureRemove}
              updateCaptureType={handleTemplateFeatureAdd}
            />
          </div>

          <div
            style={{ height: '60vh' }}
            className={`${
              uploaded ? 'hidden' : 'block'
            } relative px-6 pb-6 bg-white`}
          >
            <UploadTemplate onFileUploaded={onFileUploaded} />
          </div>
        </Spin>
      </Panel>
      <Panel active={current === 2}>
        <Spin spinning={saving}>
          <div className="flex overflow-hidden mt-6">
            <SetupTableFileList
              tableInfoListIdSet={tableInfoListIdSet}
              tableCount={tableCount}
              previous={() => setCurrent(1)}
              openAdvanceSetting={openAdvanceSetting}
              onSave={handleTemplateSave}
              onTableConfigStart={tableConfigStart}
              tableList={tableList}
              list={list}
              filename={filename}
            />
            <TableRecognitionInfo
              setConfigMode={beginTableConfigAdd}
              tableInfoPayloadList={tableInfoPayloadList}
              onTableInfoEdit={onTableInfoEdit}
              onTableInfoRemove={onTableInfoRemove}
            />
          </div>
        </Spin>
      </Panel>
      <Panel active={current === 3}>
        <TemplateSaved
          openTestModal={() => setTestTemplateVisible(true)}
          sign={sign}
        />
      </Panel>

      <Modal
        width="560px"
        visible={textModalVisible}
        title="添加文本特征"
        onOk={areaRef.submit}
        onCancel={() => setTextModalVisible(false)}
      >
        <Form
          form={areaRef}
          layout="inline"
          initialValues={{ data_type: TEXT_CONTAIN }}
          onFinish={handleAreaTextAdd}
        >
          <div className="w-32 h-8 mr-2 border border-gray-400 rounded-sm">
            <img
              className="w-full h-full object-cover"
              src={areaImage}
              alt="area text"
            />
          </div>
          <Form.Item
            style={{ marginRight: 8 }}
            name="data_type"
            className="w-28"
          >
            <Select>
              <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
              <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="text"
            rules={[{ required: true, message: '请输入文本特征' }]}
            style={{ flex: 1, marginRight: 8 }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <FileInfoFeatureModal
        visible={fileModalVisible}
        onCancle={() => setFileModalVisible(false)}
        onFinish={handleFilenameTextAdd}
      />

      <TableRecognitionModal
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        tableInfoPayload={currentTableInfoPayload}
        tableInfo={tableInfo}
        visible={tableConfigVisible}
        mode={tableConfigMode}
        src={currentPageSrc}
        onCancel={handleTableConfigCancel}
        onTableInfoPayloadAdd={onTableInfoPayloadAdd}
      />

      <TableFeatureExtractModal
        onTableFeatureAdd={onTableFeatureAdd}
        onCancel={handleTableFeatureExtractModalCancel}
        tableFeature={currentTableFeature}
        tableInfo={tableInfo}
        src={currentPageSrc}
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        mode={TABLE_CELL}
        visible={tableFeatureExtractModalVisible}
      />

      <Modal
        width="560px"
        title="测试模板"
        visible={testTemplateVisible}
        onCancel={onTestTemplateModalCancel}
        onOk={onTestTemplateModalCancel}
      >
        <div className="mb-4 flex">
          <span>识别文件：</span>
          <span className="inline-block overflow-hidden" style={{ width: 440 }}>
            <div>
              <Upload
                fileList={testFiles}
                onChange={(info) => {
                  setTestFiles([info.file]);
                  const { status, response } = info.file;
                  if (status === 'done' && response.errcode === 0) {
                    onTestFileUploaded(response.data);
                  }
                }}
                name="file"
                headers={{ Authorization: `Bearer ${getToken()}` }}
                action={uploadApi}
              >
                <Button>
                  <UploadOutlined />
                  上传文件
                </Button>
              </Upload>
            </div>
            <div className={`${testFiles.length > 0 ? 'hidden' : 'block'}`}>
              <span className="text-gray-600">支持PNG、PDF、JPG格式</span>
            </div>
          </span>
        </div>

        <Spin spinning={querying}>
          <div className="flex">
            <span>识别结果：</span>
            <span className="flex-1">
              <Input.TextArea rows={6} value={testFileResult} />
            </span>
          </div>
        </Spin>
      </Modal>

      <AdvancedSettings
        visible={advancedSettingVisible}
        advancedSetting={advancedSetting}
        onConfirm={onAdvancedUpdate}
      />
    </PageContainer>
  );
}
