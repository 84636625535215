import { Menu } from 'antd';
import { TABLE_CELL, TABLE_COLUMN } from './constants';

export default function TableRecognitionInfoOverlay(props: { onClick: any }) {
  const { onClick, ...rest } = props;
  return (
    <Menu {...rest} className="border border-gray-200" onClick={onClick}>
      <Menu.Item
        className="hover:bg-green-50 hover:text-primary"
        key={TABLE_CELL}
      >
        识别表格单元格
      </Menu.Item>
      <Menu.Item
        className="hover:bg-green-50 hover:text-primary"
        key={TABLE_COLUMN}
      >
        识别表格列
      </Menu.Item>
    </Menu>
  );
}
