import type { TableSegmentationConfig } from './index.d';

export const DEFAULT_ADVANCED_SETTINGS = {
  row_dil_iter: 2,
  col_dil_iter: 1,
  area_threshold: 200,
  h_threshold: 10,
  w_threshold: 20,
  hw_rate_threshold: 100,
  row_h_threshold: 10,
  is_remove_seal: 0,
};

export function isSegmentationEqual(
  s1: TableSegmentationConfig,
  s2: TableSegmentationConfig
) {
  if (s1.row_dil_iter !== s2.row_dil_iter) {
    return false;
  }

  if (s1.col_dil_iter !== s2.col_dil_iter) {
    return false;
  }

  if (s1.area_threshold !== s2.area_threshold) {
    return false;
  }

  if (s1.h_threshold !== s2.h_threshold) {
    return false;
  }

  if (s1.w_threshold !== s2.w_threshold) {
    return false;
  }

  if (s1.hw_rate_threshold !== s2.hw_rate_threshold) {
    return false;
  }

  if (s1.row_h_threshold !== s2.row_h_threshold) {
    return false;
  }

  if (s1.is_remove_seal !== s2.is_remove_seal) {
    return false;
  }

  return true;
}
