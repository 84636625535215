export const NONE_FEATURES = "0";

export const PICTURE_FEATURES = "1";
export const TEXT_FEATURES = "2";
export const FILE_FEATURES = "3";
export const TABLE_FEATURES = "4";
export const CUSTOM_TEMPLATE_DATA = "5";
export const CUSTOM_TEMPLATE_ANCHOR = "6";

export const NO_CONFIG = 0;
export const TABLE_CELL = 1;
export const TABLE_COLUMN = 2;

export const TEXT_EQUAL = 2;
export const TEXT_CONTAIN = 1;

export const TABLE_CONFIG_MODE_CUSTOM = 0;
export const TABLE_CONFIG_MODE_CELL = 1;
export const TABLE_CONFIG_MODE_COLUMN = 2;
export const TABLE_CONFIG_MODE_ROW = 3;

export const TABLE_CONFIG_TYPE_NORMAL = 0;
export const TABLE_CONFIG_TYPE_NUMBER = 1;
export const TABLE_CONFIG_TYPE_EN = 2;
export const TABLE_CONFIG_TYPE_CN = 3;

export const HEAD_LOCATION = 1;
export const FOOT_LOCATION = 2;

export const DEFAULT_BOX = {
  w: 0,
  h: 0,
  x: -2,
  y: -2,
  x1: 0,
  y1: 0,
};

export const STROKE = "#1EBCB2";

export const PANEL_KEY_TEMPLATE_FEATURE = "1";

export const PANEL_KEY_REGONIZE_INFO = "2";

export const PANEL_KEY_TABLE_INFO = "2";

export const TEMPLATE_DRAW_MODE = 1;

export const TEMPLATE_SELECT_MODE = 2;
