let auth = {
    token: localStorage.getItem('user') || '',
    user_id: localStorage.getItem('user_id') || '0',
};
export function setAuth(obj) {
    localStorage.setItem('user', obj.token);
    localStorage.setItem('user_id', obj.user_id);
    auth = obj;
}
export function getAuth() {
    return auth;
}
export function getToken() {
    return auth === null || auth === void 0 ? void 0 : auth.token;
}
