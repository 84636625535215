import { PlusOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import {
  useRef,
  useState,
  useMemo,
  useEffect,
  SyntheticEvent,
  MouseEvent,
} from 'react';
import {
  SegmentationTable,
  TableInfoPayload,
  TableCellRect,
  TableConfig,
  HeadFootText,
  CellText,
  CustomTableConfig,
  Rect,
  TableFeature,
} from '../../index.d';
import { drawImage } from 'utils/canvas';
import { toDataUrl, toBlobPromise } from 'utils/canvasUtils';
import CustomConfigItem from '../../component/CustomConfigItem';
import { InterceptionModal } from '../../component/InterceptionModal';
import ScaleContainer from '../../component/ScaleContainer';
import TableCellItem from '../../component/TableCellItem';
import TableCellTextFeatureItem from '../../component/TableCellTextFeatureItem';
import TableHeadFootFeatureItem from '../../component/TableHeadFootFeatureItem';
import { useManipulatingState } from 'hooks';
import {
  getOCR,
  setTableOpticalCharacterRecognitionResult,
  getTableOpticalCharacterRecognitionResult,
} from '../../ocr';
import { DEFAULT_ADVANCED_SETTINGS } from '../../segmentation';
import {
  translateToSVG,
  getTextAreaTitle,
  getImageRatio,
  getTableFeatureFragementId,
  getTableCellFragementId,
} from '../../utils';
import {
  DEFAULT_BOX,
  HEAD_LOCATION,
  TABLE_COLUMN,
  TABLE_CONFIG_MODE_CUSTOM,
  TEXT_CONTAIN,
} from './constants';
import {
  getTableRecognitionRatio,
  setTableRecognitionRatio,
} from './ratioCache';
import TableFeatureTooltip from './TableFeature';
import {
  getBoxFromPoint,
  getGroupViaBox,
  getHeadFootTitle,
  getImageData,
  getRectViaBoxes,
  getTableCellRect,
  highlightTable,
  resetCanvas,
} from './utils';
import { log } from '@bewd/logger';
import { generateKey } from './keyUtils';

const TABLE_NO_CONFIG = 0;
const TABLE_CELL = 1;
const TABLE_FEATURES = 2;

interface TableFeatureExtractModalProps {
  visible: boolean;
  mode: number;
  src: string;
  pageIndex: number;
  tableIndex: number;
  tableInfo: SegmentationTable;
  tableFeature: TableFeature;
  onCancel: () => void;
  onTableFeatureAdd: (item: TableFeature) => void;
}
export default function TableFeatureExtractModal(
  props: TableFeatureExtractModalProps
) {
  const {
    pageIndex,
    tableIndex,
    visible,
    mode,
    src,
    tableInfo,
    tableFeature,
    onTableFeatureAdd,
  } = props;

  const tableRect = translateToSVG(tableInfo.table, 1);

  /*============================== Ref ==============================*/

  const scaleRef = useRef(1);

  const entering = useRef(false);

  const ref = useRef<HTMLImageElement>(null);

  const rectRef = useRef<SVGSVGElement>(null);

  const fragementRef = useRef<SVGSVGElement>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const canvasContainerRef = useRef<HTMLDivElement>(null);

  const tableCellSelectedBox = useRef(new Set<number>());

  const tableFeatureSelectedBox = useRef(new Set<number>());

  const rebindRef = useRef({
    _key: -1,
    img: '',
    key: '',
    ocrResult: '',
    boxNumber: -1,
    contentType: 0,
    dataType: TEXT_CONTAIN,
    rebinding: false,
  });

  /*============================== State ==============================*/

  const [ratio, setRatio] = useState(1);

  const [loaded, setLoaded] = useState(false);

  const [drawed, setDrawed] = useState(false);

  const [interceptVisible, setInterceptVisible] = useState(false);

  const [configType, setConfigType] = useState(TABLE_NO_CONFIG);

  const [rect, setRect] = useState({ w: 0, h: 0, x: 0, y: 0, x1: 0, y1: 0 });

  const [fragements, setFragements] = useState<TableCellRect[]>([]);

  const [tableFeatureFragements, setTableFreatureFragements] = useState<
    TableCellRect[]
  >([]);

  const [configItem, setConfigItem] = useState<TableConfig>({
    box_num: 0,
    img: '',
    _key: 0,
    mode: 1,
    key: 'xx',
    ocr_result: '',
    changeless_content: '',
    content_type: 1,
    intercept: [],
  });

  const [headFoots, setHeadFoots, addHeadFoot, removeHeadFoot] =
    useManipulatingState<HeadFootText>([]);

  const [cellTexts, setCellTexts, addCellText, removeCellText] =
    useManipulatingState<CellText>([]);

  const [customConfigs, setCustomConfigs, addCustomConfig, removeCustomConfig] =
    useManipulatingState<CustomTableConfig>([]);

  const [configs, setConfigs, addConfig, removeConfig] =
    useManipulatingState<TableConfig>([]);

  /*============================== Memo ==============================*/

  const rects = useMemo(() => {
    if (!loaded) {
      return [];
    }
    const rects = tableInfo.table_boxes.map((box, index) =>
      getTableCellRect(
        box,
        ratio,
        index,
        tableCellSelectedBox.current.has(index),
        tableFeatureSelectedBox.current.has(index)
      )
    );
    return rects;
  }, [tableInfo, ratio, configs, cellTexts, loaded]);

  /*============================== Effect ==============================*/

  useEffect(() => {
    if (canvasRef.current && ref.current) {
      if (configType === TABLE_NO_CONFIG) {
        resetCanvas(canvasRef.current, ref.current);
      } else {
        highlightTable(canvasRef.current, ref.current, tableRect);
      }
    }
  }, [configType]);

  useEffect(() => {
    let loaded = false;
    const ratioCache = getTableRecognitionRatio(src);
    if (typeof ratioCache === 'number' && visible) {
      loaded = true;
      setRatio(ratioCache);

      setTimeout(() => {
        if (ref.current) {
          const width = ref.current.width;
          const height = ref.current.height;
          const left = ref.current.offsetLeft;

          updateFragementAndRectSvg(width, height, left);
        }
      }, 0);

      if (canvasRef.current && ref.current) {
        drawImage(canvasRef.current, ref.current);
        setDrawed(true);
      }
    }

    setLoaded(loaded);
  }, [src, visible]);

  useEffect(() => {
    tableCellSelectedBox.current.clear();

    for (let i = 0; i < configs.length; i += 1) {
      const config = configs[i];
      tableCellSelectedBox.current.add(config.box_num);
    }
  }, [configs]);

  useEffect(() => {
    tableFeatureSelectedBox.current.clear();

    for (let i = 0; i < cellTexts.length; i += 1) {
      const cellText = cellTexts[i];
      tableFeatureSelectedBox.current.add(cellText.box_num);
    }
  }, [cellTexts]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (loaded && ctx && drawed) {
      const cellTexts = tableFeature.cell_text_list;
      const headFoots = tableFeature.head_foot_text_list;

      const tableFeaturesFragements = cellTexts.map((i) => {
        const box = rects[i.box_num];
        return {
          ...box,
        };
      });

      log('useEffect: ', ratio);

      const newCellTexts = cellTexts.map((i) => ({
        ...i,
      }));

      const newHeadFoots = headFoots.map((i) => ({ ...i }));

      setFragements(fragements);
      setTableFreatureFragements(tableFeaturesFragements);
      setCustomConfigs(customConfigs);
      setCellTexts(newCellTexts);
      setHeadFoots(newHeadFoots);
    }
  }, [tableFeature, loaded, drawed]);

  /*============================== Handler ==============================*/

  async function getBlob(
    ctx: CanvasRenderingContext2D,
    box: TableCellRect,
    ratio: number
  ) {
    const img = ctx.getImageData(
      box.x / ratio,
      box.y / ratio,
      box.w / ratio,
      box.h / ratio
    );
    return await toBlobPromise(img);
  }

  async function callOpticalCharacterRecognition(
    pageIndex: number,
    tableIndex: number,
    boxNumber: number,
    box: TableCellRect,
    callback: (result: string) => void
  ) {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const blob = await getBlob(ctx, box, ratio);
      if (blob) {
        const res = await getOCR(blob);
        let result = '';
        if (res && res.errcode === 0) {
          setTableOpticalCharacterRecognitionResult(
            pageIndex,
            tableIndex,
            boxNumber,
            res.data.results
          );
          result = res.data.results;
        }
        callback(result);
      }
    }
  }

  function onTableCellTextAdd(box: TableCellRect) {
    const payload = {
      img: '',
      _key: -1,
      box_num: box.boxNumber,
      data_type: TEXT_CONTAIN,
      text: '',
      recognizing: true,
    };

    if (rebindRef.current.rebinding) {
      payload._key = rebindRef.current._key;
      if (rebindRef.current.boxNumber === box.boxNumber) {
        payload.img = rebindRef.current.img;
        payload.data_type = rebindRef.current.dataType;
      }
    } else {
      payload._key = generateKey();
    }

    if (payload.img === '') {
      const ctx = canvasRef.current?.getContext('2d');

      if (ctx) {
        const imageData = getImageData(ctx, box, ratio);
        payload.img = toDataUrl(imageData) || '';
      }
    }

    const result = getTableOpticalCharacterRecognitionResult(
      pageIndex,
      tableIndex,
      box.boxNumber
    );

    if (typeof result === 'string') {
      payload.text = result;
      payload.recognizing = false;
    } else {
      callOpticalCharacterRecognition(
        pageIndex,
        tableIndex,
        box.boxNumber,
        box,
        (result) => {
          setCellTexts((list) => {
            const newList = list.map((i) => ({ ...i }));

            const target = newList.find((i) => i._key === payload._key);
            if (target) {
              target.recognizing = false;
              target.text = result;
            }

            return newList;
          });
        }
      );
    }

    const newTableFeatureFragements = tableFeatureFragements.map((i) => ({
      ...i,
    }));
    newTableFeatureFragements.push({ ...rect, boxNumber: box.boxNumber });

    setTableFreatureFragements(newTableFeatureFragements);

    if (rebindRef.current.rebinding) {
      const newCellTexts = cellTexts.map((i) => ({ ...i }));
      const index = newCellTexts.findIndex((i) => i._key === payload._key);
      if (index !== -1) {
        newCellTexts[index] = payload;
        setCellTexts(newCellTexts);
      }

      rebindRef.current.rebinding = false;
    } else {
      addCellText(payload);
    }
  }

  function onTableConfigAdd(box: TableCellRect) {
    const payload = {
      mode: Number(mode),
      box_num: box.boxNumber,
      _key: -1,
      key: '',
      img: '',
      ocr_result: '',
      content_type: 0,
      changeless_content: '',
      intercept: [],
      recognizing: true,
    };

    if (rebindRef.current.rebinding) {
      payload._key = rebindRef.current._key;
      payload.key = rebindRef.current.key;

      // 绑定相同的盒子则复用 img ocr_result
      if (rebindRef.current.boxNumber === box.boxNumber) {
        payload.img = rebindRef.current.img;
        payload.content_type = rebindRef.current.contentType;
      }
    } else {
      payload._key = generateKey();
    }

    if (payload.img === '') {
      const ctx = canvasRef.current?.getContext('2d');

      if (ctx) {
        const imageData = getImageData(ctx, box, ratio);
        payload.img = toDataUrl(imageData) || '';
      }
    }

    const result = getTableOpticalCharacterRecognitionResult(
      pageIndex,
      tableIndex,
      box.boxNumber
    );

    if (typeof result === 'string') {
      payload.recognizing = false;
      payload.ocr_result = result;
    } else {
      callOpticalCharacterRecognition(
        pageIndex,
        tableIndex,
        box.boxNumber,
        box,
        (result) => {
          setConfigs((configs) => {
            const newConfigs = configs.map((i) => ({ ...i }));

            const target = newConfigs.find((i) => i._key === payload._key);
            if (target) {
              target.recognizing = false;
              target.ocr_result = result;
            }

            return newConfigs;
          });
        }
      );
    }

    const newFragements = fragements.map((i) => ({ ...i }));
    newFragements.push({ ...rect, boxNumber: box.boxNumber });

    setFragements(newFragements);

    if (rebindRef.current.rebinding) {
      const newConfigs = configs.map((i) => ({ ...i }));

      const index = newConfigs.findIndex((i) => i._key === payload._key);
      if (index !== -1) {
        newConfigs[index] = payload;
        setConfigs(newConfigs);
      }
      rebindRef.current.rebinding = false;
    } else {
      addConfig(payload);
    }
  }

  function openSetupModal(item: TableConfig) {
    setConfigItem(item);
    setInterceptVisible(true);
  }

  function onCustomTableConfig() {
    const payload = {
      id: generateKey(),
      mode: TABLE_CONFIG_MODE_CUSTOM,
      key: '',
      changeless_content: '',
    };

    addCustomConfig(payload);
  }

  function onCustomConfigUpdate(item: CustomTableConfig) {
    const newCustomConfigs = customConfigs.map((i) => ({ ...i }));
    const target = newCustomConfigs.find((i) => i.id === item.id);
    if (target) {
      Object.assign(target, item);
      setCustomConfigs(newCustomConfigs);
    }
  }

  function onHeadFootAdd(location: number) {
    const payload = {
      location,
      _key: generateKey(),
      data_type: TEXT_CONTAIN,
      text: '',
    };
    addHeadFoot(payload);
  }

  function onHeadFootUpdate(item: HeadFootText) {
    const newHeadFoots = headFoots.map((i) => ({ ...i }));
    const target = newHeadFoots.find((i) => item._key === i._key);
    if (target) {
      Object.assign(target, item);

      setHeadFoots(newHeadFoots);
    }
  }

  function triggerCellTextRebind(item: CellText) {
    const newFragements = tableFeatureFragements
      .map((f) => ({ ...f }))
      .filter((i) => i.boxNumber !== item.box_num);
    setTableFreatureFragements(newFragements);
    setConfigType(TABLE_FEATURES);
    rebindRef.current._key = item._key;
    rebindRef.current.img = item.img;
    rebindRef.current.boxNumber = item.box_num;
    rebindRef.current.dataType = item.data_type;
    rebindRef.current.rebinding = true;
    tableFeatureSelectedBox.current.delete(rebindRef.current.boxNumber);
  }

  function onCellTextUpdate(_key: number, payload: any) {
    const newCellTexts = cellTexts.map((i) => ({ ...i }));
    const target = newCellTexts.find((i) => _key === i._key);
    if (target) {
      Object.assign(target, payload);

      setCellTexts(newCellTexts);
    }
  }

  function triggerTableCellItemRebind(item: TableConfig) {
    const newFragements = fragements
      .map((f) => ({ ...f }))
      .filter((i) => i.boxNumber !== item.box_num);
    setFragements(newFragements);

    setConfigType(TABLE_CELL);
    rebindRef.current._key = item._key;
    rebindRef.current.img = item.img;
    rebindRef.current.key = item.key;
    rebindRef.current.ocrResult = item.ocr_result;
    rebindRef.current.boxNumber = item.box_num;
    rebindRef.current.contentType = item.content_type;
    rebindRef.current.rebinding = true;
    tableCellSelectedBox.current.delete(rebindRef.current.boxNumber);
  }

  function onTableCellUpdate(item: TableConfig) {
    const newConfigs = configs.map((i) => ({ ...i }));
    const target = newConfigs.find((i) => i._key === item._key);

    if (target) {
      Object.assign(target, item);

      setConfigs(newConfigs);
    }
  }

  function onInterceptConfirm(data: string[]) {
    const newConfigs = configs.map((i) => ({ ...i }));
    const target = newConfigs.find((i) => i.box_num === configItem.box_num);
    if (target) {
      target.intercept = data;

      setConfigs(newConfigs);
    }

    setInterceptVisible(false);
  }

  function onOk() {
    // TODO:
    // validate

    try {
      let hasTableFeature = false;

      if (cellTexts.length > 0) {
        hasTableFeature = true;
      }
      if (headFoots.length > 0) {
        hasTableFeature = true;
      }

      if (!hasTableFeature) {
        throw new Error('请添加表格特征');
      }

      for (let i = 0; i < cellTexts.length; i += 1) {
        const item = cellTexts[i];
        if (item.text === '') {
          throw new Error(
            `表格特征 - ${getTextAreaTitle(item.box_num)}: 文本不能为空`
          );
        }
      }

      for (let i = 0; i < headFoots.length; i += 1) {
        const item = headFoots[i];
        if (item.text === '') {
          throw new Error(
            `表格特征 - ${getHeadFootTitle(item.location)}: 文本不能为空`
          );
        }
      }

      const payload: TableFeature = {
        _key: generateKey(),
        page_index: 0, // add temp value, correct in outer
        table_index: 0, // add temp value, correct in outer
        cell_text_list: cellTexts,
        head_foot_text_list: headFoots,
      };

      if (tableFeature._key !== -1) {
        payload._key = tableFeature._key;
        payload.page_index = tableFeature.page_index;
        payload.table_index = tableFeature.table_index;
      }

      onTableFeatureAdd(payload);
      props.onCancel();
    } catch (error: any) {
      message.error(error.message);
    }
    // add data to upstream
  }

  function onCancel() {
    // TODO:
    // clean up
    props.onCancel();
  }

  function updateFragementAndRectSvg(
    width: number,
    height: number,
    left: number
  ) {
    if (fragementRef.current) {
      // @ts-ignore
      fragementRef.current.style.width = width;
      // @ts-ignore
      fragementRef.current.style.height = height;

      fragementRef.current.style.left = `${left}px`;
    }

    if (rectRef.current) {
      // @ts-ignore
      rectRef.current.style.width = width;
      // @ts-ignore
      rectRef.current.style.height = height;
      rectRef.current.style.left = `${left}px`;
    }
  }

  function onMouseEnter() {
    entering.current = true;
  }

  function onMouseLeave() {
    entering.current = false;
  }

  function onMouseMove(e: MouseEvent) {
    if (!entering.current || !ref.current) {
      return;
    }
    if (configType === TABLE_NO_CONFIG) {
      setRect(DEFAULT_BOX);
      return;
    }

    const wrapper = ref.current.getBoundingClientRect();
    const point = { x: e.clientX - wrapper.left, y: e.clientY - wrapper.top };

    point.x = point.x / scaleRef.current;
    point.y = point.y / scaleRef.current;

    const box = getBoxFromPoint(point, rects);

    if (!box) {
      return;
    }

    let boxes = [box];

    let rect = box as Rect;

    if (configType === TABLE_CELL && mode === TABLE_COLUMN) {
      boxes = getGroupViaBox(box, rects);

      let r = getRectViaBoxes(box, boxes);
      if (r) rect = r;
    }

    if (configType === TABLE_CELL) {
      for (let i = 0; i < boxes.length; i += 1) {
        const item = boxes[i];
        if (tableCellSelectedBox.current.has(item.boxNumber)) {
          return;
        }
      }
    }

    if (configType === TABLE_FEATURES) {
      if (tableFeatureSelectedBox.current.has(box.boxNumber)) {
        return;
      }
    }

    if (rect) {
      setRect(rect);
    }
  }

  function handleClick(e: MouseEvent) {
    if (ref.current) {
      const wrapper = ref.current.getBoundingClientRect();
      const point = { x: e.clientX - wrapper.left, y: e.clientY - wrapper.top };

      point.x = point.x / scaleRef.current;
      point.y = point.y / scaleRef.current;

      const box = getBoxFromPoint(point, rects);

      if (box !== false) {
        if (TABLE_CELL === configType) {
          onTableConfigAdd(box);
        }

        if (TABLE_FEATURES === configType) {
          if (tableFeatureSelectedBox.current.has(box.boxNumber)) {
            return;
          }

          onTableCellTextAdd(box);
        }
      }
    }

    setConfigType(TABLE_NO_CONFIG);
  }

  function onConfigRemove(item: TableConfig) {
    removeConfig((v) => v._key !== item._key);

    const newFragements = fragements.filter(
      (i) => i.boxNumber !== item.box_num
    );

    setFragements(newFragements);
  }

  function onTableFeaturesRemove(item: CellText) {
    removeCellText((i) => i._key !== item._key);

    const newFragements = tableFeatureFragements.filter(
      (i) => i.boxNumber !== item.box_num
    );

    setTableFreatureFragements(newFragements);
  }

  function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
    const { currentTarget } = e;
    const ratio = getImageRatio(currentTarget);

    const width = currentTarget.width;
    const height = currentTarget.height;
    const left = currentTarget.offsetLeft;

    if (canvasContainerRef.current) {
      canvasContainerRef.current.style.width = `${width}px`;
      canvasContainerRef.current.style.height = `${height}px`;
      canvasContainerRef.current.style.left = `${left}px`;
    }

    updateFragementAndRectSvg(width, height, left);

    setTableRecognitionRatio(src, ratio);

    setRatio(ratio);

    setLoaded(true);

    if (canvasRef.current && ref.current) {
      drawImage(canvasRef.current, ref.current);
      setDrawed(true);
    }
  }

  return (
    <Modal
      style={{ top: 0 }}
      width="1280px"
      visible={visible}
      title="表格特征"
      onOk={onOk}
      onCancel={onCancel}
    >
      <div className="flex">
        <div style={{ width: 800 }}>
          <ScaleContainer
            height="780px"
            onScaleChange={(scale) => (scaleRef.current = scale)}
          >
            <div>
              <div
                onMouseMove={onMouseMove}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className="mx-auto relative"
                style={{ maxHeight: 780 }}
              >
                <div className="absolute" ref={canvasContainerRef}>
                  <canvas className="w-full" ref={canvasRef}></canvas>
                </div>
                <img
                  ref={ref}
                  src={src}
                  onLoad={onImageLoad}
                  crossOrigin="anonymous"
                  className="mx-auto"
                  style={{ maxHeight: 780 }}
                  alt="table column recognition"
                />
                <svg ref={fragementRef} className="h-full absolute top-0">
                  {tableFeatureFragements.map((rect) => (
                    <rect
                      id={getTableFeatureFragementId(rect.boxNumber)}
                      key={rect.boxNumber}
                      width={rect.w}
                      height={rect.h}
                      x={rect.x}
                      y={rect.y}
                      className="text-opacity-50 text-blue-200 fill-current cursor-pointer"
                      stroke="transparent"
                    />
                  ))}
                  {fragements.map((rect) => (
                    <rect
                      id={getTableCellFragementId(rect.boxNumber)}
                      key={rect.boxNumber}
                      width={rect.w}
                      height={rect.h}
                      x={rect.x}
                      y={rect.y}
                      className="text-opacity-50 text-green-200 fill-current cursor-pointer"
                      stroke="transparent"
                    />
                  ))}
                </svg>
                <svg ref={rectRef} className="h-full absolute top-0">
                  <rect
                    width={rect.w}
                    height={rect.h}
                    x={rect.x}
                    y={rect.y}
                    onClick={handleClick}
                    className="hover:text-green-200 hover:opacity-50 fill-current text-transparent cursor-pointer"
                  />
                </svg>
              </div>
            </div>
          </ScaleContainer>
        </div>

        <div className="pl-4 flex-1 overflow-auto" style={{ maxHeight: 780 }}>
          <TableFeatureTooltip />
          <ul>
            {cellTexts.map((item) => (
              <TableCellTextFeatureItem
                key={item._key}
                triggerRebind={triggerCellTextRebind}
                setItem={onCellTextUpdate}
                removeItem={onTableFeaturesRemove}
                item={item}
              />
            ))}
          </ul>
          <ul>
            {headFoots.map((item) => (
              <TableHeadFootFeatureItem
                key={item._key}
                setItem={onHeadFootUpdate}
                removeItem={(item) =>
                  removeHeadFoot((i) => item._key !== i._key)
                }
                item={item}
              />
            ))}
          </ul>
          <div className="mb-4">
            <button
              className="text-primary"
              onClick={() => setConfigType(TABLE_FEATURES)}
            >
              <PlusOutlined /> 添加表格内容特征
            </button>
          </div>
        </div>
      </div>

      <InterceptionModal
        item={configItem}
        visible={interceptVisible}
        onOk={onInterceptConfirm}
        onCancel={() => setInterceptVisible(false)}
      />
    </Modal>
  );
}
