/**
 * 选择 ocr 文本
 * 框选锚点，用于辅助定位cor文本的位置
 *
 */

import { useEffect, useRef, useState } from "react";
import { OcrText, Rect } from "../../index.d";
import { translateToSVG2 } from "../../utils";
import { drawImage } from "utils/canvas";
import { STROKE } from "./constants";
import { toInteger } from "lodash";
import Iconfont from "../../component/Iconfont";
import { Circle, SVG, Rect as SVGRect } from "@svgdotjs/svg.js";
import { classOfPoints, directions, getPointsArray } from "../../pointUtils";
import { initSVG } from "../../svg";

interface CustomTemplateTextExtractionProps {
  src: string;
  enableOcrTextPick: boolean;
  enableCapture: boolean;
  textList: OcrText[];
  onOcrTextPick: (text: OcrText, img: ImageData) => void;
  onCaptureStop: () => void;
  onCaptureStart: () => void;
  onCaptureConfirm: (data: ImageData, point: number[]) => void;
}
export default function CustomTemplateTextExtraction(
  props: CustomTemplateTextExtractionProps
) {
  const {
    src,
    textList,
    enableOcrTextPick,
    enableCapture,
    onOcrTextPick,
    onCaptureConfirm,
    onCaptureStart,
    onCaptureStop,
  } = props;
  const [captureBox, setCaptureBox] = useState({
    created: false,
    creating: false,
    x1: -4,
    y1: -4,
    x2: 0,
    y2: 0,
    w: 0,
    h: 0,
  });
  const [ratio, setRatio] = useState(1);
  const rects = textList.map((t) => translateToSVG2(t.box, ratio));
  const ref = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const actionRef = useRef<HTMLDivElement>(null);
  const movingRef = useRef({ moving: false, x: 0, y: 0 });
  const draw = useRef(SVG());
  const shape = useRef<SVGRect | null>(null);
  const resizeRef = useRef<string | null>(null);
  const pointsRef = useRef<Circle[] | null>(null);
  const originalClickPoint = useRef({ x: 0, y: 0 });
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!ref.current) return;
    let cursor = "auto";
    draw.current.removeClass("bg-gray-500");
    draw.current.addClass("hidden");
    if (enableCapture) {
      cursor = "crosshair";
      draw.current.size(ref.current.clientWidth, ref.current.clientHeight);
      draw.current.addClass("bg-gray-500");
      draw.current.removeClass("hidden");
    }
    wrapperRef.current.style.cursor = cursor;
  }, [enableCapture]);

  useEffect(() => {
    if (enableOcrTextPick) {
      svgRef.current?.classList.remove("pointer-events-none");
    } else {
      svgRef.current?.classList.add("pointer-events-none");
    }
  }, [enableOcrTextPick]);

  useEffect(() => {
    function handleCancel(e: KeyboardEvent) {
      if (e.repeat) {
        return;
      }

      if (e.key === "Escape") {
        if (actionRef.current) {
          actionRef.current.style.display = "none";
        }

        setCaptureBox({
          created: false,
          creating: false,
          x1: -4,
          y1: -4,
          x2: 0,
          y2: 0,
          w: 0,
          h: 0,
        });
        onCaptureStop();
      }
    }

    document.addEventListener("keydown", handleCancel);

    return () => {
      document.removeEventListener("keydown", handleCancel);
    };
  }, []);

  useEffect(() => {
    shape.current = draw.current.rect();
    const g = [];

    for (let i = 0; i < classOfPoints.length; i += 1) {
      const cls = classOfPoints[i];
      const direction = directions[i];

      const circle = draw.current.circle(7);
      circle.stroke(STROKE).fill("white").center(-1, -1).addClass(cls);
      circle.on("mousedown", (e) => {
        e.preventDefault();
        e.stopPropagation();
        resizeRef.current = direction;
      });

      circle.on("mouseup", (e) => {
        e.preventDefault();
        e.stopPropagation();
        resizeRef.current = null;
      });
      g.push(circle);
    }

    observerRef.current = new MutationObserver(() => {
      if (shape.current && pointsRef.current && actionRef.current) {
        const bbox = shape.current.bbox();
        const points = getPointsArray(bbox);

        actionRef.current.style.top = `${bbox.y2 + 2}px`;
        actionRef.current.style.left = `${bbox.x2 - 45}px`;
        actionRef.current.style.display = "block";

        for (let i = 0; i < points.length; i += 1) {
          const point = points[i];
          const c = pointsRef.current[i];

          c.center(point[0], point[1]);
        }
      }
    });

    observerRef.current.observe(shape.current.node, { attributes: true });

    shape.current.on("mousedown", (e) => {
      movingRef.current.moving = true;
      // @ts-ignore
      movingRef.current.x = e.clientX;
      // @ts-ignore
      movingRef.current.y = e.clientY;
    });

    pointsRef.current = g;
  }, []);

  useEffect(() => {
    shape.current?.move(captureBox.x1, captureBox.y1);
    shape.current?.size(captureBox.w, captureBox.h);
  }, [captureBox]);

  useEffect(() => {
    if (enableCapture) {
      if (canvasRef.current && ref.current) {
        canvasRef.current.height = ref.current.height;
        canvasRef.current.width = ref.current.width;
        const ctx = canvasRef.current.getContext("2d");

        ctx?.drawImage(
          ref.current,
          0,
          0,
          ref.current.width,
          ref.current.height
        );
      }
    }
  }, [enableCapture]);

  function handleImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { currentTarget } = e;
    const ratio = 1024 / currentTarget.naturalWidth;
    setRatio(ratio);
    if (canvasRef.current && ref.current) {
      canvasRef.current.height = ref.current.height;
      canvasRef.current.width = ref.current.width;
      const ctx = canvasRef.current.getContext("2d");

      ctx?.drawImage(ref.current, 0, 0, ref.current.width, ref.current.height);
      if (ctx) {
        //   handleImageLoad(ctx, ratio);
      }
    }

    setTimeout(() => {
      if (wrapperRef.current && ref.current) {
        initSVG(
          draw.current,
          wrapperRef.current,
          ref.current.clientWidth,
          ref.current.clientHeight
        );
      }
    }, 20);
  }
  function handleRectClick(index: number, rect: Rect) {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      const img = ctx.getImageData(rect.x, rect.y, rect.w, rect.h);
      onOcrTextPick(textList[index], img);
    }
  }

  function resetCropBox() {
    if (actionRef.current) {
      actionRef.current.style.display = "none";
    }

    setCaptureBox({
      created: false,
      creating: false,
      x1: -4,
      y1: -4,
      x2: 0,
      y2: 0,
      w: 0,
      h: 0,
    });
  }

  function handleCancel() {
    onCaptureStop();
    resetCropBox();
  }

  function handleConfirm() {
    const ctx = canvasRef.current?.getContext("2d");

    if (ctx && ref.current) {
      const ratio = ref.current.clientWidth / ref.current.naturalWidth;
      const img = ctx.getImageData(
        captureBox.x1,
        captureBox.y1,
        captureBox.w,
        captureBox.h
      );
      let x1 = toInteger(captureBox.x1 / ratio);
      let y1 = toInteger(captureBox.y1 / ratio);
      let x2 = toInteger(captureBox.x2 / ratio);
      let y2 = toInteger(captureBox.y2 / ratio);
      onCaptureConfirm(img, [x1, y1, x2, y1, x2, y2, x1, y2]);

      resetCropBox();
    }
  }

  function handleClick(e: React.MouseEvent) {
    if (!enableCapture) return;
    if (captureBox.creating && pointsRef.current) {
      setCaptureBox({ ...captureBox, creating: false, created: true });
      return;
    }
    if (captureBox.created) return;

    if (wrapperRef.current && shape.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const currentBox = { ...captureBox };
      currentBox.x1 = e.clientX - rect.left;
      currentBox.y1 = e.clientY - rect.top;
      currentBox.x2 = currentBox.x1;
      currentBox.y2 = currentBox.y2;
      originalClickPoint.current.x = currentBox.x1;
      originalClickPoint.current.y = currentBox.y1;
      currentBox.creating = true;
      onCaptureStart();
      setCaptureBox(currentBox);
      shape.current
        .move(currentBox.x1, currentBox.y1)
        .fill(STROKE)
        .attr("fill-opacity", 0.5);
    }
  }

  function handleMove(e: React.MouseEvent) {
    if (captureBox.creating) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (rect) {
        const currentBox = { ...captureBox };
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        if (x > originalClickPoint.current.x) {
          currentBox.x1 = originalClickPoint.current.x;
          currentBox.x2 = x;
        } else {
          currentBox.x1 = x;
          currentBox.x2 = originalClickPoint.current.x;
        }
        if (y > originalClickPoint.current.y) {
          currentBox.y1 = originalClickPoint.current.y;
          currentBox.y2 = y;
        } else {
          currentBox.y1 = y;
          currentBox.y2 = originalClickPoint.current.y;
        }

        currentBox.w = currentBox.x2 - currentBox.x1;
        currentBox.h = currentBox.y2 - currentBox.y1;

        setCaptureBox(currentBox);
      }
    }

    if (resizeRef.current !== null) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (rect) {
        const currentBox = { ...captureBox };
        switch (resizeRef.current) {
          case "lt":
            currentBox.x1 = e.clientX - rect.left;
            currentBox.y1 = e.clientY - rect.top;
            break;
          case "l":
            currentBox.x1 = e.clientX - rect.left;
            break;
          case "lb":
            currentBox.x1 = e.clientX - rect.left;
            currentBox.y2 = e.clientY - rect.top;
            break;
          case "t":
            currentBox.y1 = e.clientY - rect.top;
            break;
          case "b":
            currentBox.y2 = e.clientY - rect.top;
            break;
          case "rt":
            currentBox.x2 = e.clientX - rect.left;
            currentBox.y1 = e.clientX - rect.top;
            break;
          case "r":
            currentBox.x2 = e.clientX - rect.left;
            break;
          case "rb":
            currentBox.x2 = e.clientX - rect.left;
            currentBox.y2 = e.clientY - rect.top;
            break;
          default:
            break;
        }

        currentBox.w = currentBox.x2 - currentBox.x1;
        currentBox.h = currentBox.y2 - currentBox.y1;

        setCaptureBox(currentBox);
      }
    }

    if (movingRef.current.moving) {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (rect) {
        const currentBox = { ...captureBox };
        const dx = e.clientX - movingRef.current.x;
        const dy = e.clientY - movingRef.current.y;
        movingRef.current.x = e.clientX;
        movingRef.current.y = e.clientY;
        currentBox.x1 += dx;
        currentBox.x2 += dx;
        currentBox.y1 += dy;
        currentBox.y2 += dy;
        if (currentBox.x1 < 0) {
          currentBox.x1 = 0;
        }
        if (currentBox.y1 < 0) {
          currentBox.y1 = 0;
        }
        if (currentBox.x2 > rect.width) {
          currentBox.x2 = rect.width;
          currentBox.x1 = rect.width - currentBox.w;
        }
        if (currentBox.y2 > rect.height) {
          currentBox.y2 = rect.height;
          currentBox.y1 = rect.height - currentBox.h;
        }
        setCaptureBox(currentBox);
      }
    }
  }
  return (
    <div
      className="relative overflow-hidden"
      ref={wrapperRef}
      onClick={handleClick}
      onMouseMove={handleMove}
    >
      <canvas className="w-full" ref={canvasRef} />
      <img
        onLoad={handleImageLoad}
        ref={ref}
        src={src}
        crossOrigin="anonymous"
        alt="extraction table"
        className="block w-full text-extration-image max-w-full absolute z-0 top-0"
      />
      <svg className="absolute w-full h-full top-0" ref={svgRef}>
        {rects.map((rect, index) => (
          <rect
            key={textList[index]._key}
            id={textList[index]._key.toString()}
            width={rect.w}
            height={rect.h}
            x={rect.x}
            y={rect.y}
            onClick={() => handleRectClick(index, rect)}
            className="text-opacity-50  fill-current cursor-pointer text-transparent"
            strokeWidth="2"
            stroke={enableOcrTextPick ? STROKE : "transparent"}
          ></rect>
        ))}
      </svg>
      <div className="absolute z-10 text-white hidden" ref={actionRef}>
        <button
          onClick={handleCancel}
          style={{ marginRight: 1 }}
          className="bg-green-500 px-1"
        >
          <Iconfont type="iconGroup56" />
        </button>
        <button onClick={handleConfirm} className="bg-green-500 px-1">
          <Iconfont type="iconGroup55" />
        </button>
      </div>
    </div>
  );
}
