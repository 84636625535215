import Iconfont from './Iconfont';

interface IconLibraryProps {
  className: string;
  color: string;
  icon: string;
  onChange: (type: string) => void;
}

const lib = [
  'iconclock-circle',
  'iconcontacts-fill',
  'iconrenshidangan',
  'iconyouxiang',
  'iconfapiao',
  'iconcaigou',
  'icondayin',
  'iconfabu',
  'iconmoney',
  'iconhuochepiao',
  'iconshenpi',
  'iconGroup162',
  'iconliucheng',
  'iconliucheng1',
  'iconricheng2',
  'iconxiazai',
  'iconpeople',
  'iconpeople_money',
  'iconhuiyi',
  'icondaiban',
  'iconhuiyuan',
  'iconshezhi',
  'iconicon1_shoucangmia',
  'iconrenyuanbu',
  'iconGroup195',
  'icondashboard-fill',
  'iconbaogaogongdan',
  'iconsap',
  'iconqianyi',
  'iconGroup53',
  'iconOCR',
  'iconSynchronizationtongbu',
];

export default function IconLibrary(props: IconLibraryProps) {
  const { onChange, color, icon, className } = props;
  function onClick(icon: string) {
    onChange(icon);
  }

  return (
    <ul className={className} style={{ fontSize: '16px', lineHeight: '1em' }}>
      {lib.map((item) => (
        <li key={item} onClick={() => onClick(item)} className="mx-1 mb-2">
          <Iconfont
            style={{ color: item === icon ? color : 'black' }}
            type={item}
          />
        </li>
      ))}
    </ul>
  );
}
