import Iconfont from '../../component/Iconfont';
import { TableFeature } from '../../index.d';
import {
  getDataTypeText,
  getTableFeatureRectId,
  getTemplateTableId,
} from '../../utils';
import { getImageData, handleRectHover } from './utils';

interface TableFeatureItemProps {
  item: TableFeature;
  onRemove: (key: number) => void;
}
export default function TableFeatureItem(props: TableFeatureItemProps) {
  const { item, onRemove } = props;

  /*============================== Effect ==============================*/

  function onClick() {
    const target = getTemplateTableTarget(item.page_index, item.table_index);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function toggle(hover: boolean) {
    const target = getTemplateTableTarget(item.page_index, item.table_index);
    if (target) {
      handleRectHover(target, hover);
    }
  }

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
      className="p-2 mb-4 cursor-pointer group relative border-2 border-dashed border-gray-700 hover:border-primary hover:bg-green-50"
    >
      <div className="absolute right-0 opacity-0 group-hover:opacity-100 text-red-500">
        <Iconfont
          type="icondelete"
          className="px-2 text-base"
          onClick={() => onRemove(item._key)}
        />
      </div>
      <div className="mb-2 text-gray-700">存在表格满足以下条件</div>
      <ul>
        {item.cell_text_list.map((i) => (
          <li key={i._key}>
            <div className="h-16">
              <img className="w-full h-full object-cover" src={i.img} alt="" />
            </div>
            <div>
              <span>以上单元格文本</span>
              <span> {getDataTypeText(i.data_type)} </span>
              <span className="text-primary break-all">{i.text}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
function getTemplateTableTarget(pageIndex: number, tableIndex: number) {
  const id = getTemplateTableId(`${pageIndex}-${tableIndex}`);
  return document.getElementById(id);
}
