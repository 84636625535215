import { Popover } from 'antd';
import { RenderFunction } from 'antd/lib/tooltip';
import { Category } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { permissionGuard } from '../../permission';

interface ManageCategoryProps {
  content: React.ReactNode | RenderFunction;
}

function ManageCategory(props: ManageCategoryProps) {
  return (
    <Popover content={props.content} trigger="hover">
      <span className="text-primary">管理分类</span>
    </Popover>
  );
}

export default permissionGuard(ManageCategory);

interface ManageCategoryContentProps {
  category: any[];
  onEdit: (item: Category) => void;
  onDelete: (item: Category) => void;
}
export function ManageCategoryContent(props: ManageCategoryContentProps) {
  const { category } = props;
  const c = category.filter((i) => i.id !== '' && i.id !== 1);

  return (
    <div>
      {c.map((i) => (
        <div key={i.id} className="w-64 px-3 pt-2">
          <div className="flex">
            <div className="flex-1 text-gray-700">{i.name}</div>
            <div className="w-12">
              <Iconfont
                type="iconedit"
                className="mr-2 text-base"
                onClick={() => props.onEdit(i)}
              />
              <Iconfont
                type="icondelete"
                className="mr-2 text-base"
                onClick={() => props.onDelete(i)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
