export const COMMON_OCR_ACTIVITY = '/doc/COMMON_OCR_ACTIVITY.pdf'; // 自定义OCR_Activity使用手册
export const COMMON_OCR_API = '/doc/COMMON_OCR_API.pdf'; // 自定义OCR_API调用接口文档
export const CUSTOM_OCR_ACTIVITY = '/doc/CUSTOM_OCR_ACTIVITY.pdf'; // 通用OCR_Activity使用手册
export const CUSTOM_OCR_API = '/doc/CUSTOM_OCR_API.pdf'; // 通用OCR_API调用接口文档
export const ID_CARD_OCR_ACTIVITY = '/doc/ID_CARD_OCR_ACTIVITY.pdf'; // 身份证OCR_Activity使用手册
export const ID_CARD_OCR_API = '/doc/ID_CARD_OCR_API.pdf'; // 身份证OCR_API调用接口文档
export const INVOICE_OCR_ACTIVITY = '/doc/INVOICE_OCR_ACTIVITY.pdf'; // 发票OCR_Activity使用手册
export const INVOICE_OCR_API = '/doc/INVOICE_OCR_API.pdf'; // 发票OCR_API调用接口文档
export const TRAIN_TICKET_OCR_ACTIVITY = '/doc/TRAIN_TICKET_OCR_ACTIVITY.pdf'; // 火车票OCR_Activity使用手册
export const TRAIN_TICKET_OCR_API = '/doc/TRAIN_TICKET_OCR_API.pdf'; // 火车票OCR_API调用接口文档

export function ocr_handbook(index) {
  switch (index) {
    case 1:
      return COMMON_OCR_ACTIVITY;
    case 3:
      return ID_CARD_OCR_ACTIVITY;
    case 4:
      return INVOICE_OCR_ACTIVITY;
    case 2:
      return TRAIN_TICKET_OCR_ACTIVITY;
    default:
      return COMMON_OCR_ACTIVITY;
  }
}

export function ocr_api(index) {
  switch (index) {
    case 1:
      return COMMON_OCR_API;
    case 3:
      return ID_CARD_OCR_API;
    case 4:
      return INVOICE_OCR_API;
    case 2:
      return TRAIN_TICKET_OCR_API;
    default:
      return COMMON_OCR_API;
  }
}
