import { getTemplateIcon } from '../utils';
import { ColorLibrary } from 'components';
import Iconfont from './Iconfont';
import IconLibrary from './IconLibrary';

interface TemplateIconProps {
  onChange?: (value: string) => void;
  value?: string | string[];
}

export default function TemplateIcon(props: TemplateIconProps) {
  const { value, onChange } = props;
  const [icon, color] = getTemplateIcon(value || '');

  function handleChange(icon: string, color: string) {
    onChange && onChange(`${icon} ${color}`);
  }
  return (
    <div className="flex">
      <span style={{ width: 192 }} className="mr-4">
        <IconLibrary
          icon={icon}
          className="flex flex-wrap -mx-1 -mb-2"
          color={color}
          onChange={(icon) => handleChange(icon, color)}
        />
      </span>
      <span style={{ width: 208 }} className="mr-4">
        <ColorLibrary
          color={color}
          className="flex flex-wrap -mx-1 -mb-2"
          onChange={(color) => handleChange(icon, color)}
        />
      </span>
      <span
        className="h-10 w-10 rounded text-white"
        style={{ backgroundColor: color }}
      >
        <Iconfont style={{ fontSize: '32px' }} className="p-1" type={icon} />
      </span>
    </div>
  );
}
