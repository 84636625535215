import { Modal, Form, Switch, InputNumber } from 'antd';
import { useEffect } from 'react';
import type { TableSegmentationConfig } from '../index.d';
import { DEFAULT_ADVANCED_SETTINGS } from '../segmentation';

interface AdvancedSettingsProps {
  visible: boolean;
  advancedSetting: TableSegmentationConfig;
  onConfirm: (values: any) => void;
}
export default function AdvancedSettings(props: AdvancedSettingsProps) {
  const { visible, advancedSetting, onConfirm } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(advancedSetting);
    }
  }, [visible, form, advancedSetting]);

  function onFinish(values: any) {
    const setting = {
      ...DEFAULT_ADVANCED_SETTINGS,
      ...values,
    };
    onConfirm(setting);
  }
  function onOk() {
    form.submit();
  }
  return (
    <Modal
      onOk={onOk}
      onCancel={() => onConfirm(DEFAULT_ADVANCED_SETTINGS)}
      visible={visible}
      width="560px"
      title="高级设置"
      cancelText="恢复推荐设置"
    >
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={DEFAULT_ADVANCED_SETTINGS}
        labelCol={{ span: 12 }}
      >
        <Form.Item
          name="is_remove_seal"
          label="清除印章"
          tooltip="清除印章再识别，适用于印章遮盖内容影响识别结果的情况"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="row_dil_iter"
          label="水平线检测"
          tooltip="水平线检测参数：默认3，推荐1~5.
                  增大本参数有利于检测到更小、更细的水平线"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.row_dil_iter.toString()}
          />
        </Form.Item>
        <Form.Item
          name="col_dil_iter"
          label="垂直线检测"
          tooltip="垂直线检测参数：默认1，推荐1~3.
                  增大本参数有利于检测到更小、更细的垂直线"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.col_dil_iter.toString()}
          />
        </Form.Item>
        <Form.Item
          name="area_threshold"
          label="小矩形框面积过滤"
          tooltip="小矩形框面积过滤参数：默认300，推荐不超过1000，过滤小框，增大本参数会过滤掉大一点的矩形框"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.area_threshold.toString()}
          />
        </Form.Item>
        <Form.Item
          name="h_threshold"
          tooltip="小矩形框高度过滤参数：默认24，推荐不超过30，根据高度过滤小矩形框，增大本参数会过滤点高一点的矩形框"
          label="小矩形框高度过滤"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.h_threshold.toString()}
          />
        </Form.Item>
        <Form.Item
          name="w_threshold"
          tooltip="小矩形框宽度过滤参数：默认30，推荐不超过50，根据宽度过滤小矩形框，增大本参数会过滤点宽一点的矩形框"
          label="小矩形框宽度过滤"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.w_threshold.toString()}
          />
        </Form.Item>
        <Form.Item
          name="hw_rate_threshold"
          tooltip="矩形框高宽比过滤参数：默认3.6，主要用于过滤细长的矩形框"
          label="矩形框高宽比过滤"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.hw_rate_threshold.toString()}
          />
        </Form.Item>
        <Form.Item
          name="row_h_threshold"
          tooltip="同行矩形框排列参数：默认16，推荐不超过30，同一行内按顺序排列矩形框"
          label="同行矩形框排列"
        >
          <InputNumber
            min={1}
            placeholder={DEFAULT_ADVANCED_SETTINGS.row_h_threshold.toString()}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
