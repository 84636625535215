import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, message } from 'antd';
import { useState } from 'react';
import type { Category } from '../../index.d';
import { newCateGory } from '../../api';

interface CategoryDropdownRenderProps {
  update: (payload: Category) => void;
}
export default function CategoryDropdownRender(
  props: CategoryDropdownRenderProps
) {
  const { update } = props;

  const [value, setValue] = useState('');

  async function save() {
    if (!value) {
      message.error('请填写分类名称');

      return;
    }

    const res = await newCateGory({ classifyName: value, description: '' });

    if (res && res.errcode === 0) {
      setValue('');
      update({ name: value, id: res.data.id });
    } else {
      message.error('新增失败');
    }
  }
  return (
    <div>
      <Divider className="small" />
      <div className="flex p-2">
        <span className="flex-1">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="请填写分类名称"
          />
        </span>
        <button onClick={save} className="flex items-center text-primary ml-2">
          <PlusOutlined /> 新增模板分类
        </button>
      </div>
    </div>
  );
}
