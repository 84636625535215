import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Iconfont from './Iconfont';

interface ScaleContainerProps {
  width?: number;
  height?: string;
  onScaleChange: (scale: number) => void;
  children: ReactNode;
}
export default function ScaleContainer(props: ScaleContainerProps) {
  const { children, onScaleChange, height } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const grabbingRef = useRef(false);
  // const lastClient = useRef({ x: 0, y: 0 });
  const positionRef = useRef({ left: 0, top: 0 });

  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.transform = `scale(${scale})`;
    }
  }, [scale]);

  function updateTransform(scale: number) {
    setScale(scale);
    onScaleChange(scale);
  }

  function updatePosition() {
    if (wrapperRef.current) {
      wrapperRef.current.style.top = `${positionRef.current.top}px`;
      wrapperRef.current.style.left = `${positionRef.current.left}px`;
    }
  }

  function handleScale(direction: number) {
    updateTransform(scale + 0.1 * direction);
  }

  // function onMouseDown(e: MouseEvent) {
  //   grabbingRef.current = true;
  //   lastClient.current.x = e.clientX;
  //   lastClient.current.y = e.clientY;
  //   if (containerRef.current) {
  //     containerRef.current.style.cursor = 'grabbing';
  //   }
  // }

  // function onMouseMove(e: MouseEvent) {
  //   e.preventDefault();
  //   if (grabbingRef.current) {
  //     const dx = e.clientX - lastClient.current.x;
  //     const dy = e.clientY - lastClient.current.y;

  //     lastClient.current.x = e.clientX;
  //     lastClient.current.y = e.clientY;

  //     positionRef.current.left += dx;
  //     positionRef.current.top += dy;

  //     updatePosition();
  //   }
  // }

  // function onMouseLeave() {
  //   grabbingRef.current = false;
  //   if (containerRef.current) {
  //     containerRef.current.style.cursor = 'default';
  //   }
  // }

  function restore() {
    positionRef.current.left = 0;
    positionRef.current.top = 0;
    updateTransform(1);
    updatePosition();
  }

  useEffect(() => {
    function handleMouseup() {
      grabbingRef.current = false;
    }

    window.addEventListener('mouseup', handleMouseup);

    return () => {
      window.removeEventListener('mouseup', handleMouseup);
    };
  }, []);

  const precent = (scale * 100).toFixed(0);

  return (
    <div className="border border-gray-400 bg-gray-300 flex flex-col justify-between">
      <div ref={containerRef} className="flex-1 overflow-auto">
        <div
          style={{ transformOrigin: 'left top', height }}
          ref={wrapperRef}
          className="relative transform"
        >
          {children}
        </div>
      </div>

      <div className="flex justify-center p-1 bg-gray-100 border-t border-gray-400">
        <div>
          <span className="inline-block border-l border-gray-300 ">
            <button
              className=" hover:bg-gray-200 px-2 py-1"
              onClick={() => handleScale(-1)}
            >
              <MinusOutlined />
            </button>
            <span>{precent}%</span>
            <button
              className=" hover:bg-gray-200 px-2 py-1"
              onClick={() => handleScale(1)}
            >
              <PlusOutlined />
            </button>
          </span>
          <span
            onClick={restore}
            className="cursor-pointer inline-block border-r border-l border-gray-300 px-2 py-1 hover:bg-gray-200"
          >
            原始尺寸
            <Iconfont className="mx-2" type="iconGroup140" />
          </span>
        </div>
      </div>
    </div>
  );
}
