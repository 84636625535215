import type { AreaText } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { getAreaTextRectId, getDataTypeText } from '../../utils';
import { handleRectHover } from './utils';

export default function AreaTextItem(props: {
  item: AreaText;
  onRemove: (key: number) => void;
}) {
  const { item, onRemove } = props;
  function onClick() {
    const rect = document.getElementById(getAreaTextRectId(item._key));
    if (rect) {
      rect.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function toggle(hover: boolean) {
    const target = document.getElementById(getAreaTextRectId(item._key));
    if (target) {
      handleRectHover(target, hover);
    }
  }
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
      className="p-2 mb-4 cursor-pointer group relative border-2 border-dashed border-gray-700 hover:border-primary hover:bg-green-50"
    >
      <div className="absolute right-0  opacity-0 group-hover:opacity-100 text-red-500">
        <Iconfont
          onClick={() => onRemove(item._key)}
          className="px-2 text-base"
          type="icondelete"
        />
      </div>
      <div className="mb-2 text-gray-700">文件以下区域</div>
      <div className="h-6 mb-2 border border-gray-600">
        <img className="w-full h-full object-cover" src={item.dataUrl} alt="" />
      </div>
      <div className="mb-2">文本 {getDataTypeText(item.data_type)}</div>
      <div className="truncate text-primary" title={item.text}>
        {item.text}
      </div>
    </div>
  );
}
