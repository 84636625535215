import { upload } from './api';

export async function handleBlobUpload(blob: Blob, name = 'feature.png') {
  const file = new File([blob], name);

  const response = await upload({ file });

  if (response && response.errcode === 0) {
    return response.data.file_url;
  }

  return '';
}

/**
 * commander:
 *   fileExt
 *   fileHash
 *   fileName
 *   filePath
 *   fileSize
 *   fileType
 *   searchHash
 *   url
 * AI server
 *   file_url
 * @param data
 * @returns
 */
export function getUploadResponseBody(data: any) {
  return {
    url: data.file_url,
  };
}
