interface ScoreRenderProps {
  score: number;
  fade: boolean;
}

export default function ScoreRender(props: ScoreRenderProps) {
  const { score, fade } = props;

  const cls = ['inline-block w-5 text-center text-xs'];

  if (score > 59) {
    let color = 'text-green-500';
    if (fade) {
      color = 'text-green-500/45';
    }
    cls.push(`${color} bg-green-500/15`);
  } else {
    let color = 'text-red-500';
    if (fade) {
      color = 'text-red-500/45';
    }
    cls.push(`${color} bg-red-500/15`);
  }
  return <span className={cls.join(' ')}>{score}</span>;
}
