import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { sso } from '../../api';
import { Pending } from 'components';

export default function SSO() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function handleSSOAuth(secret: string, user_id: string) {
      try {
        const res = await sso({ secret, user_id });
        if (res.errcode === 0) {
          navigate('/');
        }
      } catch (error) {}
    }

    const secret = searchParams.get('secret');
    const user_id = searchParams.get('user_id');
    if (secret === null || user_id === null) {
      return;
    }

    handleSSOAuth(secret, user_id);
  }, []);
  return <Pending pending={true} />;
}
