import {
  AREA_TEXT_ITEM_PREFIX,
  AREA_TEXT_RECT_ID_PREFIX,
  FEATURE_IMAGE_ITEM_PREFIX,
  FEATURE_IMAGE_RECT_ID_PREFIX,
  FILENAME_TEXT_ITEM_PREFIX,
  RECORD_RECT_ID_PREFIX,
  TABLE_CELL_FRAGEMENT_ID_PREFIX,
  TABLE_FEATURE_FRAGEMENT_ID_PREFIX,
  TABLE_FEATURE_RECT_ID_PREFIX,
  TABLE_RECOGNITION_ITEM_PREFIX,
  TEMPLATE_TABLE_ID_PREFIX,
} from "ai-constants";
import { TEXT_EQUAL } from "./pages/template/constants";
import { CustomTemplateData, CustomTemplateItem } from "./index.d";
import { generateKey } from "./pages/template/keyUtils";

export function getFileType(url: string) {
  const pdf = /\.pdf$/i;
  return pdf.test(url) ? 1 : 2;
}

export function isBase64(data: string) {
  const reg = /data:.*base64,/;
  return reg.test(data);
}

export function getTemplateIcon(value: string[] | string) {
  if (typeof value === "string" && value !== "") {
    return value.split(" ");
  }
  if (Array.isArray(value) && value.length === 2) {
    return value;
  }

  return ["iconclock-circle", "#C0F0E6"];
}

export function getDataTypeText(type: number) {
  if (type === TEXT_EQUAL) {
    return "等于";
  }
  return "包含";
}

export function translateToSVG2(point: number[], ratio: number) {
  const [x, y, x1, y1, x2, y2, x3, y3] = point;
  return translateToSVG([x, y, x2, y2], ratio);
}

export function translateToSVG(point: number[], ratio: number) {
  const x = point[0] * ratio;
  const y = point[1] * ratio;
  const x1 = point[2] * ratio;
  const y1 = point[3] * ratio;
  return {
    x,
    y,
    x1,
    y1,
    w: x1 - x,
    h: y1 - y,
  };
}

export function getImageRatio(target: HTMLImageElement) {
  let ratio = target.clientWidth / target.naturalWidth;

  if (!Number.isFinite(ratio)) {
    ratio = 1;
  }
  return ratio;
}

export function getTextAreaTitle(index: number) {
  return `${index + 1}号识别区域`;
}

export function stringify(params: any) {
  try {
    return JSON.stringify(params, null, 2);
  } catch (error) {
    return "";
  }
}

export function getPageIndicator(page: string, total: number) {
  return `${page}/${total}`;
}

export function getAreaTextRectId(key: number) {
  return `${AREA_TEXT_RECT_ID_PREFIX}-${key}`;
}

export function getFeatureImageRectId(key: number) {
  return `${FEATURE_IMAGE_RECT_ID_PREFIX}-${key}`;
}

export function getTableFeatureRectId(key: number) {
  return `${TABLE_FEATURE_RECT_ID_PREFIX}-${key}`;
}

export function getRecordRectId(id: string) {
  return `${RECORD_RECT_ID_PREFIX}-${id}`;
}

export function getTemplateTableId(id: string) {
  return `${TEMPLATE_TABLE_ID_PREFIX}-${id}`;
}

export function getFeatureImageItemId(id: number) {
  return `${FEATURE_IMAGE_ITEM_PREFIX}-${id}`;
}

export function getAreaTextItemId(id: number) {
  return `${AREA_TEXT_ITEM_PREFIX}-${id}`;
}

export function getFilenameTextItemId(id: number) {
  return `${FILENAME_TEXT_ITEM_PREFIX}-${id}`;
}

export function getTableRecognitionItemId(id: number) {
  return `${TABLE_RECOGNITION_ITEM_PREFIX}-${id}`;
}

export function getTableFeatureFragementId(id: number) {
  return `${TABLE_FEATURE_FRAGEMENT_ID_PREFIX}-${id}`;
}

export function getTableCellFragementId(id: number) {
  return `${TABLE_CELL_FRAGEMENT_ID_PREFIX}-${id}`;
}

export function scrollIntoView(id: string) {
  const target = document.getElementById(id);
  if (target) {
    target.scrollIntoView();
  }
}

export function getCustomTemplateListFromResponse(data: any) {
  const getDataList = (feature: any) => {
    if (!feature) return [];
    let keys = Object.keys(feature);
    let list: CustomTemplateData[] = [];
    for (let i = 0, len = keys.length; i < len; i += 1) {
      let key = keys[i] as keyof typeof feature;
      list.push({
        _key: generateKey(),
        param: key,
        ...feature[key],
      });
    }
    return list;
  };
  const getList = () => {
    let keys = Object.keys(data);
    let list: CustomTemplateItem[] = [];
    for (let i = 0, len = keys.length; i < len; i += 1) {
      let key = keys[i] as keyof typeof data;
      const { feature, ...rest } = data[key];
      list.push({
        _key: generateKey(),
        temp_name: keys[i],
        data_list: getDataList(feature),
        ...rest,
      });
    }
    return list;
  };
  return getList();
}
