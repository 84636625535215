import { Menu } from 'antd';
import { enableTemplateTableFeature } from '../../featureFlags';
import {
  PICTURE_FEATURES,
  TEXT_FEATURES,
  FILE_FEATURES,
  TABLE_FEATURES,
} from './constants';

const menus = [
  {
    key: PICTURE_FEATURES,
    text: '图片特征',
  },
  {
    key: TEXT_FEATURES,
    text: '文本特征',
  },
];

if (enableTemplateTableFeature) {
  menus.push({
    key: TABLE_FEATURES,
    text: '表格特征',
  });
}

menus.push({
  key: FILE_FEATURES,
  text: '文件信息特征',
});

export default function TemplateFeaturesOverlay(props: { onClick: any }) {
  const { onClick, ...rest } = props;
  return (
    <Menu {...rest} className="border border-gray-200" onClick={onClick}>
      {menus.map((menu) => (
        <Menu.Item
          className="hover:bg-green-50 hover:text-primary"
          key={menu.key}
        >
          {menu.text}
        </Menu.Item>
      ))}
    </Menu>
  );
}
