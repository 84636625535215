import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import type { Category, TemplateInfo } from '../../index.d';
import { getCategory } from '../../api';
import TemplateIcon from '../../component/TemplateIcon';
import { useManipulatingState } from 'hooks';
import CategoryDropdownRender from './CategoryDropdownRender';

interface TemplateInfoProps {
  next: (values: TemplateInfo) => void;
}
export function TemplateInfoComponent(props: TemplateInfoProps) {
  const { next } = props;
  const [category, setCategory, addCategory] = useManipulatingState<Category>(
    []
  );
  function onFinish(values: any) {
    next(values);
  }

  useEffect(() => {
    async function init() {
      const res = await getCategory();

      if (res && res.errcode === 0) {
        setCategory(res.data.list);
      }
    }

    init();
  }, [setCategory]);

  return (
    <Form
      layout="vertical"
      className="pb-10"
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      initialValues={{ icon: 'iconclock-circle #1EBCB2', template_classify: 1 }}
    >
      <Form.Item
        label="模板名称"
        name="name"
        rules={[
          {
            required: true,
            message: '请输入模板名称!',
          },
        ]}
      >
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="icon" label="模版图标" required>
        <TemplateIcon />
      </Form.Item>
      <Form.Item name="template_classify" label="模板分类">
        <Select
          dropdownRender={(menu) => (
            <div>
              {menu}
              <CategoryDropdownRender update={addCategory} />
            </div>
          )}
        >
          {category.map((c) => (
            <Select.Option key={c.id} value={c.id}>
              {c.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="description" label="模板描述">
        <Input.TextArea
          rows={6}
          placeholder="请输入"
          maxLength={200}
        ></Input.TextArea>
      </Form.Item>
      <Form.Item colon={false}>
        <Button type="primary" htmlType="submit">
          下一步
        </Button>
      </Form.Item>
    </Form>
  );
}
