import { OCRResult, TemplateResult } from '../../index.d';
import EditableTable from '../../component/EditableTable';
import StatusRender from './components/StatusRender';
import ScoreRender from './components/ScoreRender';
import RecordTableCellItemRender from './components/RecordTableCellItemRender';
import { Table } from 'antd';
import { log } from '@bewd/logger';
import { memo } from 'react';

interface RecordTableProps {
  pageIndex: number;
  tableIndex: number;
  dataSource: TemplateResult[];
  handleItemUpdate: (
    pageIndex: number,
    tableIndex: number,
    keyIndex: number,
    item: any
  ) => void;
}
export default memo(RecordTable);

function RecordTable(props: RecordTableProps) {
  const { pageIndex, tableIndex, dataSource, handleItemUpdate } = props;

  const columns = [
    {
      title: '单元格字段名称',
      dataIndex: 'key',
      width: 240,
    },
    {
      title: 'OCR识别结果',
      dataIndex: 'ocr_results',
      render(target: OCRResult[]) {
        return (
          <RecordTableCellItemRender item={target[0]} handleSave={handleSave} />
        );
      },
    },
    {
      title: 'OCR评分',
      dataIndex: 'ocr_results',
      width: 128,
      render(target: OCRResult[]) {
        return <ScoreRender fade={false} score={target[0].ocr_results[1]} />;
      },
    },
  ];

  function handleSave(record: OCRResult) {
    const keyIndex = dataSource.findIndex(
      (item) => item.ocr_results[0].id === record.id
    );
    if (keyIndex !== -1) {
      handleItemUpdate(pageIndex, tableIndex, keyIndex, record);
    }
  }

  return (
    <Table
      size="small"
      className="record-table"
      scroll={{ x: true }}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
    />
  );
}
