import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

interface UploadButtonProps {
  loading: boolean;
}
export default function UploadButton(props: UploadButtonProps) {
  const { loading } = props;
  if (loading) {
    return <LoadingOutlined />;
  }
  return <PlusOutlined className="text-2xl" />;
}

export function UploadText(props: { loading: boolean }) {
  const { loading } = props;
  const text = loading ? '上传中' : '上传图片';
  return <div className="mt-2">{text}</div>;
}
