import { getAuth } from "utils/auth";

let prefix = "/ai";

export function withPrefix(url: string) {
  return `${prefix}${url}`;
}
export async function _fetch(url: string, init?: RequestInit) {
  const res = await fetch(withPrefix(url), init);
  if (res.ok) {
    const body = await res.json();
    if (body.errcode === 4102 || body.errcode === 4201) {
      // window.location.assign('/login');
    }
    return body;
  }
  return null;
}
class HttpRequest {
  auth = getAuth();
  patch(url: string, payload: object, bearerToken?: string) {
    const auth = getAuth();
    const p = { ...payload };
    return _fetch(url, patch(p, auth?.token));
  }

  put(url: string, payload: object, bearerToken?: string) {
    const auth = getAuth();
    const p = { ...payload };
    return _fetch(url, put(p, auth?.token));
  }
  post(url: string, payload: object, bearerToken?: string) {
    const auth = getAuth();
    const p = { ...payload };
    return _fetch(url, post(p, auth?.token));
  }
  get(url: string, payload?: object, bearerToken?: string) {
    const auth = getAuth();
    let u = url;
    if (payload) {
      const p = buildParams({ ...payload });
      if (p) u = `${url}?${p}`;
    }
    return _fetch(u, get(auth?.token));
  }
  delete(url: string, payload: object, bearerToken?: string) {
    const auth = getAuth();
    const p = { ...payload };
    return _fetch(url, deleteRequest(p, auth?.token));
  }
}

const http = new HttpRequest();

export default http;

function buildParams(params?: { [index: string]: any }) {
  if (!params) {
    return "";
  }
  const parts = [];
  for (let key in params) {
    const value = params[key];
    if (value === "" || value === null || typeof value === "undefined") {
      continue;
    }
    parts.push(`${key}=${value}`);
  }

  return parts.join("&");
}

function initHeaders(h: Headers, bearerToken?: string | null) {
  h.append("Content-Type", "application/json");
  h.append("From", "origin-ai");
  if (bearerToken) {
    h.append("Authorization", `Bearer ${bearerToken}`);
  }
}

export function patch(
  payload: object,
  bearerToken?: string | null
): RequestInit {
  const h = new Headers();
  initHeaders(h, bearerToken);
  return {
    mode: "cors",
    credentials: "include",
    method: "PATCH",
    headers: h,
    body: JSON.stringify(payload),
  };
}

export function put(payload: object, bearerToken?: string | null): RequestInit {
  const h = new Headers();
  initHeaders(h, bearerToken);

  return {
    mode: "cors",
    credentials: "include",
    method: "PUT",
    headers: h,
    body: JSON.stringify(payload),
  };
}

export function deleteRequest(
  payload: object,
  bearerToken?: string | null
): RequestInit {
  const h = new Headers();
  initHeaders(h, bearerToken);
  return {
    mode: "cors",
    credentials: "include",
    method: "DELETE",
    headers: h,
    body: JSON.stringify(payload),
  };
}
export function post(
  payload: object,
  bearerToken?: string | null
): RequestInit {
  const h = new Headers();
  initHeaders(h, bearerToken);
  return {
    mode: "cors",
    credentials: "include",
    method: "POST",
    headers: h,
    body: JSON.stringify(payload),
  };
}

export function get(token?: string | null): RequestInit {
  const h = new Headers();
  initHeaders(h, token);
  return {
    method: "GET",
    headers: h,
  };
}

export function isOk(res: any) {
  return res && res.errcode === 0;
}
