import { Box } from '@svgdotjs/svg.js';

export function getPointsArray(bbox: Box) {
  return [
    [bbox.x, bbox.y],
    [bbox.x, bbox.cy],
    [bbox.x, bbox.y2],
    [bbox.cx, bbox.y],
    [bbox.cx, bbox.y2],
    [bbox.x2, bbox.y],
    [bbox.x2, bbox.cy],
    [bbox.x2, bbox.y2],
  ];
}

export const directions = ['lt', 'l', 'lb', 't', 'b', 'rt', 'r', 'rb'];

export const classOfPoints = [
  'points-lt',
  'points-l',
  'points-lb',
  'points-t',
  'points-b',
  'points-rt',
  'points-r',
  'points-rb',
];
