import { useMemo, useState } from 'react';
import { useImageObserver } from 'hooks';
import { getPageIndicator } from '../utils';

interface ImageIndicatorProps {
  list: string[];
  filename: string;
  tableCount?: number;
  showTableCount?: boolean;
  container: any;
}

export function ImageIndicator(props: ImageIndicatorProps) {
  const { list, filename, container, tableCount, showTableCount } = props;
  const [pageIndicate, setPageIndicate] = useState('');

  useImageObserver(
    (entries: IntersectionObserverEntry[]) => {
      for (let i = 0; i < entries.length; i += 1) {
        const item = entries[i];
        if (item.isIntersecting) {
          const page = (item.target as HTMLElement).dataset.page || '0';
          setPageIndicate(getPageIndicator(page, list.length));
        }
      }
    },
    list,
    container
  );

  const text = useMemo(
    () => `总识别出${tableCount}个表格（下方已框出）`,
    [tableCount]
  );

  return (
    <div className="flex whitespace-nowrap h-10 items-center bg-gray-300">
      <span className="flex flex-1">
        <span
          className="mr-2 px-6 truncate text-gray-700"
          style={{ maxWidth: `calc(100vw - 880px)` }}
        >
          {filename}
        </span>
        {showTableCount && <span className="text-gray-600">{text}</span>}
      </span>
      <span className="px-4 text-gray-700">{pageIndicate}</span>
    </div>
  );
}
