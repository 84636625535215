import { memo, SyntheticEvent, useMemo, useState } from 'react';
import { log } from '@bewd/logger';
import { getRecordRectId } from '../../utils';
import { composeResultId } from './utils';
import { OCRBox, TemplateResultInfo } from '../../index.d';

interface RecordImageRendererProps {
  id: number;
  src: string;
  results: TemplateResultInfo[];
}

function RecordImageRenderer(props: RecordImageRendererProps) {
  const { id, src, results } = props;
  const [ratio, setRatio] = useState(1);

  const tables = results;

  function onLoad(e: SyntheticEvent<HTMLImageElement>) {
    const { currentTarget } = e;
    const ratio = currentTarget.clientWidth / currentTarget.naturalWidth;
    setRatio(ratio);
  }

  log('image render');

  const rects = useMemo(() => {
    log('RecordImageRenderer: ratio', ratio);

    let areaBoxes: OCRBox[] = [];

    tables.forEach((table) => {
      log('Handle table: ', table.table_name);

      const groups = table.results;

      groups.forEach((item) => {
        item.ocr_results.forEach((cell) => {
          const box = cell.area_boxes;

          areaBoxes.push({
            id: cell.id,
            x: box[0] * ratio,
            y: box[1] * ratio,
            w: (box[2] - box[0]) * ratio,
            h: (box[3] - box[1]) * ratio,
          });
        });
      });
    });

    return areaBoxes;
  }, [id, tables, ratio]);

  return (
    <div className="relative">
      <img className="w-full" src={src} onLoad={onLoad} alt="xx" />
      <svg className="absolute top-0 h-full w-full"></svg>
      <svg className="absolute top-0 h-full w-full">
        {rects.map((box) => (
          <rect
            key={box.id}
            id={getRecordRectId(box.id)}
            className="fill-current text-transparent"
            x={box.x}
            y={box.y}
            width={box.w}
            height={box.h}
          ></rect>
        ))}
      </svg>
    </div>
  );
}

export default memo(RecordImageRenderer);
