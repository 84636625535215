import { Modal, Input } from 'antd';
import { useState, useEffect } from 'react';

interface AddTableCellModalProps {
  visible: boolean;
  onOk: (value: string) => void;
  onCancel: () => void;
}

export default function AddTableCellModal(props: AddTableCellModalProps) {
  const { visible, onOk, onCancel } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    if (visible) {
      setValue('');
    }
  }, [visible]);

  function confirm() {
    onOk(value);
  }

  return (
    <Modal
      title="添加单元格"
      visible={visible}
      onOk={confirm}
      onCancel={onCancel}
    >
      <Input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder="选填，请输入单元格内容"
      />
    </Modal>
  );
}
