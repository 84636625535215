import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Radio, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRecordList } from '../../api';
import LogoutButton from '../../component/LogoutButton';
import { useRecoilState } from 'recoil';
import templateRecordState from './TemplateRecordState';
import { isOk } from '../../http';
import { recordsOptions, RecordsOptionsValue, recordStatusMap } from './utils';
import { useRequest } from 'ahooks';

export default function Records() {
  const columns = [
    {
      title: '记录ID',
      dataIndex: 'id',
    },
    {
      title: '识别文件',
      dataIndex: 'file_url',
      render(data: string) {
        return (
          <a href={data} target="_blank" rel="noreferrer">
            {data}
          </a>
        );
      },
    },
    {
      title: '调用时间',
      dataIndex: 'create_time',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render(status: number) {
        return recordStatusMap.get(status);
      },
    },
    {
      title: '调用详情',
      render: (record: any) => {
        return (
          <button
            className="text-primary focus:outline-none"
            onClick={() => view(record)}
          >
            详情
          </button>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  function view(record: any) {
    navigate(`/record/${record.id}`);
  }

  const title = query.get('name') + '调用记录';

  if (!id) {
    return null;
  }
  return (
    <div
      style={{ backgroundColor: '#f0f2f5' }}
      className="h-screen overflow-auto"
    >
      <PageHeaderWrapper title={title} extra={<LogoutButton />}>
        <Render id={id} columns={columns} />
      </PageHeaderWrapper>
    </div>
  );
}

interface RenderProps {
  id: string;
  columns: any[];
}
function Render(props: RenderProps) {
  const { id, columns } = props;

  const [status, setStatus] = useState(RecordsOptionsValue.All);

  const request = useRequest(
    () => getRecordList({ templateSign: id, status }),
    { refreshDeps: [id, status] }
  );

  return (
    <div className="bg-white p-6">
      <div className="mb-4 flex justify-end">
        <Radio.Group
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          optionType="button"
          options={recordsOptions}
        />
      </div>
      <Table
        rowKey="id"
        loading={request.loading}
        dataSource={request.data?.data.records || []}
        columns={columns}
      ></Table>
    </div>
  );
}
