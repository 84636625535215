import { Modal, message, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { CustomTemplateItem as CustomTemplateItemType } from "../../index.d";
import Iconfont from "../../component/Iconfont";
import { getTemplateIcon } from "../../utils";
import ModifyTemplateButton from "./ModifyTemplateButton";
import {
  deleteCustomTemplate,
  deleteTemplate,
  disableTemplate,
} from "../../api";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { isOk } from "../../http";
import { Permission } from "../../permission";
import CustomTemplateItemMoreAction from "./CustomTemplateItemMoreAction";

interface CustomTemplateItemProps {
  item: CustomTemplateItemType;
  onTemplateDelete: (item: CustomTemplateItemType) => void;
  onTemplateModify: (item: CustomTemplateItemType) => void;
  // toggleTemplateActive: (item: CustomTemplateItemType) => void;
  onTemplateTest: (item: CustomTemplateItemType) => void;
}
export default function CustomTemplateItem(props: CustomTemplateItemProps) {
  const { item, onTemplateDelete, onTemplateModify, onTemplateTest } = props;

  const navigate = useNavigate();

  /*============================== Request ==============================*/

  /*============================== Handler ==============================*/

  function deleteTempalte(item: CustomTemplateItemType) {
    Modal.confirm({
      title: `确定要删除模板 ${item.temp_name} 吗?`,
      icon: <CloseCircleOutlined />,
      content: "删除后该模板信息将无法恢复",
      cancelText: "取消",
      okText: "确定",
      okType: "danger",
      onOk: async () => {
        const res = await deleteCustomTemplate({ temp_name: item.temp_name });
        if (res && res.errcode === 0) {
          message.success("删除成功");
          onTemplateDelete(item);
        } else {
          message.error(res && res.errmsg);
        }
      },
    });
  }

  // async function toggleTemplateActive() {
  //   const res = await disableTemplate({
  //     templateSign: item.templateSign,
  //     isDeactivate: item.isDeactivate === 0 ? 1 : 0,
  //   });
  //   if (isOk(res)) {
  //     message.success(item.isDeactivate === 0 ? '禁用成功' : '启用成功');
  //     props.toggleTemplateActive(item);
  //   }
  // }

  // function toRecord() {
  //   navigate(`/records/${item.templateSign}?name=${item.name}`);
  // }

  function openModal() {
    onTemplateTest(item);
  }

  function toEdit() {
    navigate(`/template/custom/${item._key}/edit?name=${item.temp_name}`);
  }

  function handleDropdown(e: any) {
    switch (e.key) {
      case "edit":
        onTemplateModify(item);
        break;
      case "disabled":
        // toggleTemplateActive();
        break;
      case "delete":
        deleteTempalte(item);
        break;

      default:
        break;
    }
  }
  // @ts-ignore
  const [iconType, backgroundColor] = getTemplateIcon(item.icon);

  // @ts-ignore
  const active = item.isDeactivate === 0 ? "禁用" : "启用";

  return (
    <div className="card flex flex-col bg-white mb-4 mr-4 rounded-sm border border-gray-300">
      <div className="flex-1 p-6 content flex">
        <div className="h-12 rounded p-1 mr-4" style={{ backgroundColor }}>
          <Iconfont type={iconType} />
        </div>
        <div>
          <div className="flex">
            <div className="w-44 truncate text-base text-gray-800 mb-2 font-medium">
              {item.temp_name}
            </div>
            {/* {item.isDeactivate === 1 && (
              <div>
                <span className="text-xs border border-red-500 text-red-400 px-2 py-1">
                  已禁用
                </span>
              </div>
            )} */}
          </div>
          <div className="text-gray-600 overflow-hidden h-16">
            {/* {item.description} */}
          </div>
        </div>
      </div>
      <div className="flex bg-gray-100 h-10 text-center">
        {/* <div className="flex-1 leading-10 border-r border-gray-300">
          <Tooltip title="调用记录">
            <Iconfont type="iconlist" onClick={toRecord} />
          </Tooltip>
        </div> */}
        <div className="flex-1 leading-10 border-r border-gray-300">
          <Tooltip title="调用模板">
            <Iconfont type="iconlink" onClick={openModal} />
          </Tooltip>
        </div>
        <div className="flex-1 leading-10 border-r border-gray-300">
          <ModifyTemplateButton
            mode={Permission.MANAGE}
            fallback={<Iconfont type="iconedit" />}
            onClick={() => onTemplateModify(item)}
          />
        </div>
        <div className="flex-1 leading-10 border-r border-gray-300">
          <Tooltip title="删除模板">
            <DeleteOutlined onClick={() => deleteTempalte(item)} />
          </Tooltip>
          {/* <CustomTemplateItemMoreAction
            mode={Permission.MANAGE}
            fallback={<Iconfont type="iconellipsis" />}
            active={active}
            handleDropdown={handleDropdown}
          /> */}
        </div>
      </div>
    </div>
  );
}
