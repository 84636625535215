import { PageContainer } from '@ant-design/pro-layout';
import { Button, message, Modal, Spin, Tabs } from 'antd';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RecordType, TemplateRecord, TemplateResult } from '../../index.d';
import { records, updateData } from '../../api';
import RecordImageRenderer from './RecordImageRenderer';
import { composeResultId, makeupRecordItem } from './utils';
import ScaleContainer from '../../component/ScaleContainer';
import { isOk } from '../../http';
import { OCRResultMode, OCRResultStatus } from 'ai-constants';
import CustomFieldRender from './components/CustomFieldRender';
import RecordTable from './RecordTable';
import { ulid } from 'ulid';
import produce from 'immer';
import RecordTableColumnItemRender from './components/RecordTableColumnItemRender';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { CellInTable, ColumnInTable } from './interface';
import AddTableCellModal from './AddTableCellModal';
import { stringify } from '../../utils';

enum TabKey {
  Table = '1',
  List = '2',
}

interface RecordProps {}
export default function Record(props: RecordProps) {
  const navigate = useNavigate();
  const { id } = useParams();

  /*============================== Ref ==============================*/

  const formattedResponse = useRef('');

  const sortByTitleRef = useRef(-1);

  const sortByScoreRef = useRef(-1);

  const originalSource = useRef<TemplateRecord[]>([]);

  const cellItemInsertInfo = useRef({
    current: -1,
    insert: -1,
    tableIndex: -1,
    columnIndex: -1,
  });

  /*============================== State ==============================*/
  const [spinning, setSpinning] = useState(false);

  // 1: 代办， 2: 已办
  const [submitted, setSubmitted] = useState(false);

  const [customFields, setCustomFields] = useState<TemplateResult[]>([]);

  const [cellInTables, setCellInTables] = useState<CellInTable[]>([]);

  const [columnsInTables, setColumnInTables] = useState<ColumnInTable[]>([]);

  const [templateRecord, setTemplateRecord] = useState<TemplateRecord[]>([]);

  const [height, setHeight] = useState(600);

  const [recordRecognitionItems, setRecordRecognitionItems] = useState<
    RecordType[]
  >([]);

  const [collapsed, setCollapsed] = useState(false);

  const [cellModalVisible, setCellModalVisible] = useState(false);

  const [templateName, setTemplateName] = useState('');

  /*============================== Effect ==============================*/

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const height = window.innerHeight - 280;
      setHeight(height);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    async function init(id: string) {
      setSpinning(true);
      const res = await records(id);
      if (isOk(res)) {
        const { records, templateName, status } = res.data;

        formattedResponse.current = stringify(res);
        // area box

        // 数据结构
        // 页 => 多个表格
        // 表格对应多种格式
        // 按表格
        // 按列
        // 自定义字段
        const result = makeupRecordItem(records, handleTableColumnItemUpdate);
        setCustomFields(result.customFiled);
        setCellInTables(result.cellTable);
        setRecordRecognitionItems(result.itemList);
        setColumnInTables(result.columnTable);
        originalSource.current = JSON.parse(JSON.stringify(result.records));
        setTemplateRecord(result.records);
        setTemplateName(templateName || '');
        setSubmitted(status === 2);
        // setRecordRecognitionItems(items);
      }
      setSpinning(false);
    }

    if (id) {
      init(id);
    }
  }, [id]);

  /*============================== Hanlder ==============================*/

  function beforeGoBack() {
    if (submitted) {
      previous();
      return;
    }
    Modal.confirm({
      title: '确认放弃当前编辑内容吗？',
      icon: <ExclamationCircleOutlined />,
      content: '返回后，已校验编辑过的内容将不会保存。',
      onOk: previous,
    });
  }

  function handleCellAdd(value: string) {
    // TODO:
    handleTableColumnItemAdd(value);
    closeCellModal();
  }

  function closeCellModal() {
    setCellModalVisible(false);
  }

  function previous() {
    navigate(-1);
  }

  // save action
  async function onSave() {
    setSpinning(true);
    try {
      if (!id) {
        return;
      }
      const newTempalteRecord = {
        id,
        flag: 1,
        results: originalSource.current,
      };
      const res = await updateData(newTempalteRecord);
      if (res && res.errcode === 0) {
        message.success('保存成功');
      } else {
        message.error(res.errmsg);
      }
    } catch (error) {}

    setSpinning(false);
  }

  async function onSubmit() {
    setSpinning(true);
    try {
      if (!id) {
        return;
      }
      const newTempalteRecord = {
        id,
        results: originalSource.current,
      };
      const res = await updateData(newTempalteRecord);
      if (res && res.errcode === 0) {
        message.success('保存成功');
        navigate(-1);
      } else {
        message.error(res.errmsg);
      }
    } catch (error) {}

    setSpinning(false);
  }

  function updateResultStatus(
    pageIndex: number,
    areaIndex: number,
    boxIndex: number,
    status: boolean
  ) {
    const id = composeResultId(pageIndex, areaIndex, boxIndex);
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    const target = newRecordRecognitionItems.find((i) => i.id === id);
    if (target) {
      target.removed = status;
      setRecordRecognitionItems(newRecordRecognitionItems);
    }
  }

  function updateResult(item: RecordType, value: string) {
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    const target = newRecordRecognitionItems.find((i) => i.id === item.id);
    if (target) {
      target.result = value;
      target.changed = true;
      const table =
        originalSource.current[item.pageIndex].results[item.tableIndex];
      const column = table.results[item.keyIndex];
      const targetIndex = column.ocr_results.findIndex(
        (i) => i.id === target.id
      );
      if (targetIndex !== -1) {
        column.ocr_results[targetIndex].ocr_results[0] = value;
      }
      if (column.mode === OCRResultMode.OCRResultCellMode) {
        setCellInTables(
          produce((cellInTables) => {
            const cellInTable = cellInTables.find(
              (i) => i.tableIndex === item.tableIndex
            );
            if (cellInTable) {
              cellInTable.dataSource[
                item.keyIndex
              ].ocr_results[0].ocr_results[0] = value;
            }
          })
        );
      } else if (column.mode === OCRResultMode.OCRResultColumnMode) {
        setColumnInTables(
          produce((columnsInTables) => {
            const columnTable = columnsInTables.find(
              (i) =>
                i.tableIndex === item.tableIndex &&
                i.pageIndex === item.pageIndex
            );
            if (columnTable) {
              const columnItem = columnTable.dataSource[
                item.keyIndex
              ].ocr_results.find((i) => i.id === item.id);
              if (columnItem) {
                columnItem.ocr_results[0] = value;
              }
            }
          })
        );
      }
      setRecordRecognitionItems(newRecordRecognitionItems);
    }
    // udpate ocr result
  }

  function handleTableCellItemUpdate(
    pageIndex: number,
    tableIndex: number,
    keyIndex: number,
    item: any
  ) {
    originalSource.current[pageIndex].results[tableIndex].results[
      keyIndex
    ].ocr_results[0].ocr_results[0] = item.ocr_results[0];
    originalSource.current[pageIndex].results[tableIndex].results[
      keyIndex
    ].ocr_results[0].status = OCRResultStatus.OCRResultEdited;

    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    const target = newRecordRecognitionItems.find((i) => i.id === item.id);
    if (target) {
      if (!target.changed) {
        target.changed = target.result !== item.ocr_results[0];
      }
      target.result = item.ocr_results[0];
    }

    const newCellInTables = cellInTables.map((i) => ({ ...i }));
    const table = newCellInTables.find(
      (i) => pageIndex === i.pageIndex && tableIndex === i.tableIndex
    );
    if (table) {
      const newDataSource = table.dataSource.map((i) => ({ ...i }));

      if (
        newDataSource[keyIndex].ocr_results[0].status ===
        OCRResultStatus.OCRResultUnEdited
      ) {
        newDataSource[keyIndex].ocr_results[0].status =
          newDataSource[keyIndex].ocr_results[0].ocr_results[0] ===
          item.ocr_results[0]
            ? OCRResultStatus.OCRResultUnEdited
            : OCRResultStatus.OCRResultEdited;
      }
      newDataSource[keyIndex].ocr_results[0].ocr_results[0] =
        item.ocr_results[0];
      setCellInTables(newCellInTables);
    }
    setRecordRecognitionItems(newRecordRecognitionItems);
  }

  function handleTableColumnItemUpdate(
    table: ColumnInTable,
    columnId: string,
    item: any,
    value: string
  ) {
    const targetTable =
      originalSource.current[table.pageIndex].results[table.tableIndex];
    const column = targetTable.results.find((i) => i.id === columnId);

    const originalTarget = column?.ocr_results.find((i) => i.id === item.id);
    if (originalTarget) {
      originalTarget.status = OCRResultStatus.OCRResultEdited;
      originalTarget.ocr_results[0] = value;
    }

    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    const target = newRecordRecognitionItems.find((i) => i.id === item.id);
    if (target) {
      if (!target.changed) {
        target.changed = target.result !== value;
      }
      target.result = value;
    }

    setColumnInTables(
      produce((columnsInTables) => {
        const targetTable = columnsInTables.find((i) => i.id === table.id);
        const targetColumn = targetTable?.dataSource.find(
          (i) => i.id === columnId
        );
        const target = targetColumn?.ocr_results.find((i) => i.id === item.id);
        if (target) {
          if (target.status === OCRResultStatus.OCRResultUnEdited) {
            target.status =
              target.ocr_results[0] === value
                ? OCRResultStatus.OCRResultUnEdited
                : OCRResultStatus.OCRResultEdited;
          }
          target.ocr_results[0] = value;
        }
      })
    );
    setRecordRecognitionItems(newRecordRecognitionItems);
  }

  function handleTableColumnItemAdd(value: string) {
    const { tableIndex, columnIndex, current, insert } =
      cellItemInsertInfo.current;
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    setColumnInTables(
      produce((columnsInTables) => {
        const table = columnsInTables[tableIndex];
        const targetColumn = table.dataSource[columnIndex];
        if (targetColumn) {
          const target = targetColumn.ocr_results[current];
          const recordRecognitionItemIndex =
            newRecordRecognitionItems.findIndex((i) => i.id === target.id);
          const payload = {
            status: OCRResultStatus.OCRResultNew,
            id: ulid(),
            ocr_results: [value, 99] as [string, number],
            area_boxes: [],
          };
          const recordRecognitionItem: RecordType = {
            pageIndex: table.pageIndex,
            tableIndex: table.tableIndex,
            keyIndex: columnIndex,
            itemIndex: insert,
            composeId: `${table.pageIndex}-${table.tableIndex}-${columnIndex}-${insert}`,
            id: payload.id,
            result: payload.ocr_results[0],
            score: payload.ocr_results[1],
          };
          if (recordRecognitionItemIndex !== -1) {
            originalSource.current[table.pageIndex].results[
              table.tableIndex
            ].results[columnIndex].ocr_results.splice(
              insert,
              0,
              JSON.parse(JSON.stringify(payload))
            );
            newRecordRecognitionItems.splice(insert, 0, recordRecognitionItem);
            setRecordRecognitionItems(newRecordRecognitionItems);
            targetColumn.ocr_results.splice(
              insert,
              0,
              JSON.parse(JSON.stringify(payload))
            );
          }
        }
      })
    );
  }

  function handTableColumnItemAppend(
    tableIndex: number,
    columnIndex: number,
    spot: number
  ) {
    cellItemInsertInfo.current.current = spot;
    cellItemInsertInfo.current.insert = spot + 1;
    cellItemInsertInfo.current.tableIndex = tableIndex;
    cellItemInsertInfo.current.columnIndex = columnIndex;

    setCellModalVisible(true);
  }

  function handleTableColumnInsertBefore(
    tableIndex: number,
    columnIndex: number,
    spot: number
  ) {
    cellItemInsertInfo.current.current = spot;
    cellItemInsertInfo.current.insert = spot;
    cellItemInsertInfo.current.tableIndex = tableIndex;
    cellItemInsertInfo.current.columnIndex = columnIndex;

    setCellModalVisible(true);
  }

  function handleTableColumnRestore(
    pageIndex: number,
    tableIndex: number,
    item: any
  ) {
    const newRecords = templateRecord.map((i) => ({ ...i }));
    const newResults = newRecords[pageIndex].results[tableIndex].results.map(
      (r) => ({ ...r })
    );
    newResults.forEach((r) => {
      if (r.mode !== OCRResultMode.OCRResultCustomMode) {
        r.ocr_results[item.index].removed = false;
      }
    });

    newRecords[pageIndex].results[tableIndex].results = newResults;
  }

  function handleTableColumnRemove(
    tableIndex: number,
    columnIndex: number,
    spot: number
  ) {
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));

    setColumnInTables(
      produce((columnsInTables) => {
        const table = columnsInTables[tableIndex];
        const targetColumn = table.dataSource[columnIndex];
        if (targetColumn) {
          const target = targetColumn.ocr_results[spot];
          const recordRecognitionItemIndex =
            newRecordRecognitionItems.findIndex((i) => i.id === target.id);
          newRecordRecognitionItems.splice(recordRecognitionItemIndex, 1);
          targetColumn.ocr_results.splice(spot, 1);
          originalSource.current[table.pageIndex].results[
            table.tableIndex
          ].results[columnIndex].ocr_results.splice(spot, 1);
          setRecordRecognitionItems(newRecordRecognitionItems);
        }
      })
    );
  }

  function handleTableRowRemove(tableIndex: number, spot: number) {
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));

    setColumnInTables(
      produce((columnsInTables) => {
        const table = columnsInTables[tableIndex];
        for (let i = 0; i < table.dataSource.length; i += 1) {
          const column = table.dataSource[i];
          if (spot < column.ocr_results.length) {
            const target = column.ocr_results[spot];
            const index = newRecordRecognitionItems.findIndex(
              (i) => i.id === target.id
            );

            column.ocr_results.splice(spot, 1);
            newRecordRecognitionItems.splice(index, 1);
            originalSource.current[table.pageIndex].results[
              table.tableIndex
            ].results[i].ocr_results.splice(spot, 1);
          }
        }
        setRecordRecognitionItems(newRecordRecognitionItems);
      })
    );
  }

  function handleRecordRecognitionItemSortByScore() {
    handleRecordRecognitionItemSort(
      (a, b) => (a.score - b.score) * sortByScoreRef.current
    );
    sortByScoreRef.current = -sortByScoreRef.current;
  }

  function handleRecordRecognitionItemSortByTitle() {
    function compare(a: RecordType, b: RecordType) {
      if (a.pageIndex > b.pageIndex) {
        return 1;
      } else if (a.pageIndex === b.pageIndex) {
        if (a.tableIndex > b.tableIndex) {
          return 1;
        } else if (a.tableIndex === b.tableIndex) {
          if (a.keyIndex > b.keyIndex) {
            return 1;
          } else if (a.keyIndex === b.keyIndex) {
            return a.itemIndex - b.itemIndex;
          }
        }
      }
      return -1;
    }

    handleRecordRecognitionItemSort(
      (a, b) => compare(a, b) * sortByTitleRef.current
    );

    sortByTitleRef.current = -sortByTitleRef.current;
  }

  function handleRecordRecognitionItemSort(
    compare: (a: RecordType, b: RecordType) => number
  ) {
    const newRecordRecognitionItems = recordRecognitionItems.map((i) => ({
      ...i,
    }));
    newRecordRecognitionItems.sort(compare);
    setRecordRecognitionItems(newRecordRecognitionItems);
  }

  return (
    <PageContainer
      style={{ backgroundColor: '#F0F2F5', minHeight: '100vh' }}
      title={templateName + '识别结果'}
    >
      <Spin spinning={spinning}>
        <div className="flex overflow-hidden mt-6 p-6 bg-white relative">
          <div className="relative">
            <div
              className="absolute top-1/2 -right-4 cursor-pointer"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
            </div>
            <div
              style={{ width: collapsed ? 0 : 1000 }}
              className="transition-all duration-700 overflow-hidden"
            >
              <div
                style={{
                  width: 1000,
                  transform: `translateX(${collapsed ? '-100%' : 0})`,
                }}
                className="mx-auto overflow-auto record-container transition-all duration-700"
              >
                <ScaleContainer
                  height="calc(100vh - 192px)"
                  onScaleChange={() => 1}
                >
                  <ul>
                    {templateRecord.map((i, index) => (
                      <li className="relative" key={i.image_url}>
                        <RecordImageRenderer
                          id={i.id}
                          results={i.results}
                          src={i.image_url}
                        />
                      </li>
                    ))}
                  </ul>
                </ScaleContainer>
              </div>
            </div>
          </div>
          <div className="overflow-hidden pl-4 flex-1">
            <Tabs defaultActiveKey={TabKey.Table}>
              <Tabs.TabPane tab="可视化表格" key={TabKey.Table}>
                <div style={{ height: height + 19 }} className="overflow-auto">
                  <ul>
                    {cellInTables.map((table, index) => (
                      <li key={table.id} className="mb-4">
                        <p className="mb-2 text-gray-700">
                          {index + 1}. 表格（按单元格识别）
                        </p>
                        <p className="mb-2">{table.tableName}</p>
                        <RecordTable
                          key={table.id}
                          pageIndex={table.pageIndex}
                          tableIndex={table.tableIndex}
                          dataSource={table.dataSource}
                          handleItemUpdate={handleTableCellItemUpdate}
                        />
                      </li>
                    ))}
                  </ul>

                  <ul>
                    {columnsInTables.map((table, tableIndex) => (
                      <li key={table.id} className="mb-4">
                        <p className="mb-2 text-gray-700">
                          {tableIndex + 1 + cellInTables.length}.
                          表格（按表格列识别）
                        </p>
                        <p className="mb-2">{table.tableName}</p>
                        <ul className="flex overflow-x-auto">
                          {table.dataSource.map((group, columnIndex) => (
                            <li
                              style={{
                                width: `${table.dataSource.length / 100}%`,
                                minWidth: 120,
                              }}
                              key={columnIndex}
                              className="border-gray-300 border-r first:border-l flex-1"
                            >
                              <div className="p-1 border-t border-b border-gray-300 mb-0 bg-gray-100">
                                <p className="w-max mb-0">{group.key}</p>
                              </div>
                              <ul className="border-gray-300">
                                {group.ocr_results.map((item, spot) => (
                                  <li
                                    key={item.id}
                                    className="border-b border-gray-300 w-full hover:bg-gray-200"
                                  >
                                    <RecordTableColumnItemRender
                                      item={item}
                                      handleSave={(value) => {
                                        handleTableColumnItemUpdate(
                                          table,
                                          group.id,
                                          item,
                                          value
                                        );
                                      }}
                                      handleRowRemove={() =>
                                        handleTableRowRemove(tableIndex, spot)
                                      }
                                      handleItemRemove={(item) =>
                                        handleTableColumnRemove(
                                          tableIndex,
                                          columnIndex,
                                          spot
                                        )
                                      }
                                      handleItemInsertBefore={(item) =>
                                        handleTableColumnInsertBefore(
                                          tableIndex,
                                          columnIndex,
                                          spot
                                        )
                                      }
                                      handleItemAppend={(item) =>
                                        handTableColumnItemAppend(
                                          tableIndex,
                                          columnIndex,
                                          spot
                                        )
                                      }
                                    />
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                  {customFields.length !== 0 && (
                    <p className="mb-2 text-gray-700">自定义输出字段</p>
                  )}
                  <ul>
                    {customFields.map((item) => (
                      <CustomFieldRender item={item} key={item.id} />
                    ))}
                  </ul>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="JSON结果" key={TabKey.List}>
                <div className="border px-2">
                  <div>
                    <a
                      href="/doc/CUSTOM_OCR_API.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      查看接口文档
                      <RightOutlined />
                    </a>
                  </div>
                  <pre
                    className="overflow-auto mb-0"
                    style={{ height: height - 5 }}
                  >
                    {formattedResponse.current}
                  </pre>
                </div>
              </Tabs.TabPane>
            </Tabs>
            <div className="flex items-center mt-4">
              <span>
                {!submitted && (
                  <Button type="primary" onClick={onSubmit} className="mr-2">
                    提交
                  </Button>
                )}
                <Button onClick={beforeGoBack}>返回</Button>
              </span>
            </div>
          </div>
        </div>
      </Spin>
      <AddTableCellModal
        visible={cellModalVisible}
        onOk={handleCellAdd}
        onCancel={closeCellModal}
      />
    </PageContainer>
  );
}
