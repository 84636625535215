import { Button, Modal } from 'antd';

interface GeneralRecordModalProps {
  visible: boolean;
  onOk: () => void;
  data: any;
}

export default function GeneralRecordModal(props: GeneralRecordModalProps) {
  const { visible, data, onOk } = props;

  const { src, title, result } = data;

  return (
    <Modal
      title="使用详情"
      width={500}
      visible={visible}
      onCancel={onOk}
      footer={<Button onClick={onOk}>关闭</Button>}
    >
      <div className="mb-2">识别文件：</div>
      <div className="border hover:border-green-500 rounded p-2 mb-4">
        <img
          onClick={() => window.open(src)}
          className="w-12 h-12 cursor-pointer"
          src={src}
          alt=""
        />
      </div>
      <div className="mb-2">识别结果：</div>
      <div className="border hover:border-green-500 overflow-y-auto p-2 h-64">
        <pre className="mb-0">{result}</pre>
      </div>
    </Modal>
  );
}
