import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Tooltip } from 'antd';
import {
  AreaText,
  FeatureImage,
  FilenameText,
  TableFeature,
} from '../../index.d';
import FilenameItem from './FilenameItem';
import AreaTextItem from './AreaTextItem';
import FeatureImageItem from './FeatureImageItem';
import {
  getAreaTextItemId,
  getFeatureImageItemId,
  getFilenameTextItemId,
} from '../../utils';
import TemplateFeaturesOverlay from './TemplateFeaturesOverlay';
import TableFeatureItem from './TableFeatureItem';

interface TemplateFeaturesProps {
  areaTextList: AreaText[];
  featureImageList: FeatureImage[];
  filenameTextList: FilenameText[];
  tableFeatureList: TableFeature[];
  onFeatureImageRemove: (key: number) => void;
  onAreaTextRemove: (key: number) => void;
  onFilenameTextRemove: (key: number) => void;
  onTableFeatureRemove: (key: number) => void;
  updateCaptureType: (type: string) => void;
}
export default function TemplateFeatures(props: TemplateFeaturesProps) {
  const {
    areaTextList,
    featureImageList,
    filenameTextList,
    tableFeatureList,
    updateCaptureType,
    onFeatureImageRemove,
    onAreaTextRemove,
    onFilenameTextRemove,
    onTableFeatureRemove,
  } = props;

  function handleMenuClick(e: any) {
    updateCaptureType(e.key);
  }
  return (
    <div style={{ minWidth: '360px' }} className="ml-4">
      <div className="bg-white p-4">
        <p className="font-medium">
          模板特征（选填）
          <Tooltip title="调用多模板识别时，将文件与模板特征进行匹配，从多份自定义模板中选择匹配成功的模板来识别文件内容。它们是 AND 关系">
            <span className="text-gray-600">
              <InfoCircleOutlined className="mx-1" />
            </span>
          </Tooltip>
        </p>
        <Divider className="normal" />
        <div className="border-2 border-dashed border-gray-700">
          <Dropdown
            overlay={<TemplateFeaturesOverlay onClick={handleMenuClick} />}
          >
            <div
              className="text-gray-700 text-center"
              style={{ lineHeight: '36px' }}
            >
              <PlusOutlined className="text-base" />
              &nbsp; 添加特征
            </div>
          </Dropdown>
        </div>
      </div>
      <div
        className="overflow-y-auto px-4 bg-white"
        style={{ maxHeight: `calc(100vh - 358px)` }}
      >
        <ul>
          {featureImageList.map((f) => (
            <li
              className="mb-4 "
              id={getFeatureImageItemId(f._key)}
              key={f._key}
            >
              <FeatureImageItem onRemove={onFeatureImageRemove} item={f} />
            </li>
          ))}
        </ul>
        <ul>
          {areaTextList.map((a) => (
            <li key={a._key} id={getAreaTextItemId(a._key)}>
              <AreaTextItem onRemove={onAreaTextRemove} item={a} />
            </li>
          ))}
        </ul>
        <ul>
          {tableFeatureList.map((t) => (
            <li key={t._key}>
              <TableFeatureItem item={t} onRemove={onTableFeatureRemove} />
            </li>
          ))}
        </ul>
        <ul>
          {filenameTextList.map((f) => (
            <li key={f._key} id={getFilenameTextItemId(f._key)}>
              <FilenameItem item={f} onRemove={onFilenameTextRemove} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
