import { useNavigate } from 'react-router';
import { logout } from '../api';
import { setAuth } from 'utils/auth';
import { enableLogoutFeature } from '../featureFlags';

export default function LogoutButton() {
  const navigate = useNavigate();

  async function handleLogout() {
    await logout();
    localStorage.removeItem('token');
    setAuth({ token: '', user_id: '0' });
    navigate('/login');
  }

  if (enableLogoutFeature) {
    return (
      <button className="hover:text-primary" onClick={handleLogout}>
        退出登录
      </button>
    );
  }

  return null;
}
