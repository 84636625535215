import { Form, Input, Modal, Select } from 'antd';
import { TEXT_CONTAIN, TEXT_EQUAL } from './constants';

interface FileInfoFeatureModalProps {
  visible: boolean;
  onCancle: () => void;
  onFinish: (e: any) => void;
}
export default function FileInfoFeatureModal(props: FileInfoFeatureModalProps) {
  const { visible, onCancle, onFinish } = props;

  const [form] = Form.useForm();
  return (
    <Modal
      width="560px"
      visible={visible}
      title="添加文件信息特征"
      onOk={form.submit}
      onCancel={onCancle}
    >
      <Form
        layout="inline"
        form={form}
        initialValues={{ data_type: TEXT_CONTAIN }}
        onFinish={onFinish}
      >
        <Form.Item style={{ marginRight: 8 }} className="w-32">
          <Select defaultValue="name">
            <Select.Option value="name">文件名称</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ marginRight: 8 }} name="data_type" className="w-28">
          <Select>
            <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
            <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="text"
          rules={[{ required: true, message: '请输入文件名称' }]}
          style={{ flex: 1, marginRight: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
