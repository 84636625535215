import { useState, useMemo } from 'react';
export default function useManipulatingState(initialState) {
    const [state, setState] = useState(initialState);
    const remove = useMemo(() => {
        function remove(predicate) {
            const newState = state.filter(predicate);
            setState(newState);
        }
        return remove;
    }, [state]);
    const add = useMemo(() => {
        function add(item) {
            const newState = state.map((s) => (Object.assign({}, s)));
            newState.push(item);
            setState(newState);
        }
        return add;
    }, [state]);
    return [state, setState, add, remove];
}
