import type { Rect, TableCellRect } from '../../index.d';
import { translateToSVG } from '../../utils';
import {
  FOOT_LOCATION,
  HEAD_LOCATION,
  TABLE_CELL,
  TABLE_COLUMN,
} from './constants';
import { generateKey } from './keyUtils';

export function getTableCellRecognitionTitle(mode: number) {
  switch (mode) {
    case TABLE_COLUMN:
      return '表格列识别';
    case TABLE_CELL:
      return '表格单元格识别';
    default:
      return 'unknow_mode';
  }
}

export function getRectFromBoxes(boxes: Rect[]) {
  if (boxes.length === 0) {
    return boxes[0];
  }
  const newBoxes = boxes.sort((a, b) => a.y - b.y);
  const start = newBoxes[0];
  const end = newBoxes[newBoxes.length - 1];
  const rect = {
    x: start.x,
    y: start.y,
    x1: end.x1,
    y1: end.y1,
    w: end.x1 - start.x,
    h: end.y1 - start.y,
  };

  return rect;
}

function getCenter(a: number, b: number) {
  return (a + b) / 2;
}

export function getBoxes(
  point: { x: number; y: number },
  boxes: TableCellRect[],
  ratio: number
) {
  // TODO:
  const targetBox = getBoxFromPoint(point, boxes);
  if (!targetBox) {
    return false;
  }

  const cx = getCenter(targetBox.x, targetBox.x1);
  const baseY = targetBox.y - 1;

  const rects = [];

  for (let i = 0; i < boxes.length; i += 1) {
    const item = boxes[i];

    const itemCX = getCenter(item.x, item.x1);

    const alpha = cx - itemCX;

    if (Math.abs(alpha) < 2 && item.y > baseY) {
      rects.push(item);
    }
  }

  return rects;
}

export function getBoxFromPoint(
  point: { x: number; y: number },
  boxes: TableCellRect[]
) {
  for (let i = 0; i < boxes.length; i += 1) {
    const box = boxes[i];
    if (
      box.x < point.x &&
      box.x1 > point.x &&
      box.y < point.y &&
      box.y1 > point.y
    ) {
      return box;
    }
  }

  return false;
}

export function getGroupViaBox(box: TableCellRect, boxes: TableCellRect[]) {
  const cx = getCenter(box.x, box.x1);

  const rects = [];

  for (let i = 0; i < boxes.length; i += 1) {
    const item = boxes[i];

    const itemCX = getCenter(item.x, item.x1);

    const alpha = cx - itemCX;

    if (Math.abs(alpha) < 2) {
      rects.push(item);
    }
  }

  return rects;
}

export function getRectViaBoxes(
  box: TableCellRect,
  boxes: TableCellRect[]
): Rect | false {
  const baseY = box.y - 1;

  const rects = [];

  for (let i = 0; i < boxes.length; i += 1) {
    const item = boxes[i];
    if (item.y > baseY) {
      rects.push(item);
    }
  }

  if (rects.length === 0) {
    return false;
  }

  if (rects.length === 1) {
    return rects[0];
  }

  rects.sort((a, b) => a.y - b.y);

  const start = rects[0];
  const end = rects[rects.length - 1];

  const rect = {
    x: start.x,
    y: start.y,
    x1: end.x1,
    y1: end.y1,
    w: end.x1 - start.x,
    h: end.y1 - start.y,
  };

  return rect;
}

export function getHeadFootTitle(type: number) {
  switch (type) {
    case HEAD_LOCATION:
      return '表格上方文本';
    case FOOT_LOCATION:
      return '表格下方文本';

    default:
      return '表格上方文本';
  }
}

export function getOcrResult(index: number, results: string[]) {
  let result = results[index];
  return result || '';
}

export function getTableCellRect(
  point: number[],
  ratio: number,
  boxNumber: number,
  tableCellSelected: boolean,
  tableFeatureSelected: boolean
) {
  const payload = translateToSVG(point, ratio);
  return {
    ...payload,
    boxNumber,
    tableCellSelected,
    tableFeatureSelected,
  };
}

export function getImageData(
  ctx: CanvasRenderingContext2D,
  box: Rect,
  ratio: number
) {
  return ctx.getImageData(
    box.x / ratio,
    box.y / ratio,
    box.w / ratio,
    box.h / ratio
  );
}

export function handleRectHover(
  target: HTMLElement,
  hover: boolean,
  stroke?: string,
  color?: string
) {
  const s = stroke || '#1EBCB2';
  const c = color || 'text-green-500';
  if (hover) {
    target.classList.add(c);
    target.setAttribute('stroke', s);
  } else {
    target.setAttribute('stroke', 'transparent');
    target.classList.remove(c);
  }
}

export function resetCanvas(
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
) {
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
  }
}

export function highlightTable(
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  rect: Rect
) {
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
    ctx.fillStyle = 'rgba(0,0,0,.25)';
    ctx.fillRect(0, 0, image.naturalWidth, image.naturalHeight);
    ctx.clearRect(rect.x, rect.y, rect.w, rect.h);
    ctx.drawImage(
      image,
      rect.x,
      rect.y,
      rect.w,
      rect.h,
      rect.x,
      rect.y,
      rect.w,
      rect.h
    );
  }
}

export function attachKey(item: any) {
  item._key = generateKey();
}
