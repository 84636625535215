import { OCRResultStatus } from 'ai-constants';
import { Form, Input } from 'antd';
import { InputRef } from 'antd/lib/input/Input';
import { useEffect, useRef, useState } from 'react';
import { OCRResult } from '../../../index.d';
import { getRecordRectId } from '../../../utils';
import ScoreRender from './ScoreRender';
import StatusRender from './StatusRender';

interface RecordTableCellItemRenderProps {
  item: OCRResult;
  handleSave?: (item: OCRResult) => void;
}

export default function RecordTableCellItemRender(
  props: RecordTableCellItemRenderProps
) {
  const { item, handleSave } = props;

  /*============================== Ref ==============================*/
  const inputRef = useRef<InputRef>(null);

  const [form] = Form.useForm();

  /*============================== State ==============================*/
  const [editing, setEditing] = useState(false);

  const [hover, setHover] = useState(false);

  /*============================== Effect ==============================*/

  useEffect(() => {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      if (editing) {
        // scroll in to view
        target.scrollIntoView();
        target.classList.add('text-green-500', 'opacity-50');
      } else {
        target.classList.remove('text-green-500', 'opacity-50`');
      }
    }
    if (editing) {
      // focus while editing
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      if (hover) {
        target.classList.add('text-green-500', 'opacity-50');
      } else {
        target.classList.remove('text-green-500', 'opacity-50');
      }
    }
  }, [hover]);

  /*============================== Handler ==============================*/

  function toggleEdit() {
    setEditing(!editing);
    form.setFieldsValue({ value: item.ocr_results[0] });
  }

  function handleClick() {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      target.scrollIntoView();
    }
  }

  async function save() {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave &&
        handleSave({
          ...item,
          ocr_results: [values.value, item.ocr_results[1]],
        });
    } catch (error) {}
  }

  return (
    <div
      className="flex items-center relative"
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {item.status === OCRResultStatus.OCRResultEdited && !editing && (
        <div className="absolute -left-2 -top-1 border-l-8 border-b-8 border-b-transparent border-l-yellow-500"></div>
      )}
      <Form form={form} component={false} layout="inline">
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%' }} name="value">
            <Input ref={inputRef} onBlur={save} onPressEnter={save} />
          </Form.Item>
        ) : (
          <span
            style={{ minWidth: '2em' }}
            className="inline-block w-full h-6"
            onClick={toggleEdit}
          >
            {item.ocr_results[0]}
          </span>
        )}
      </Form>
    </div>
  );
}
