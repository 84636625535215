import type { FeatureImage } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { getFeatureImageItemId, getFeatureImageRectId } from '../../utils';
import { handleRectHover } from './utils';

interface FeatureImageItemProps {
  item: FeatureImage;
  onRemove: (key: number) => void;
}
export default function FeatureImageItem(props: FeatureImageItemProps) {
  const { item, onRemove } = props;

  function onClick() {
    const rect = document.getElementById(getFeatureImageRectId(item._key));
    if (rect) {
      rect.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function toggle(hover: boolean) {
    const target = document.getElementById(getFeatureImageRectId(item._key));
    if (target) {
      handleRectHover(target, hover);
    }
  }

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
      className="p-2 mb-4 cursor-pointer group relative border-2 border-dashed border-gray-700 hover:border-primary hover:bg-green-50"
    >
      <div className="absolute right-0  opacity-0 group-hover:opacity-100 text-red-500">
        <Iconfont
          onClick={() => onRemove(item._key)}
          className="px-2 text-base"
          type="icondelete"
        />
      </div>
      <div className="mb-2 text-gray-700">文件包含以下图片</div>
      <div className="h-6 border border-gray-600">
        <img
          className="w-full h-full object-cover"
          src={item.dataUrl}
          alt="fi"
        />
      </div>
    </div>
  );
}
