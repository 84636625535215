import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Divider, Input, Tooltip } from "antd";
import Iconfont from "../../component/Iconfont";
import { CustomTemplateData, CustomTemplateDataInfo } from "../../index.d";
import { HoverToShow } from "components";

interface CustomTemplateDataRecognitionInfoProps {
  templateDataList: CustomTemplateDataInfo[];
  addData: () => void;
  updateData: (item: CustomTemplateDataInfo) => void;
  changeDataAnchor: (item: CustomTemplateDataInfo) => void;
  removeData: (item: CustomTemplateDataInfo) => void;
}
export default function CustomTemplateDataRecognitionInfo(
  props: CustomTemplateDataRecognitionInfoProps
) {
  const {
    templateDataList,
    updateData,
    changeDataAnchor,
    removeData,
    addData,
  } = props;
  return (
    <div style={{ minWidth: 360 }} className="ml-4">
      <div className="bg-white p-4">
        <p className="font-medium">
          识别信息（必填）
          <Tooltip title="添加需要识别的表格并配置输出内容">
            <span className="text-gray-600">
              <InfoCircleOutlined className="mx-1" />
            </span>
          </Tooltip>
        </p>
        <Divider className="normal" />
        <div
          className="border-2 border-dashed border-gray-700 hover:text-primary hover:border-primary mb-4"
          onClick={addData}
        >
          <div
            className="text-center cursor-pointer"
            style={{ lineHeight: "36px" }}
          >
            <PlusOutlined className="text-base" />
            &nbsp; 添加识别字段
          </div>
        </div>
        <ul
          className="overflow-y-auto bg-white mb-0"
          style={{ height: "calc(100vh - 375px)" }}
        >
          {templateDataList.map((item) => (
            <li key={item._key} className="mb-4">
              <TemplateDataCard {...props} item={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

interface TemplateDataCardProps {
  item: CustomTemplateDataInfo;
  updateData: (data: CustomTemplateDataInfo) => void;
  removeData: (data: CustomTemplateDataInfo) => void;
  changeDataAnchor: (data: CustomTemplateDataInfo) => void;
}
function TemplateDataCard(props: TemplateDataCardProps) {
  const { item, updateData, removeData, changeDataAnchor } = props;
  return (
    <div className="relative p-4 border">
      <button
        className="absolute top-0 right-0 text-red-500 px-2"
        onClick={() => removeData(item)}
      >
        <Iconfont type="iconclose-circle-o" />
      </button>
      <div className="mb-2">识别区域</div>
      <div className=" h-8">
        <img
          src={item.image}
          alt=""
          className="w-full h-full border object-cover"
        />
      </div>
      <div className="mb-2 mt-4">参数名称</div>
      <Input
        value={item.param}
        onChange={(e) => updateData({ ...item, param: e.target.value })}
      />
      <div className="mb-2 mt-4">锚点</div>
      <div
        className=" h-8 cursor-pointer relative mb-2"
        onClick={() => changeDataAnchor(item)}
      >
        <HoverToShow>
          <div className="bg-white h-8 leading-8 border border-gray-400 text-center cursor-pointer text-primary">
            选择锚点
          </div>
        </HoverToShow>
        {item.originImage ? (
          <img
            src={item.originImage}
            alt=""
            className="w-full h-full border object-cover"
          />
        ) : (
          <div className="bg-white h-8 leading-8 border border-gray-400 text-center cursor-pointer text-primary">
            选择锚点
          </div>
        )}
      </div>
      <Input
        value={item.origin_coord_text}
        onChange={(e) =>
          updateData({ ...item, origin_coord_text: e.target.value })
        }
      />
    </div>
  );
}
