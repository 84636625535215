import { Modal, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import type { TableConfig } from '../index.d';
import { getTextAreaTitle } from '../utils';

interface InterceptionModalProps {
  visible: boolean;
  item: TableConfig;
  onOk: (data: string[]) => void;
  onCancel: () => void;
}

export function InterceptionModal(props: InterceptionModalProps) {
  const { visible, item, onCancel, onOk } = props;

  const { ocr_result } = item;

  const [start, setStart] = useState('');

  const [end, setEnd] = useState('');

  const result = useMemo(() => {
    let startIndex = 0;
    if (start !== '') {
      startIndex = ocr_result.indexOf(start) + start.length;
    }

    let endIndex = ocr_result.length;

    if (end !== '') {
      endIndex = ocr_result.indexOf(end, startIndex);
    }

    if (startIndex === -1) {
      startIndex = 0;
    }

    if (endIndex === -1) {
      endIndex = ocr_result.length;
    }

    return ocr_result.slice(startIndex, endIndex);
  }, [ocr_result, start, end]);

  useEffect(() => {
    let s = '';
    let e = '';
    if (item.intercept.length > 0) {
      s = item.intercept[0];
      e = item.intercept[1];
    }
    setStart(s);
    setEnd(e);
  }, [item]);

  return (
    <Modal
      width="560px"
      title="识别结果截取"
      visible={visible}
      onOk={() => onOk([start, end])}
      onCancel={onCancel}
    >
      <div className="flex mb-4">
        <span className="w-24 text-right">识别区域：</span>
        <span>{getTextAreaTitle(item.box_num)}</span>
      </div>
      <div className="flex mb-4">
        <span className="w-24 text-right">当前结果：</span>
        <span className="flex-1">
          <Input.TextArea rows={6} className="w-full" value={item.ocr_result} />
        </span>
      </div>
      <div className="flex mb-4">
        <span className="w-24 text-right">起始关键字：</span>
        <span className="flex-1">
          <Input
            placeholder="请输入"
            onChange={(e) => setStart(e.target.value)}
            value={start}
          />
        </span>
      </div>
      <div className="flex mb-4">
        <span className="w-24 text-right">截止关键字：</span>
        <span className="flex-1">
          <Input
            placeholder="请输入"
            onChange={(e) => setEnd(e.target.value)}
            value={end}
          />
        </span>
      </div>
      <div className="flex">
        <span className="w-24 text-right">截取结果：</span>
        <span className="flex-1">
          <Input.TextArea rows={6} value={result} />
        </span>
      </div>
    </Modal>
  );
}
