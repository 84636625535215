import React, { createRef, PureComponent } from 'react';
let params = {
    zoomVal: 1,
    left: 0,
    top: 0,
    currentX: 0,
    currentY: 0,
    flag: false,
};
export default class PhotoPreview extends PureComponent {
    constructor() {
        super(...arguments);
        this.img = createRef();
        this.onWheel = (e) => {
            if (this.img.current) {
                params.zoomVal += e.deltaY / 1200;
                if (params.zoomVal >= 0.2) {
                    this.img.current.style.transform = 'scale(' + params.zoomVal + ')';
                }
                else {
                    params.zoomVal = 0.2;
                    this.img.current.style.transform = 'scale(' + params.zoomVal + ')';
                }
            }
        };
        this.getCss = (o, key) => {
            return o.style[key];
        };
        this.startDrag = (bar, target, callback) => {
            if (this.getCss(target, 'left') !== 'auto') {
                params.left = Number(this.getCss(target, 'left'));
            }
            if (this.getCss(target, 'top') !== 'auto') {
                params.top = Number(this.getCss(target, 'top'));
            }
            bar.onmousedown = (event) => {
                params.flag = true;
                if (!event) {
                    bar.onselectstart = () => {
                        return false;
                    };
                }
                var e = event;
                params.currentX = e.clientX;
                params.currentY = e.clientY;
            };
            document.onmouseup = () => {
                params.flag = false;
                if (this.getCss(target, 'left') !== 'auto') {
                    params.left = Number(this.getCss(target, 'left'));
                }
                if (this.getCss(target, 'top') !== 'auto') {
                    params.top = Number(this.getCss(target, 'top'));
                }
            };
            document.onmousemove = (event) => {
                var e = event;
                if (params.flag) {
                    var nowX = e.clientX, nowY = e.clientY;
                    var disX = nowX - params.currentX, disY = nowY - params.currentY;
                    target.style.left = params.left + disX + 'px';
                    target.style.top = params.top + disY + 'px';
                    if (typeof callback === 'function') {
                        callback((params.left || 0) + disX, (params.top || 0) + disY);
                    }
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    return false;
                }
            };
        };
    }
    componentDidMount() {
        if (this.img.current) {
            this.startDrag(this.img.current, this.img.current);
        }
    }
    render() {
        const { src } = this.props;
        return (React.createElement("div", { onWheel: this.onWheel, style: {
                width: '100%',
                height: '758px',
                overflow: 'hidden',
                position: 'relative',
            } },
            React.createElement("img", { ref: this.img, id: "img", src: src, alt: src, style: {
                    position: 'absolute',
                    width: '100%',
                    height: 'auto',
                    cursor: 'move',
                } })));
    }
}
