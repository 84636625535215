import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function TableFeature() {
  return (
    <div className="mb-2 font-bold">
      <span style={{ color: '#F5222D' }}>*</span>
      表格特征
      <Tooltip title="表格唯一标识，用于匹配识别文件中的目标表格。它们是 AND 关系">
        <span className="text-gray-600">
          <InfoCircleOutlined className="mx-1" />
        </span>
      </Tooltip>
      ：
    </div>
  );
}
