import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import React from 'react';
export default function Pending(props) {
    const { pending } = props;
    if (!pending) {
        return null;
    }
    return (React.createElement("div", { className: "absolute z-10 w-full h-screen bg-white flex items-center justify-center" },
        React.createElement(LoadingOutlined, { spin: true })));
}
