import { permissionGuard } from '../../permission';

interface AddTemplateButtonProps {
  onClick: () => void;
}
function AddTemplateButton(props: AddTemplateButtonProps) {
  return (
    <div
      className="card  flex justify-center items-center text-gray-600 hover:text-primary cursor-pointer bg-white mb-4 mr-4"
      onClick={props.onClick}
    >
      + 新增模板
    </div>
  );
}

export default permissionGuard(AddTemplateButton);
