import { PlusOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import {
  Form,
  message,
  Alert,
  Spin,
  Button,
  Collapse,
  Dropdown,
  Modal,
  Select,
  Input,
} from 'antd';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SegmentationResponse,
  TemplateInfo,
  ImageDataWrapper,
  SegmentationTable,
  AreaText,
  FeatureImage,
  FilenameText,
  TableInfoPayload,
  TemplateResponse,
  TemplatePayload,
  TableFeature,
} from '../../index.d';
import { getTemplate, updateTemplate } from '../../api';
import { toBlobPromise, toDataUrl } from 'utils/canvasUtils';
import { ImageIndicator } from '../../component/ImageIndicator';
import { IMAGE_OBSERVER_CLASS } from 'ai-constants';
import { useManipulatingState } from 'hooks';
import { clearOpticalCharacterRecognitionResults, getOCR } from '../../ocr';
import { DEFAULT_ADVANCED_SETTINGS } from '../../segmentation';
import { handleBlobUpload } from '../../upload';
import { getTemplateTableId, translateToSVG } from '../../utils';
import AreaTextItem from './AreaTextItem';
import {
  PANEL_KEY_TEMPLATE_FEATURE,
  NONE_FEATURES,
  NO_CONFIG,
  PICTURE_FEATURES,
  TEXT_FEATURES,
  FILE_FEATURES,
  TABLE_CONFIG_MODE_CUSTOM,
  PANEL_KEY_TABLE_INFO,
  TEXT_CONTAIN,
  TEXT_EQUAL,
  TABLE_FEATURES,
  TABLE_CELL,
} from './constants';
import FeatureImageItem from './FeatureImageItem';
import FilenameItem from './FilenameItem';
import { clearTableRecognitionRatio } from './ratioCache';
import TableRecognitionInfoOverlay from './TableRecognitionInfoOverlay';
import TableRecognitionItem from './TableRecognitionItem';
import TableRecognitionModal from './TableRecognitionModal';
import TableFeatureExtractModal from './TableFeatureExtractModal';
import TemplateFeaturesOverlay from './TemplateFeaturesOverlay';
import TemplateRecognition from './TemplateRecognition';
import { generateKey } from './keyUtils';
import { attachKey, getImageData } from './utils';
import TableFeatureItem from './TableFeatureItem';
import { TABLE_RECOGNITION_TOOLTIP } from '../../config';
import FileInfoFeatureModal from './FileInfoFeatureModal';
import { isOk } from '../../http';

export default function EditTemplate() {
  const navigate = useNavigate();

  const { sign } = useParams();

  const [areaRef] = Form.useForm();

  const [panelActiveKey, setPanelActiveKey] = useState(
    PANEL_KEY_TEMPLATE_FEATURE
  );

  const segmentResult = useRef<SegmentationResponse>({
    file_type: 1,
    origin_file_url: '',
    image_url_list: [],
    page_table_list: [],
    table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
  });

  const [filename, setFilename] = useState('');

  const templateInfo = useRef<TemplateInfo>({
    name: '',
    icon: '',
    template_classify: 0,
    description: '',
  });
  const pointRef = useRef<number[]>([]);

  const capturePageIndexRef = useRef(-1);

  const imageDataRef = useRef<ImageDataWrapper[]>([]);

  const alertMessage = useRef(TABLE_RECOGNITION_TOOLTIP);

  /*============================== State ==============================*/

  const [tableFeatureExtractModalVisible, setTableFeatureExtractModalVisible] =
    useState(false);

  const [list, setList] = useState<string[]>([]);

  const [captureType, setCaptureType] = useState(NONE_FEATURES);
  const [currentCapturePage, setCurrentCapturePage] = useState(-1);

  const [tableConfigMode, setTableConfigMode] = useState(NO_CONFIG);

  const [areaImage, setAreaImage] = useState('');

  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [textModalVisible, setTextModalVisible] = useState(false);

  const [tableConfigVisible, setTableConfigVisible] = useState(false);

  const [currentPageSrc, setCurrentPageSrc] = useState('');

  const [pageIndex, setPageIndex] = useState(0);

  const [tableIndex, setTableIndex] = useState(0);

  const [tableInfo, setTableInfo] = useState<SegmentationTable>({
    id: '',
    table: [],
    table_boxes: [[]],
  });

  const [tableList, setTableList] = useState<SegmentationTable[][]>([[]]);

  const container = useRef<HTMLDivElement>(null);

  const [spinning, setSpinning] = useState(false);

  const [tableCount, setTableCount] = useState(0);

  const [showAddTableConfigInfo, setShowAddTableConfigInfo] = useState(false);

  const [areaTextList, setAreaTextList, addAreaText, removeAreaText] =
    useManipulatingState<AreaText>([]);

  const [
    featureImageList,
    setFeatureImageList,
    addFeatureImage,
    removeFeatureImageList,
  ] = useManipulatingState<FeatureImage>([]);

  const [
    filenameTextList,
    setFilenameTextList,
    addFilenameText,
    removeFilenameText,
  ] = useManipulatingState<FilenameText>([]);

  const [
    tableInfoPayloadList,
    setTableInfoPayloadList,
    addTableInfoPayload,
    removeTableInfoPayload,
  ] = useManipulatingState<TableInfoPayload>([]);

  const [currentTableInfoPayload, setCurrentTableInfoPayload] =
    useState<TableInfoPayload>({
      _key: -1,
      table_name: '',
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: '',
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });

  const [
    tableFeatureList,
    setTableFeatureList,
    addTableFeature,
    removeTableFeature,
  ] = useManipulatingState<TableFeature>([]);

  const [currentTableFeature, setCurrentTableFeature] = useState<TableFeature>({
    _key: -1,
    page_index: 0,
    table_index: 0,
    cell_text_list: [],
    head_foot_text_list: [],
  });

  /*============================== Effect ==============================*/

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < tableList.length; i += 1) {
      count += tableList[i].length;
    }

    setTableCount(count);
  }, [tableList]);

  useEffect(() => {
    async function init(templateSign: string) {
      setSpinning(true);
      try {
        const res = await getTemplate({ templateSign });
        if (res && res.errcode === 0) {
          const { data } = res;

          const {
            template_features,
            table_info,
            table_segmentation_config,
            page_table_list,
            image_url_list,
            name,
            filename,
            icon,
            template_classify,
            description,
            file_type,
            origin_file_url,
          } = data as TemplateResponse;

          template_features.feature_image_list.forEach(attachKey);
          template_features.area_text_list.forEach(attachKey);
          template_features.filename_text_list.forEach(attachKey);

          for (
            let i = 0;
            i < template_features.table_features_list.length;
            i += 1
          ) {
            const tableFeatures = template_features.table_features_list[i];
            const { cell_text_list, head_foot_text_list } = tableFeatures;
            cell_text_list.forEach(attachKey);
            head_foot_text_list.forEach(attachKey);
            attachKey(tableFeatures);
          }

          for (let i = 0; i < table_info.length; i += 1) {
            const item = table_info[i];

            const { table_features, table_configs } = item;
            table_features.cell_text_list.forEach(attachKey);
            table_features.head_foot_text_list.forEach(attachKey);
            table_configs.forEach(attachKey);
            attachKey(item);
          }

          templateInfo.current.name = name;
          templateInfo.current.icon = icon;
          templateInfo.current.template_classify = template_classify;
          templateInfo.current.description = description;

          segmentResult.current.file_type = file_type;
          segmentResult.current.origin_file_url = origin_file_url;
          segmentResult.current.image_url_list = image_url_list;
          segmentResult.current.page_table_list = page_table_list.map(
            (i, pageIndex) =>
              i.map((t, tableIndex) => ({
                ...t,
                id: getTemplateTableId(`${pageIndex}-${tableIndex}`),
              }))
          );
          segmentResult.current.table_segmentation_config =
            table_segmentation_config;
          setList(image_url_list);
          setTableInfoPayloadList(table_info);

          setFeatureImageList(
            template_features.feature_image_list as FeatureImage[]
          );
          setAreaTextList(template_features.area_text_list as AreaText[]);
          setFilenameTextList(template_features.filename_text_list);
          setTableFeatureList(template_features.table_features_list);

          setTableList(segmentResult.current.page_table_list);

          setFilename(filename);

          clearOpticalCharacterRecognitionResults();
        }
      } catch (error) {}

      setSpinning(false);
    }

    if (sign) {
      init(sign);
    }
  }, [sign]);

  function previous() {
    // TODO: go back
    navigate('/');
  }

  async function onSave() {
    setSpinning(true);
    try {
      for (let i = 0; i < featureImageList.length; i += 1) {
        const item = featureImageList[i];
        if (!item.imageData) {
          continue;
        }
        const result = await toBlobPromise(item.imageData);
        if (result) {
          item.url = await handleBlobUpload(result);
        }
      }

      for (let i = 0; i < tableInfoPayloadList.length; i += 1) {
        const item = tableInfoPayloadList[i];
        const target = imageDataRef.current.find(
          (i) =>
            i.pageIndex === item.page_index && i.tableIndex === item.table_index
        );

        if (target) {
          if (!target.uploaded) {
            const blob = await toBlobPromise(target.data);
            if (blob) {
              target.url = await handleBlobUpload(blob);
              target.uploaded = true;
            }
          }
          item.thumbnail_image_url = target.url;
        }
      }

      const areaTextListPayload = areaTextList.map((i) => ({
        data_type: i.data_type,
        text: i.text,
        area: i.area,
        page: i.page,
      }));

      const payload: TemplatePayload = {
        ...templateInfo.current,
        filename,
        file_type: segmentResult.current?.file_type,
        origin_file_url: segmentResult.current.origin_file_url,
        image_url_list: segmentResult.current.image_url_list,
        table_segmentation_config:
          segmentResult.current.table_segmentation_config,
        template_features: {
          feature_image_list: featureImageList.map((i) => ({
            url: i.url,
            page: i.page,
            box: i.box,
          })),
          area_text_list: areaTextListPayload,
          filename_text_list: filenameTextList,
          table_features_list: tableFeatureList,
        },
        table_info: tableInfoPayloadList,
        templateSign: sign,
      };

      const res = await updateTemplate(payload);

      if (res && res.errcode === 0) {
        message.success('保存成功');
        navigate('/');
      }
    } catch (error) {}
    setSpinning(false);
    clearTableRecognitionRatio();
    // TODO: save template
  }

  function handleFilenameTextAdd(e: any) {
    const payload = { ...e, key: generateKey() };
    addFilenameText(payload);
    setFileModalVisible(false);
  }

  function handleAreaTextAdd(e: any) {
    const payload = {
      ...e,
      page: capturePageIndexRef.current,
      area: pointRef.current,
      dataUrl: areaImage,
      _key: generateKey(),
    };
    addAreaText(payload);
    setTextModalVisible(false);
  }

  function handleTemplateFeatureMenuClick(e: any) {
    const key = e.key;

    if (key === TABLE_FEATURES) {
      alertMessage.current = '请选择表格';
      setShowAddTableConfigInfo(true);
    }

    if (key === PICTURE_FEATURES || key === TEXT_FEATURES) {
      alertMessage.current = '在模板文件中框选图片';
      setShowAddTableConfigInfo(true);
      setTimeout(() => {
        setShowAddTableConfigInfo(false);
      }, 3e3);
    }

    if (key === FILE_FEATURES) {
      if (filenameTextList.length > 0) {
        // limit to one, may remove later
        message.info('已设置文件信息特征');
        return;
      }
      setFileModalVisible(true);
    }

    setCaptureType(key);
  }

  function handleTableRecognitionInfoMenuClick(e: any) {
    // TODO:
    setCaptureType(NONE_FEATURES);
    setTableConfigMode(parseInt(e.key));
    alertMessage.current = TABLE_RECOGNITION_TOOLTIP;
    setShowAddTableConfigInfo(true);
  }

  function handleTableEdit(src: string, pageIndex: number, tableIndex: number) {
    setCurrentPageSrc(src);
    setPageIndex(pageIndex);
    setTableIndex(tableIndex);
    setTableInfo(tableList[pageIndex][tableIndex]);
  }

  function onTableInfoEdit(item: TableInfoPayload) {
    setCurrentTableInfoPayload(item);
    const target = item.table_configs.find(
      (i) => i.mode !== TABLE_CONFIG_MODE_CUSTOM
    );
    if (!target) {
      return;
    }

    const src = list[item.page_index];
    if (!src) {
      return;
    }

    setTableConfigVisible(true);

    setTableConfigMode(target.mode);

    handleTableEdit(src, item.page_index, item.table_index);
    // TODO:
  }

  function handleTableFeatureExtractModalCancel() {
    setTableFeatureExtractModalVisible(false);
  }

  function handleTableConfigCancel() {
    setTableConfigVisible(false);
    setTableConfigMode(NO_CONFIG);
  }

  function addImageData(
    pageIndex: number,
    tableIndex: number,
    data: ImageData
  ) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );

    if (target) {
      return;
    }

    const payload = {
      pageIndex,
      tableIndex,
      data,
      uploaded: false,
      url: toDataUrl(data) || '',
    };

    imageDataRef.current.push(payload);
  }

  async function onAreaTextAdd(dataUrl: string, data: ImageData) {
    let text = '';
    try {
      // try extract text from image
      const blob = await toBlobPromise(data);
      if (blob) {
        const res = await getOCR(blob);
        if (isOk(res)) {
          text = res.data.results;
        }
      }
    } catch (error) {}
    areaRef.setFieldsValue({ text });
    setTextModalVisible(true);
    setAreaImage(dataUrl);
  }

  function handleTableClick(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (captureType === TABLE_FEATURES) {
      tableFeatureStart(pageIndex, tableIndex, img);
      return;
    }
    tableConfigStart(pageIndex, tableIndex, img);
  }

  function tableFeatureStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    setShowAddTableConfigInfo(false);
    setCurrentTableFeature({
      _key: -1,
      page_index: 0,
      table_index: 0,
      cell_text_list: [],
      head_foot_text_list: [],
    });

    const src = list[pageIndex];
    if (src !== undefined) {
      setTableFeatureExtractModalVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  function tableConfigStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (tableConfigMode === NO_CONFIG) {
      return;
    }

    setShowAddTableConfigInfo(false);

    setCurrentTableInfoPayload({
      _key: -1,
      table_name: '',
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: '',
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });
    const src = list[pageIndex];
    if (src !== undefined) {
      setTableConfigVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  function onCaptureConfirm(data: ImageData, point: number[], page: number) {
    const dataUrl = toDataUrl(data);
    if (!dataUrl) {
      message.error('Unknow Error');
      return;
    }
    pointRef.current = point;

    if (captureType === PICTURE_FEATURES) {
      const payload = {
        dataUrl,
        hover: false,
        page: page,
        box: point,
        url: '',
        imageData: data,
        _key: generateKey(),
      };

      addFeatureImage(payload);
    } else if (captureType === TEXT_FEATURES) {
      onAreaTextAdd(dataUrl, data);
      capturePageIndexRef.current = page;
    }

    setCaptureType(NONE_FEATURES);
    // toBlob(data, () => )
  }

  function handleImageLoad(
    ctx: CanvasRenderingContext2D,
    ratio: number,
    pageIndex: number
  ) {
    const newFeatureImageList = featureImageList.map((i) => ({ ...i }));
    for (let i = 0; i < newFeatureImageList.length; i += 1) {
      const item = newFeatureImageList[i];

      if (item.page === pageIndex) {
        const img = ctx.getImageData(
          item.box[0] * ratio,
          item.box[1] * ratio,
          (item.box[2] - item.box[0]) * ratio,
          (item.box[3] - item.box[1]) * ratio
        );
        item.dataUrl = toDataUrl(img) || '';
      }
    }

    const newAreaTextList = areaTextList.map((i) => ({ ...i }));

    for (let i = 0; i < newAreaTextList.length; i += 1) {
      const item = newAreaTextList[i];
      if (item.page === pageIndex) {
        const img = ctx.getImageData(
          item.area[0] * ratio,
          item.area[1] * ratio,
          (item.area[2] - item.area[0]) * ratio,
          (item.area[3] - item.area[1]) * ratio
        );
        item.dataUrl = toDataUrl(img) || '';
      }
    }

    const newTableFeatureList = tableFeatureList.map((i) => ({ ...i }));

    for (let i = 0; i < newTableFeatureList.length; i += 1) {
      const item = newTableFeatureList[i];
      if (item.page_index === pageIndex) {
        const tableInfo = tableList[item.page_index][item.table_index];
        const rects = tableInfo.table_boxes.map((box) =>
          translateToSVG(box, ratio)
        );

        for (let j = 0; j < item.cell_text_list.length; j += 1) {
          const cellText = item.cell_text_list[j];
          cellText.img =
            toDataUrl(getImageData(ctx, rects[cellText.box_num], 1)) || '';
        }
      }
    }

    setTableFeatureList(newTableFeatureList);

    setFeatureImageList(newFeatureImageList);
    setAreaTextList(newAreaTextList);
  }

  function getThumbnailUrl(pageIndex: number, tableIndex: number) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );
    if (target) {
      return target.url;
    }
    return '';
  }

  function onTableInfoPayloadAdd(payload: TableInfoPayload) {
    const targetIndex = tableInfoPayloadList.findIndex(
      (item) => item._key === payload._key
    );
    if (targetIndex !== -1) {
      const newTableInfoPayloadList = tableInfoPayloadList.map((i) => ({
        ...i,
      }));
      newTableInfoPayloadList[targetIndex] = payload;
      setTableInfoPayloadList(newTableInfoPayloadList);
      return;
    }
    payload.page_index = pageIndex;
    payload.table_index = tableIndex;
    payload.thumbnail_image_url = getThumbnailUrl(pageIndex, tableIndex);

    addTableInfoPayload(payload);
  }

  function onTableFeatureAdd(payload: TableFeature) {
    const index = tableFeatureList.findIndex((i) => i._key === payload._key);

    if (index !== -1) {
      const newTableFeatureList = tableFeatureList.map((i) => ({ ...i }));
      newTableFeatureList[index] = payload;
      setTableFeatureList(newTableFeatureList);
      return;
    }

    payload.page_index = pageIndex;
    payload.table_index = tableIndex;

    addTableFeature(payload);
  }

  function onCollapseChange(key: string | string[]) {
    let k = key;
    if (Array.isArray(k)) {
      k = key[0];
    }

    setPanelActiveKey(k);
  }

  const templateFeaturesCount =
    areaTextList.length +
    featureImageList.length +
    filenameTextList.length +
    tableFeatureList.length;

  const tableInfoListId = tableInfoPayloadList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableFeatureListId = tableFeatureList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableInfoListIdSet = new Set(
    tableInfoListId.concat(tableFeatureListId)
  );

  return (
    <PageContainer
      style={{ backgroundColor: '#F0F2F5', minHeight: '100vh' }}
      title="修改自定义模板"
    >
      <div className="fixed w-full left-0 top-4">
        <div className="flex justify-center">
          {showAddTableConfigInfo && (
            <Alert
              className="mx-auto"
              message={alertMessage.current}
              type="info"
              showIcon
              closable
              onClose={() => setShowAddTableConfigInfo(false)}
            />
          )}
        </div>
      </div>
      <Spin spinning={spinning}>
        <div className="flex">
          <div className="flex-1 w-full bg-white">
            <ImageIndicator
              tableCount={tableCount}
              filename={filename}
              list={list}
              container={container}
            />
            <div className="overflow-hidden">
              <div
                className="mx-auto overflow-auto"
                ref={container}
                style={{
                  maxHeight: 'calc(100vh - 190px)',
                  minHeight: 'calc(100vh - 190px)',
                }}
              >
                <ul className="mx-auto" style={{ width: 1024 }}>
                  {list.map((i, index) => (
                    <li
                      className={IMAGE_OBSERVER_CLASS}
                      key={i}
                      data-src={i}
                      data-page={index + 1}
                    >
                      <TemplateRecognition
                        src={i}
                        fragments={[]}
                        captureType={captureType}
                        tableInfoListIdSet={tableInfoListIdSet}
                        panelActiveKey={panelActiveKey}
                        enableCapture={
                          currentCapturePage === -1 ||
                          currentCapturePage === index
                        }
                        onImageLoad={(ctx, ratio) =>
                          handleImageLoad(ctx, ratio, index)
                        }
                        onTableClick={(tableIndex, img) =>
                          handleTableClick(index, tableIndex, img)
                        }
                        onCaptureStop={() => setCurrentCapturePage(-1)}
                        onCaptureStart={() => setCurrentCapturePage(index)}
                        onCaptureConfirm={(data, point) => {
                          setCurrentCapturePage(-1);
                          onCaptureConfirm(data, point, index);
                        }}
                        tables={tableList[index] || []}
                        featureFragments={featureImageList.filter(
                          (i) => i.page === index
                        )}
                        areaTextFragments={areaTextList.filter(
                          (i) => i.page === index
                        )}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className="flex h-12 items-center px-6 justify-end mt-1"
              style={{ boxShadow: '0 -2px 2px 0 #ccc' }}
            >
              <span>
                <Button onClick={previous} className="mr-4">
                  取消
                </Button>
                <Button type="primary" onClick={onSave}>
                  保存
                </Button>
              </span>
            </div>
          </div>

          <div style={{ width: '360px' }} className="ml-4">
            <Collapse
              accordion
              onChange={onCollapseChange}
              activeKey={panelActiveKey}
              expandIconPosition="right"
            >
              <Collapse.Panel
                header={`模板特征（${templateFeaturesCount}）`}
                key={PANEL_KEY_TEMPLATE_FEATURE}
              >
                <div className="border-2 border-dashed border-gray-700 mb-4">
                  <Dropdown
                    overlay={
                      <TemplateFeaturesOverlay
                        onClick={handleTemplateFeatureMenuClick}
                      />
                    }
                  >
                    <div className="text-center" style={{ lineHeight: '36px' }}>
                      <PlusOutlined className="text-base" />
                      &nbsp; 添加特征
                    </div>
                  </Dropdown>
                </div>
                <div
                  className="overflow-y-auto "
                  style={{ maxHeight: `calc(100vh - 280px)` }}
                >
                  <ul>
                    {featureImageList.map((f) => (
                      <li className="mb-4" key={f._key}>
                        <FeatureImageItem
                          onRemove={(key) =>
                            removeFeatureImageList((item) => item._key !== key)
                          }
                          item={f}
                        />
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {areaTextList.map((a) => (
                      <li key={a._key}>
                        <AreaTextItem
                          onRemove={(key) =>
                            removeAreaText((item) => item._key !== key)
                          }
                          item={a}
                        />
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {tableFeatureList.map((t) => (
                      <li key={t._key}>
                        <TableFeatureItem
                          item={t}
                          onRemove={(key) =>
                            removeTableFeature((item) => item._key !== key)
                          }
                        />
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {filenameTextList.map((f) => (
                      <li key={f._key}>
                        <FilenameItem
                          item={f}
                          onRemove={(key) =>
                            removeFilenameText((item) => item._key !== key)
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header="表格识别信息（必填）"
                key={PANEL_KEY_TABLE_INFO}
              >
                <div className="border-2 border-dashed border-gray-700 mb-4">
                  <Dropdown
                    trigger={['click', 'hover']}
                    overlay={
                      <TableRecognitionInfoOverlay
                        onClick={handleTableRecognitionInfoMenuClick}
                      />
                    }
                  >
                    <div className="text-center" style={{ lineHeight: '36px' }}>
                      <PlusOutlined className="text-base" />
                      &nbsp; 添加识别表格
                    </div>
                  </Dropdown>
                </div>
                <ul
                  className="overflow-y-auto"
                  style={{ maxHeight: `calc(100vh - 280px)` }}
                >
                  {tableInfoPayloadList.map((i) => (
                    <TableRecognitionItem
                      key={i._key}
                      item={i}
                      onRemove={(item) =>
                        removeTableInfoPayload((i) => i._key !== item._key)
                      }
                      onEdit={onTableInfoEdit}
                    />
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
      </Spin>

      <Modal
        width="560px"
        visible={textModalVisible}
        title="添加文本特征"
        onOk={areaRef.submit}
        onCancel={() => setTextModalVisible(false)}
      >
        <Form
          form={areaRef}
          layout="inline"
          initialValues={{ data_type: TEXT_CONTAIN }}
          onFinish={handleAreaTextAdd}
        >
          <div className="w-32 h-8 mr-2 border border-gray-400 rounded-sm">
            <img
              className="w-full h-full object-cover"
              src={areaImage}
              alt="area text"
            />
          </div>
          <Form.Item
            style={{ marginRight: 8 }}
            name="data_type"
            className="w-28"
          >
            <Select>
              <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
              <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="text"
            rules={[{ required: true, message: '请输入文本特征' }]}
            style={{ flex: 1, marginRight: 0 }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <FileInfoFeatureModal
        visible={fileModalVisible}
        onCancle={() => setFileModalVisible(false)}
        onFinish={handleFilenameTextAdd}
      />

      <TableRecognitionModal
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        tableInfoPayload={currentTableInfoPayload}
        tableInfo={tableInfo}
        visible={tableConfigVisible}
        mode={tableConfigMode}
        src={currentPageSrc}
        onCancel={handleTableConfigCancel}
        onTableInfoPayloadAdd={onTableInfoPayloadAdd}
      />
      <TableFeatureExtractModal
        onTableFeatureAdd={onTableFeatureAdd}
        onCancel={handleTableFeatureExtractModalCancel}
        tableFeature={currentTableFeature}
        tableInfo={tableInfo}
        src={currentPageSrc}
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        mode={TABLE_CELL}
        visible={tableFeatureExtractModalVisible}
      />
    </PageContainer>
  );
}
