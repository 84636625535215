import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
export default function SpanWithPending(props) {
    const { loading, children } = props;
    if (loading) {
        return (React.createElement("span", null,
            React.createElement(LoadingOutlined, null)));
    }
    return React.createElement("span", null, children);
}
