import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { permission } from '../../api';
import { setAuth } from 'utils/auth';
import { Pending } from 'components';
import { enablePermissionCheck } from '../../featureFlags';
import { isOk } from '../../http';
import { permissionState } from '../../permission';

interface AuthProps {
  //
}
export default function Auth(props: AuthProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const setPermission = useSetRecoilState(permissionState);

  useEffect(() => {
    async function init() {
      const token = searchParams.get('token');
      if (token) {
        setAuth({ user_id: '', token });
        if (enablePermissionCheck) {
          const res = await permission();
          if (isOk(res)) {
            setPermission(res.data.mode);
          }
        }
        navigate('/');
      }
    }

    init();
  }, []);

  return <Pending pending={true} />;
}
