import { Dropdown, Menu } from 'antd';
import Iconfont from '../../component/Iconfont';
import { permissionGuard } from '../../permission';

interface CustomTemplateItemMoreActionProps {
  active: string;
  handleDropdown: (e: any) => void;
}
function CustomTemplateItemMoreAction(
  props: CustomTemplateItemMoreActionProps
) {
  const { active, handleDropdown } = props;
  return (
    <Dropdown
      trigger={['click']}
      overlay={<Overlay active={active} onClick={handleDropdown} />}
    >
      <Iconfont type="iconellipsis" />
    </Dropdown>
  );
}

function Overlay(props: any) {
  const { active } = props;

  return (
    <Menu {...props} className="border border-gray-200">
      <Menu.Item key="edit">修改基本信息</Menu.Item>
      <Menu.Item key="disabled">{active}</Menu.Item>
      <Menu.Item key="delete">删除</Menu.Item>
    </Menu>
  );
}

export default permissionGuard(CustomTemplateItemMoreAction);
