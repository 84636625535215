import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Tooltip } from 'antd';
import { TableInfoPayload } from '../../index.d';
import TableRecognitionItem from './TableRecognitionItem';
import { getTableRecognitionItemId } from '../../utils';
import TableRecognitionInfoOverlay from './TableRecognitionInfoOverlay';

interface TableRecognitionInfoProps {
  tableInfoPayloadList: TableInfoPayload[];
  setConfigMode: (mode: any) => void;
  onTableInfoEdit: (item: TableInfoPayload) => void;
  onTableInfoRemove: (item: TableInfoPayload) => void;
}

export default function TableRecognitionInfo(props: TableRecognitionInfoProps) {
  const {
    tableInfoPayloadList,
    setConfigMode,
    onTableInfoEdit,
    onTableInfoRemove,
  } = props;
  function handleMenuClick(e: any) {
    setConfigMode(parseInt(e.key));
    // TODO: handle click
  }

  return (
    <div style={{ minWidth: '360px' }} className="ml-4">
      <div className="bg-white p-4">
        <p className="font-medium">
          表格识别信息（必填）
          <Tooltip title="添加需要识别的表格并配置输出内容">
            <span className="text-gray-600">
              <InfoCircleOutlined className="mx-1" />
            </span>
          </Tooltip>
        </p>
        <Divider className="normal" />
        <div className="border-2 border-dashed border-gray-700">
          <Dropdown
            trigger={['click', 'hover']}
            overlay={<TableRecognitionInfoOverlay onClick={handleMenuClick} />}
          >
            <div className="text-center" style={{ lineHeight: '36px' }}>
              <PlusOutlined className="text-base" />
              &nbsp; 添加识别表格
            </div>
          </Dropdown>
        </div>
      </div>
      <ul
        className="overflow-y-auto bg-white px-4"
        style={{ maxHeight: `calc(100vh - 358px)` }}
      >
        {tableInfoPayloadList.map((i) => (
          <li key={i._key} id={getTableRecognitionItemId(i._key)}>
            <TableRecognitionItem
              item={i}
              onRemove={onTableInfoRemove}
              onEdit={onTableInfoEdit}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
