import { Button } from 'antd';
import { useNavigate } from 'react-router';
import Iconfont from '../../component/Iconfont';
import { CUSTOM_OCR_API } from '../aiPower/utils';

interface TemplateSavedProps {
  sign: string;
  openTestModal: () => void;
}
export default function TemplateSaved(props: TemplateSavedProps) {
  const { sign, openTestModal } = props;

  const navigate = useNavigate();

  function toHome() {
    navigate('/');
  }
  return (
    <div className="bg-white text-center">
      <div className="py-6 text-center text-white">
        <Iconfont
          type="iconcheck-circle-o"
          className="inline-flex bg-primary rounded-full text-7xl"
        />
      </div>
      <div className="text-2xl text-gray-800 mb-4">保存成功</div>
      <div className="text-gray-600 mb-4">您可以通过以下方式使用模版</div>
      <div
        style={{ width: 560 }}
        className="bg-gray-200 bg-opacity-50 mb-4 text-left py-6 px-24 mx-auto"
      >
        <div className="mb-2">
          <div className="flex items-center mb-2">
            <div>调用指定接口使用</div>
            <a
              rel="noreferrer"
              target="_blank"
              href={CUSTOM_OCR_API}
              className="ml-2 text-primary"
            >
              接口文档
            </a>
          </div>
          <div className="mb-2">模版ID：{sign}</div>
          <div>
            <button className="text-primary" onClick={openTestModal}>
              测试接口
            </button>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <Button onClick={toHome} type="primary">
          我知道了
        </Button>
      </div>
    </div>
  );
}
