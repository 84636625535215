import {
  CloseCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Divider, Input, Select, Tooltip } from "antd";
import { TextFeatureItem } from "../../index.d";
import { CustomTemplateType } from "ai-constants";

interface CustomTemplateFeaturesProps {
  textFeatureList: TextFeatureItem[];
  templateType: string;
  onTemplateTypeChange: (value: string) => void;
  addTextFeature: () => void;
  updateTextFeature: (item: TextFeatureItem) => void;
  removeTextFeature: (item: TextFeatureItem) => void;
}
export default function CustomTemplateFeatures(
  props: CustomTemplateFeaturesProps
) {
  const {
    textFeatureList,
    templateType,
    onTemplateTypeChange,
    updateTextFeature,
    removeTextFeature,
  } = props;
  return (
    <div style={{ minWidth: "360px" }} className="ml-4">
      <div className="bg-white p-4">
        <p className="font-medium">
          模板特征（选填）
          <Tooltip title="调用多模板识别时，将文件与模板特征进行匹配，从多份自定义模板中选择匹配成功的模板来识别文件内容。它们是 AND 关系">
            <span className="text-gray-600">
              <InfoCircleOutlined className="mx-1" />
            </span>
          </Tooltip>
        </p>
        <Divider className="normal" />
        <div className="mb-4">
          <div className="mb-2">该模板属于</div>
          <Select
            className="w-full"
            value={templateType}
            onChange={(e) => onTemplateTypeChange(e)}
          >
            {CustomTemplateType.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="text-gray-700  border-2 border-dashed border-gray-700 hover:text-primary hover:border-primary mb-4">
          <div
            className="text-center cursor-pointer"
            style={{ lineHeight: "36px" }}
            onClick={() => props.addTextFeature()}
          >
            <PlusOutlined className="text-base" />
            &nbsp; 添加特征
          </div>
        </div>
        {textFeatureList.length > 0 && <div className="mb-4">包含文本</div>}
        <div
          className="overflow-y-auto  bg-white"
          style={{ maxHeight: `calc(100vh - 358px)` }}
        >
          <ul>
            {textFeatureList.map((item) => (
              <li
                key={item._key}
                className="flex items-center mb-2 text-red-500"
              >
                <Input
                  value={item.text}
                  onChange={(e) =>
                    updateTextFeature({ text: e.target.value, _key: item._key })
                  }
                />
                <CloseCircleOutlined
                  className="px-2 "
                  onClick={() => removeTextFeature(item)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
