import { Dropdown, Form, Input, Menu } from 'antd';
import { memo, Profiler, useEffect, useRef, useState } from 'react';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { OCRResult } from '../../../index.d';
import { getRecordRectId } from '../../../utils';
import ScoreRender from './ScoreRender';
import StatusRender from './StatusRender';
import { InputRef } from 'antd/lib/input/Input';
import { OCRResultStatus } from 'ai-constants';

enum RecordTableColumnMenuKey {
  InsertBefore = '1',
  Append = '2',
  Remove = '3',
  RowRemove = '4',
}

interface RecordTableColumnItemRenderProps {
  item: OCRResult;
  handleItemInsertBefore: (item: OCRResult) => void;
  handleItemAppend: (item: OCRResult) => void;
  handleItemRemove: (item: OCRResult) => void;
  handleRowRemove: (item: OCRResult) => void;
  handleSave?: (value: string) => void;
}

export default memo(RecordTableColumnItemRender);
function RecordTableColumnItemRender(props: RecordTableColumnItemRenderProps) {
  const {
    item,
    handleSave,
    handleItemAppend,
    handleItemInsertBefore,
    handleItemRemove,
    handleRowRemove,
  } = props;

  /*============================== Ref ==============================*/
  const inputRef = useRef<InputRef>(null);

  const [form] = Form.useForm();

  /*============================== State ==============================*/
  const [editing, setEditing] = useState(false);

  const [hover, setHover] = useState(false);

  /*============================== Effect ==============================*/

  useEffect(() => {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      if (editing) {
        // scroll in to view
        target.scrollIntoView();
        target.classList.add('text-green-500', 'opacity-50');
      } else {
        target.classList.remove('text-green-500', 'opacity-50`');
      }
    }
    if (editing) {
      // focus while editing
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      if (hover) {
        target.classList.add('text-green-500', 'opacity-50');
      } else {
        target.classList.remove('text-green-500', 'opacity-50');
      }
    }
  }, [hover]);

  /*============================== Handler ==============================*/

  function toggleEdit() {
    if (item.removed) {
      return;
    }
    setEditing(!editing);
    form.setFieldsValue({ value: item.ocr_results[0] });
  }

  async function save() {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave && handleSave(values.value);
    } catch (error) {
      debugger;
    }
  }

  function handleTableAction(info: MenuInfo) {
    const { key } = info;
    switch (key) {
      case RecordTableColumnMenuKey.InsertBefore:
        handleItemInsertBefore(item);
        break;
      case RecordTableColumnMenuKey.Append:
        handleItemAppend(item);
        break;
      case RecordTableColumnMenuKey.Remove:
        handleItemRemove(item);
        break;
      case RecordTableColumnMenuKey.RowRemove:
        handleRowRemove(item);
        break;
      default:
        break;
    }
  }

  function handleClick() {
    const target = document.getElementById(getRecordRectId(item.id));
    if (target) {
      target.scrollIntoView();
    }
  }

  const textCls = ['inline-block w-full h-8 truncate'];

  const menu = (
    <Menu onClick={handleTableAction}>
      <Menu.Item key={RecordTableColumnMenuKey.InsertBefore}>
        上方添加单元格
      </Menu.Item>
      <Menu.Item key={RecordTableColumnMenuKey.Append}>
        下方添加单元格
      </Menu.Item>
      <Menu.Item key={RecordTableColumnMenuKey.Remove}>删除单元格</Menu.Item>
      <Menu.Item key={RecordTableColumnMenuKey.RowRemove}>删除所在行</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']}>
      <div className="relative flex items-center justify-between">
        {item.status === OCRResultStatus.OCRResultEdited && (
          <div className="absolute left-0 top-0 border-l-8 border-b-8 border-b-transparent border-l-yellow-500"></div>
        )}
        <Form form={form} component={false} layout="inline">
          {editing ? (
            <Form.Item style={{ margin: 0, width: '100%' }} name="value">
              <Input ref={inputRef} onBlur={save} onPressEnter={save} />
            </Form.Item>
          ) : (
            <div
              className="w-full h-8 leading-8 px-1 text-sm truncate"
              onClick={handleClick}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span
                style={{ minWidth: '2em' }}
                className={textCls.join(' ')}
                onClick={toggleEdit}
              >
                {item.ocr_results[0]}
              </span>
            </div>
          )}
        </Form>
        <div className="px-1">
          <ScoreRender fade={false} score={item.ocr_results[1]} />
        </div>
      </div>
    </Dropdown>
  );
}
