import { createFromIconfontCN } from '@ant-design/icons';

let basename = process.env.REACT_APP_BASENAME;

const icon = '/font_2086295_nkzc4is07w.js';

if (basename === undefined || basename === '/') {
  basename = '';
}

const scriptUrl = `${basename}${icon}`;

const Iconfont = createFromIconfontCN({
  scriptUrl,
});

export default Iconfont;
