export const IMAGE_OBSERVER_CLASS = "image-observer-item";
export const FEATURE_IMAGE_RECT_ID_PREFIX = "a";
export const AREA_TEXT_RECT_ID_PREFIX = "b";
export const RECORD_RECT_ID_PREFIX = "c";
export const TEMPLATE_TABLE_ID_PREFIX = "d";
export const FEATURE_IMAGE_ITEM_PREFIX = "e";
export const AREA_TEXT_ITEM_PREFIX = "f";
export const FILENAME_TEXT_ITEM_PREFIX = "g";
export const TABLE_RECOGNITION_ITEM_PREFIX = "h";
export const TABLE_FEATURE_FRAGEMENT_ID_PREFIX = "i";
export const TABLE_CELL_FRAGEMENT_ID_PREFIX = "j";
export const TABLE_FEATURE_RECT_ID_PREFIX = "k";
export const TAB_PANE_INDEX = "0";
export const TAB_PANE_GENERAL = "1";
export const TAB_PANE_CUSTOM = "2";
export const TAB_PANE_TABLE = "3";
export var OCRResultStatus;
(function (OCRResultStatus) {
    OCRResultStatus[OCRResultStatus["OCRResultUnEdited"] = 0] = "OCRResultUnEdited";
    OCRResultStatus[OCRResultStatus["OCRResultEdited"] = 1] = "OCRResultEdited";
    OCRResultStatus[OCRResultStatus["OCRResultNew"] = 2] = "OCRResultNew";
})(OCRResultStatus || (OCRResultStatus = {}));
export var OCRResultMode;
(function (OCRResultMode) {
    OCRResultMode[OCRResultMode["OCRResultCustomMode"] = 0] = "OCRResultCustomMode";
    OCRResultMode[OCRResultMode["OCRResultCellMode"] = 1] = "OCRResultCellMode";
    OCRResultMode[OCRResultMode["OCRResultColumnMode"] = 2] = "OCRResultColumnMode";
})(OCRResultMode || (OCRResultMode = {}));
export const CustomTemplateType = [
    { name: "购票信息单", value: "bill" },
    { name: "汽车票", value: "bus_ticket" },
    { name: "行程单", value: "itinerary" },
    { name: "飞机票", value: "plane_ticket" },
    { name: "退票", value: "return_ticket" },
    { name: "船票", value: "steamer_ticket" },
    { name: "火车票", value: "train_ticket" },
    { name: "增值税发票", value: "vat" },
];
