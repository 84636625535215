import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getGeneralRecordItem,
  getGeneralRecordList,
  getRecordList,
} from '../../api';
import LogoutButton from '../../component/LogoutButton';
import { useRecoilState } from 'recoil';
import templateRecordState from './TemplateRecordState';
import { isOk } from '../../http';
import GeneralRecordModal from './GeneralRecordModal';

export default function GeneralRecords() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  /*============================== State ==============================*/

  const [templateRecords, setTemplateRecords] =
    useRecoilState(templateRecordState);

  const [item, setItem] = useState({ src: '', title: '', result: '' });

  const [visible, setVisible] = useState(false);

  /*============================== Effect ==============================*/

  useEffect(() => {
    async function init(template_id: string) {
      const res = await getGeneralRecordList(Number(template_id));
      if (isOk(res)) {
        setTemplateRecords(res.data.list);
      }
    }
    if (id) {
      init(id);
    }
  }, [id, setTemplateRecords]);

  /*============================== Handler ==============================*/

  async function view(record: any) {
    try {
      const res = await getGeneralRecordItem(record.id);
      if (isOk(res)) {
        let result = res.data.errmsg;
        // check if ocr succeed
        if (isOk(res.data)) {
          result = res.data.data.results;
        }
        const item = {
          src: record.file_url,
          title: record.file_url,
          result: JSON.stringify(result, null, 2),
        };

        setItem(item);
        setVisible(true);
      }
    } catch (error) {}
  }

  const title = query.get('name') + '调用记录';

  const columns = [
    {
      title: '记录ID',
      dataIndex: 'id',
    },
    {
      title: '识别文件',
      dataIndex: 'file_url',
      render(data: string) {
        return (
          <a href={data} target="_blank" rel="noreferrer">
            {data}
          </a>
        );
      },
    },
    {
      title: '调用时间',
      dataIndex: 'create_time',
    },
    {
      title: '调用详情',
      render: (record: any) => {
        return (
          <button
            className="text-primary focus:outline-none"
            onClick={() => view(record)}
          >
            详情
          </button>
        );
      },
    },
  ];

  return (
    <div
      style={{ backgroundColor: '#f0f2f5' }}
      className="h-screen overflow-auto"
    >
      <PageHeaderWrapper title={title} extra={<LogoutButton />}>
        <div className="bg-white p-6">
          <Table
            rowKey="id"
            dataSource={templateRecords}
            columns={columns}
          ></Table>
        </div>
        <GeneralRecordModal
          onOk={() => setVisible(false)}
          visible={visible}
          data={item}
        />
      </PageHeaderWrapper>
    </div>
  );
}
