import { Modal, message, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { TemplateItem } from "../../index.d";
import Iconfont from "../../component/Iconfont";
import { getTemplateIcon } from "../../utils";
import ModifyTemplateButton from "./ModifyTemplateButton";
import { deleteTemplate, disableTemplate } from "../../api";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isOk } from "../../http";
import { Permission } from "../../permission";
import CustomTemplateItemMoreAction from "./CustomTemplateItemMoreAction";

interface TableTemplateItemProps {
  item: TemplateItem;
  onTemplateDelete: (item: TemplateItem) => void;
  onTemplateModify: (item: TemplateItem) => void;
  toggleTemplateActive: (item: TemplateItem) => void;
  onTemplateTest: (item: TemplateItem) => void;
}
export default function TableTemplateItem(props: TableTemplateItemProps) {
  const { item, onTemplateDelete, onTemplateModify, onTemplateTest } = props;

  const navigate = useNavigate();

  /*============================== Request ==============================*/

  /*============================== Handler ==============================*/

  function deleteTempalte(item: TemplateItem) {
    Modal.confirm({
      title: `确定要删除模板 ${item.name} 吗?`,
      icon: <CloseCircleOutlined />,
      content: "删除后该模板信息将无法恢复",
      cancelText: "取消",
      okText: "确定",
      okType: "danger",
      onOk: async () => {
        const res = await deleteTemplate({ templateSign: item.templateSign });
        if (res && res.errcode === 0) {
          message.success("删除成功");
          onTemplateDelete(item);
        } else {
          message.error(res && res.errmsg);
        }
      },
    });
  }

  async function toggleTemplateActive() {
    const res = await disableTemplate({
      templateSign: item.templateSign,
      isDeactivate: item.isDeactivate === 0 ? 1 : 0,
    });
    if (isOk(res)) {
      message.success(item.isDeactivate === 0 ? "禁用成功" : "启用成功");
      props.toggleTemplateActive(item);
    }
  }

  function toRecord() {
    navigate(`/records/${item.templateSign}?name=${item.name}`);
  }

  function openModal() {
    onTemplateTest(item);
  }

  function toEdit() {
    navigate(`/template/table/${item.templateSign}/edit`);
  }

  function handleDropdown(e: any) {
    switch (e.key) {
      case "edit":
        onTemplateModify(item);
        break;
      case "disabled":
        toggleTemplateActive();
        break;
      case "delete":
        deleteTempalte(item);
        break;

      default:
        break;
    }
  }
  const [iconType, backgroundColor] = getTemplateIcon(item.icon);

  const active = item.isDeactivate === 0 ? "禁用" : "启用";

  return (
    <div className="card flex flex-col bg-white mb-4 mr-4 rounded-sm border border-gray-300">
      <div className="flex-1 p-6 content flex">
        <div className="h-12 rounded p-1 mr-4" style={{ backgroundColor }}>
          <Iconfont type={iconType} />
        </div>
        <div>
          <div className="flex">
            <div className="w-44 truncate text-base text-gray-800 mb-2 font-medium">
              {item.name}
            </div>
            {item.isDeactivate === 1 && (
              <div>
                <span className="text-xs border border-red-500 text-red-400 px-2 py-1">
                  已禁用
                </span>
              </div>
            )}
          </div>
          <div className="text-gray-600 overflow-hidden h-16">
            {item.description}
          </div>
        </div>
      </div>
      <div className="flex bg-gray-100 h-10 text-center">
        <div className="flex-1 leading-10 border-r border-gray-300">
          <Tooltip title="调用记录">
            <Iconfont type="iconlist" onClick={toRecord} />
          </Tooltip>
        </div>
        <div className="flex-1 leading-10 border-r border-gray-300">
          <Tooltip title="调用模板">
            <Iconfont type="iconlink" onClick={openModal} />
          </Tooltip>
        </div>
        <div className="flex-1 leading-10 border-r border-gray-300">
          <ModifyTemplateButton
            mode={Permission.MANAGE}
            fallback={<Iconfont type="iconedit" />}
            onClick={toEdit}
          />
        </div>
        <div className="flex-1 leading-10 border-r border-gray-300">
          <CustomTemplateItemMoreAction
            mode={Permission.MANAGE}
            fallback={<Iconfont type="iconellipsis" />}
            active={active}
            handleDropdown={handleDropdown}
          />
        </div>
      </div>
    </div>
  );
}
