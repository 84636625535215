import React from 'react';
export default function HoverToShow(props) {
    const { className, children } = props;
    const cls = [
        className,
        'hover:opacity-100',
        'opacity-0',
        'absolute',
        'w-full',
    ];
    return React.createElement("div", { className: cls.join(' ') }, children);
}
