import { SyntheticEvent, useRef, useState } from 'react';
import { drawImage } from 'utils/canvas';
import type { Rect, SegmentationTable } from '../../index.d';
import { getTemplateTableId, translateToSVG } from '../../utils';
import { STROKE } from './constants';

interface TemplateTableExtractionProps {
  src: string;
  tables: SegmentationTable[];
  tableInfoListIdSet: Set<string>;
  onTableClick: (index: number, img: ImageData) => void;
}

export default function TemplateTableExtraction(
  props: TemplateTableExtractionProps
) {
  const { src, tables, tableInfoListIdSet, onTableClick } = props;

  const [ratio, setRatio] = useState(1);

  // const rects = useMemo(() => tables.map(t => translateToSVG(t.table, ratio)), [ratio, tables])

  const rects = tables.map((t) => translateToSVG(t.table, ratio));

  const ref = useRef<HTMLImageElement>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const svgRef = useRef<SVGSVGElement>(null);

  function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
    const { currentTarget } = e;
    const ratio = 1024 / currentTarget.naturalWidth;

    setRatio(ratio);

    if (canvasRef.current && ref.current) {
      drawImage(canvasRef.current, ref.current);
    }
  }

  function handleClick(index: number, rect: Rect) {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const img = ctx.getImageData(rect.x, rect.y, rect.w, rect.h);
      onTableClick(index, img);
    }
    // TODO: select table
  }
  return (
    <div className="relative overflow-hidden">
      <canvas className="w-full absolute" ref={canvasRef} />
      <img
        onLoad={onImageLoad}
        ref={ref}
        src={src}
        crossOrigin="anonymous"
        alt="extraction table"
        className="block w-full table-extration-image"
      />
      <svg className="absolute w-full h-full top-0" ref={svgRef}>
        {rects.map((rect, index) => (
          <rect
            key={tables[index].id}
            id={tables[index].id}
            width={rect.w}
            height={rect.h}
            x={rect.x}
            y={rect.y}
            onClick={() => handleClick(index, rect)}
            className={`text-opacity-50  fill-current cursor-pointer ${
              tableInfoListIdSet.has(tables[index].id)
                ? 'text-green-200'
                : 'text-transparent'
            }`}
            strokeWidth="2"
            stroke={
              tableInfoListIdSet.has(tables[index].id) ? 'transparent' : STROKE
            }
          ></rect>
        ))}
      </svg>
    </div>
  );
}
