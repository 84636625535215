import React from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';
import './index.css';
import { useState } from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

const { CheckableTag } = Tag;

export interface TagRadioOptionProps {
  children: React.ReactNode;
  value?: string | number;
  style?: React.CSSProperties;
  checked?: boolean;
  onChange?: (value: string | number | undefined, state: boolean) => void;
}

const TagRadioOption: React.FC<TagRadioOptionProps> & {
  isTagRadioOption: boolean;
} = ({ children, checked, onChange, value }) => (
  <CheckableTag
    checked={!!checked}
    key={value}
    onChange={(state) => onChange && onChange(value, state)}
  >
    {children}
  </CheckableTag>
);

TagRadioOption.isTagRadioOption = true;

type TagRadioOptionType = typeof TagRadioOption;

function isTagRadioOption(
  node: React.ReactElement<TagRadioOptionType, TagRadioOptionType>
) {
  return (
    node &&
    node.type &&
    (node.type.isTagRadioOption || node.type.displayName === 'TagRadioOption')
  );
}

interface TagRadioProps {
  onChange?: (value: number | string) => void;
  children?:
    | React.ReactElement<TagRadioOptionType>
    | React.ReactElement<TagRadioOptionType>[]
    | React.ReactNode;
  className?: string;
  expandable?: boolean;
  value?: number | string;
}

TagRadio.Option = TagRadioOption;
function TagRadio(props: TagRadioProps) {
  const { children, value, onChange, className, expandable } = props;

  const [expand, setExpand] = useState(false);
  function handleTagChange(value: string | number) {
    if (onChange) {
      onChange(value);
    }
  }
  const cls = classNames('tag-select', className, {
    hasExpandTag: expandable,
    expanded: expand,
  });
  const expandText = '展开';
  const collapseText = '收起';
  // selectAllText = '全部';

  function handleExpand() {
    setExpand(!expand);
  }

  return (
    <div className={cls}>
      {children &&
        React.Children.map(children, (child: any) => {
          if (isTagRadioOption(child)) {
            return React.cloneElement(child, {
              checked: value === child.props.value,
              onChange: handleTagChange,
            });
          }
          return child;
        })}
      {expandable && (
        <span
          className="trigger cursor-pointer text-primary"
          onClick={handleExpand}
        >
          {expand ? (
            <>
              {collapseText} <UpOutlined />
            </>
          ) : (
            <>
              {expandText}
              <DownOutlined />
            </>
          )}
        </span>
      )}
    </div>
  );
}

export default TagRadio;
