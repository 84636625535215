import { useMemo } from 'react';
import type { TableInfoPayload } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { TABLE_CONFIG_MODE_CUSTOM } from './constants';
import { getTableCellRecognitionTitle, handleRectHover } from './utils';
import { getTemplateTableId } from '../../utils';

function getTemplateTableTarget(pageIndex: number, tableIndex: number) {
  const id = getTemplateTableId(`${pageIndex}-${tableIndex}`);
  return document.getElementById(id);
}

interface TableRecognitionItemProps {
  item: TableInfoPayload;
  onRemove: (item: TableInfoPayload) => void;
  onEdit: (item: TableInfoPayload) => void;
}
export default function TableRecognitionItem(props: TableRecognitionItemProps) {
  const { item, onRemove, onEdit } = props;

  const { table_configs } = item;
  const configs = useMemo(
    () => table_configs.filter((i) => i.mode !== TABLE_CONFIG_MODE_CUSTOM),
    [table_configs]
  );

  const keys = configs.map((c) => c.key);

  const mode = configs[0].mode;

  function toggle(hover: boolean) {
    const target = getTemplateTableTarget(item.page_index, item.table_index);
    if (target) {
      handleRectHover(target, hover);
    }
  }

  function onClick() {
    const target = getTemplateTableTarget(item.page_index, item.table_index);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div
      onMouseEnter={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
      onClick={onClick}
      className="p-2 mb-4 group relative border-2 border-dashed border-gray-700 hover:border-primary hover:bg-green-50"
    >
      <div className="absolute right-0 cursor-pointer opacity-0 group-hover:opacity-100">
        <span>
          <Iconfont
            onClick={() => onEdit(item)}
            className="px-2 text-base"
            type="iconGroup129"
          />
        </span>
        <span className="text-red-500">
          <Iconfont
            onClick={() => onRemove(item)}
            className="px-2 text-base"
            type="icondelete"
          />
        </span>
      </div>

      <div className="mb-2">{getTableCellRecognitionTitle(mode)}</div>
      <div className="h-16 mb-2">
        <img
          src={item.thumbnail_image_url}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        识别 {keys.join('、')} 等{keys.length}个字段
      </div>
    </div>
  );
}
