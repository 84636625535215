import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef } from 'react';
import { ImageIndicator } from '../../component/ImageIndicator';
import { IMAGE_OBSERVER_CLASS } from 'ai-constants';
import { SegmentationTable } from '../../index.d';
import TemplateTableExtraction from './TemplateTableExtraction';

interface SetupTableFileListProps {
  list: string[];
  filename: string;
  tableCount: number;
  tableList: SegmentationTable[][];
  tableInfoListIdSet: Set<string>;
  onSave: () => void;
  openAdvanceSetting: () => void;
  previous: () => void;
  onTableConfigStart: (
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) => void;
}
export default function SetupTableFileList(props: SetupTableFileListProps) {
  const {
    list,
    filename,
    tableList,
    tableCount,
    tableInfoListIdSet,
    openAdvanceSetting,
    onTableConfigStart,
    onSave,
    previous,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="w-full bg-white relative">
      <ImageIndicator
        tableCount={tableCount}
        filename={filename}
        list={list}
        showTableCount
        container={ref}
      />

      <div className="overflow-hidden">
        <div
          ref={ref}
          style={{
            maxHeight: `calc(100vh - 320px)`,
            minHeight: 'calc(100vh - 320px)',
          }}
          className="mx-auto overflow-auto"
        >
          <ul className="mx-auto" style={{ width: 1024 }}>
            {list.map((i, index) => (
              <li
                className={IMAGE_OBSERVER_CLASS}
                key={i}
                data-src={i}
                data-page={index + 1}
              >
                <TemplateTableExtraction
                  onTableClick={(tableIndex, img) =>
                    onTableConfigStart(index, tableIndex, img)
                  }
                  src={i}
                  tableInfoListIdSet={tableInfoListIdSet}
                  tables={tableList[index] || []}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sticky left-0 bottom-0 w-full flex">
        <div
          className="flex h-12 items-center px-6 justify-end w-full bg-white"
          style={{ boxShadow: '0 -2px 4px 0 #ccc' }}
        >
          <span className="flex-1">
            <Button
              className="text-gray-700"
              onClick={openAdvanceSetting}
              type="text"
            >
              <SettingOutlined />
              高级设置
              <Tooltip title="当表格识别效果不佳时，如出现表格单元格分割位置不准确，可尝试高级设置调整识别效果">
                <span className="text-gray-600 ml-1">
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            </Button>
          </span>
          <span>
            <Button onClick={previous} className="mr-4">
              上一步
            </Button>
            <Button type="primary" onClick={onSave}>
              下一步
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
