import { ulid } from 'ulid';
import { TemplateRecord, RecordType, TemplateResult } from '../../index.d';
import { OCRResultMode } from 'ai-constants';

export enum RecordsOptionsValue {
  All,
  UnVerified,
  Verified,
}

export const recordStatusMap = new Map();

recordStatusMap.set(RecordsOptionsValue.UnVerified, '待校验');
recordStatusMap.set(RecordsOptionsValue.Verified, '已校验');

export const recordsOptions = [
  { label: '全部', value: RecordsOptionsValue.All },
  { label: '待校验', value: RecordsOptionsValue.UnVerified },
  { label: '已校验', value: RecordsOptionsValue.Verified },
];

export function composeResultId(...idx: number[]) {
  return idx.join('-');
}

export function makeupRecordItem(records: TemplateRecord[], handler: any) {
  const cellTable = [];
  const columnTable = [];
  const customFiled = [];
  const itemList: RecordType[] = [];

  for (let pageIndex = 0; pageIndex < records.length; pageIndex += 1) {
    const page = records[pageIndex];
    const tables = page.results;
    for (let tableIndex = 0; tableIndex < tables.length; tableIndex += 1) {
      const table = tables[tableIndex];
      table.id = ulid();
      let tableGroups = [];
      for (let keyIndex = 0; keyIndex < table.results.length; keyIndex += 1) {
        const group = table.results[keyIndex];
        group.id = ulid();
        if (group.mode === OCRResultMode.OCRResultCustomMode) {
          customFiled.push(group);
        } else {
          group.ocr_results.forEach((cell, itemIndex) => {
            cell.id = ulid();
            const payload = {
              tableIndex,
              pageIndex,
              keyIndex,
              itemIndex,
              composeId: `${pageIndex}-${tableIndex}-${keyIndex}-${itemIndex}`,
              id: cell.id,
              result: cell.ocr_results[0],
              score: cell.ocr_results[1],
            };
            itemList.push(payload);
          });
          tableGroups.push(group);
        }
      }

      if (tableGroups.length > 0) {
        const item = tableGroups[0];

        if (item.mode === OCRResultMode.OCRResultCellMode) {
          cellTable.push({
            pageIndex,
            tableIndex,
            id: table.id,
            tableName: table.table_name,
            mode: item.mode,
            dataSource: tableGroups,
          });
        } else {
          columnTable.push({
            pageIndex,
            tableIndex,
            dataSource: tableGroups,
            id: table.id,
            tableName: table.table_name,
            mode: item.mode,
          });
        }
      }
    }
  }

  return {
    records,
    customFiled,
    cellTable,
    columnTable,
    itemList: itemList.sort((a, b) => a.score - b.score),
  };
}

export function makeupColumnPayload(result: TemplateResult, dataSource: any[]) {
  const dataIndex = ulid();
  result.ocr_results.forEach((item, index) => {
    item.id = ulid();
    // @ts-ignore
    item.dataIndex = dataIndex;
    let obj: any = {};
    if (dataSource[index]) {
      obj = dataSource[index];
    } else {
      dataSource.push(obj);
    }

    obj[dataIndex] = item;
  });

  return dataIndex;
}
