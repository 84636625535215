import { Input, Select } from 'antd';
import { ChangeEvent } from 'react';
import type { TableConfig } from '../index.d';
import {
  TABLE_CONFIG_TYPE_CN,
  TABLE_CONFIG_TYPE_EN,
  TABLE_CONFIG_TYPE_NORMAL,
  TABLE_CONFIG_TYPE_NUMBER,
} from '../pages/template/constants';
import { handleRectHover } from '../pages/template/utils';
import { getTableCellFragementId } from '../utils';
import { HoverToShow } from 'components';
import Iconfont from './Iconfont';

interface TableCellItemProps {
  item: TableConfig;
  triggerRebind: (item: TableConfig) => void;
  openSetupModal: (item: TableConfig) => void;
  setItem: (item: TableConfig) => void;
  removeItem: (item: TableConfig) => void;
}
export default function TableCellItem(props: TableCellItemProps) {
  const { item, setItem, removeItem, openSetupModal, triggerRebind } = props;

  function onKeyChange(e: ChangeEvent<HTMLInputElement>) {
    const newItem = { ...item };
    newItem.key = e.target.value;

    setItem(newItem);
  }

  function onContentTypeChange(value: any) {
    const newItem = { ...item };
    newItem.content_type = value;

    setItem(newItem);
  }

  function toggle(hover: boolean) {
    const target = document.getElementById(
      getTableCellFragementId(item.box_num)
    );
    if (target) {
      handleRectHover(target, hover);
    }
  }

  return (
    <div className="flex mb-2 items-center">
      <div
        className="w-32 h-8 relative"
        onMouseEnter={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
      >
        <HoverToShow>
          <div
            onClick={() => triggerRebind(item)}
            className="bg-white h-8 leading-8 border border-gray-400 text-center cursor-pointer text-primary"
          >
            重新绑定
          </div>
        </HoverToShow>
        <div className="w-32 h-8 border border-gray-400">
          <img
            className="w-full h-full border border-gray-400 object-cover"
            src={item.img}
            alt=""
          />
        </div>
      </div>
      <div className="w-32 ml-2">
        <Input onChange={onKeyChange} value={item.key} />
      </div>
      <div className="w-24 ml-2">
        <Select
          className="w-full"
          value={item.content_type}
          onChange={onContentTypeChange}
        >
          <Select.Option value={TABLE_CONFIG_TYPE_NORMAL}>常规</Select.Option>
          <Select.Option value={TABLE_CONFIG_TYPE_NUMBER}>纯数字</Select.Option>
          <Select.Option value={TABLE_CONFIG_TYPE_EN}>纯英文</Select.Option>
          <Select.Option value={TABLE_CONFIG_TYPE_CN}>纯中文</Select.Option>
        </Select>
      </div>
      <div className="ml-1">
        <button className="text-primary" onClick={() => openSetupModal(item)}>
          <Iconfont className="px-1" type="iconGroup121" />
        </button>
        <button className="text-red-500" onClick={() => removeItem(item)}>
          <Iconfont className="px-1" type="iconclose-circle-o" />
        </button>
      </div>
    </div>
  );
}
