import { Select, Input } from 'antd';
import { ChangeEvent } from 'react';
import type { CellText } from '../index.d';
import { TEXT_CONTAIN, TEXT_EQUAL } from '../pages/template/constants';
import { getTableFeatureFragementId } from '../utils';
import Iconfont from './Iconfont';
import { HoverToShow } from 'components';
import { SpanWithPending } from 'components';
import { handleRectHover } from '../pages/template/utils';

interface TableCellTextFeatureItemProps {
  item: CellText;
  triggerRebind: (item: CellText) => void;
  setItem: (id: number, payload: any) => void;
  removeItem: (item: CellText) => void;
}
export default function TableCellTextFeatureItem(
  props: TableCellTextFeatureItemProps
) {
  const { item, setItem, removeItem, triggerRebind } = props;

  function onDataTypeChange(value: any) {
    setItem(item._key, { data_type: value });
  }

  function onTextChange(e: ChangeEvent<HTMLInputElement>) {
    setItem(item._key, { text: e.target.value });
  }

  function toggle(hover: boolean) {
    const target = document.getElementById(
      getTableFeatureFragementId(item.box_num)
    );
    if (target) {
      handleRectHover(target, hover, '#1890ff', 'text-blue-500');
    }
  }

  return (
    <div className="flex items-center mb-2">
      <div
        className="w-32 h-8 relative"
        onMouseEnter={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
      >
        <HoverToShow>
          <div
            onClick={() => triggerRebind(item)}
            className="bg-white h-8 leading-8  border border-gray-400 text-center cursor-pointer text-primary"
          >
            重新绑定
          </div>
        </HoverToShow>
        <div className="w-32 h-8 border border-gray-400">
          <img
            className="w-full h-full object-cover  border border-gray-400"
            src={item.img}
            alt=""
          />
        </div>
      </div>
      <div className="w-32 ml-2">
        <Select
          onChange={onDataTypeChange}
          value={item.data_type}
          className="w-full"
        >
          <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
          <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
        </Select>
      </div>
      <div className="ml-2 w-32">
        <SpanWithPending loading={item.recognizing || false}>
          <Input onChange={onTextChange} value={item.text} />
        </SpanWithPending>
      </div>
      <div className="ml-2">
        <button className="text-red-500" onClick={() => removeItem(item)}>
          <Iconfont className="px-1" type="iconclose-circle-o" />
        </button>
      </div>
    </div>
  );
}
