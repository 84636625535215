import { Form, Input, message, Modal, Select } from 'antd';
import type { Category, TemplateItem } from '../../index.d';
import TemplateIcon from '../../component/TemplateIcon';
import CategoryDropdownRender from '../template/CategoryDropdownRender';
import { getCategory, updateTemplateBasicInfo } from '../../api';
import { useEffect, useState } from 'react';

interface EditTemplateModalProps {
  visible: boolean;
  item: TemplateItem;
  category: Category[];
  onCancel: () => void;
  onSave: (item: TemplateItem) => void;
  addCategory: (payload: Category) => void;
}

export default function EditTemplateModal(props: EditTemplateModalProps) {
  const { visible, item, category, onCancel, onSave, addCategory } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: item.name,
      description: item.description,
      icon: item.icon,
      templateSign: item.templateSign,
      template_classify: item.template_classify,
    });
  }, [item, form]);

  async function onFinish(value: any) {
    setLoading(true);
    const res = await updateTemplateBasicInfo(value);

    if (res && res.errcode === 0) {
      onCancel();
      onSave(value);
      message.success('保存成功');
    } else {
      message.error('保存失败');
    }
    setLoading(false);
  }

  return (
    <Modal
      onOk={() => form.submit()}
      onCancel={onCancel}
      confirmLoading={loading}
      visible={visible}
      title="修改模板信息"
      width="560px"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        initialValues={{
          icon: 'iconclock-circle #67D6C7',
          template_classify: 1,
        }}
      >
        <Form.Item name="templateSign" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="模板名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入模板名称!',
            },
          ]}
        >
          <Input placeholder="请输入模板名称" />
        </Form.Item>
        <Form.Item name="icon" label="模版图标" required>
          <TemplateIcon />
        </Form.Item>
        <Form.Item name="template_classify" label="模板分类">
          <Select
            dropdownRender={(menu) => (
              <div>
                {menu}
                <CategoryDropdownRender update={addCategory} />
              </div>
            )}
          >
            {category.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="模板描述">
          <Input.TextArea
            rows={6}
            placeholder="请输入模板描述"
            maxLength={200}
          ></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
}
