import { useEffect } from 'react';
import { IMAGE_OBSERVER_CLASS } from 'ai-constants';
export default function useImageObserver(callback, dependencies, container) {
    useEffect(() => {
        const observer = new IntersectionObserver(callback, {
            root: container.current,
        });
        const collection = document.getElementsByClassName(IMAGE_OBSERVER_CLASS);
        for (let i = 0; i < collection.length; i += 1) {
            observer.observe(collection[i]);
        }
        return () => {
            observer.disconnect();
        };
    }, [dependencies, container, callback]);
}
