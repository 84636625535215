import type { FilenameText } from '../../index.d';
import Iconfont from '../../component/Iconfont';
import { getDataTypeText } from '../../utils';

export default function FilenameItem(props: {
  item: FilenameText;
  onRemove: (key: number) => void;
}) {
  const { item, onRemove } = props;

  return (
    <div className="p-2 mb-4 group relative border-2 border-dashed border-gray-700 hover:border-primary hover:bg-green-50">
      <div className="absolute right-0 cursor-pointer opacity-0 group-hover:opacity-100 text-red-500">
        <Iconfont
          onClick={() => onRemove(item._key)}
          className="px-2 text-base"
          type="icondelete"
        />
      </div>
      <div className="mb-2 text-gray-700">
        文件名称 {getDataTypeText(item.data_type)}
      </div>
      <div className="text-primary  truncate">{item.text}</div>
    </div>
  );
}
