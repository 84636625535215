import type {
  CustomTemplateInfoPayload,
  CustomTemplatePayload,
  Feedback,
  TableSegmentationConfig,
  TemplateItem,
  TemplatePayload,
  TemplateRecord,
} from "./index.d";
import { taskResultAPI, uploadApi } from "./config";
import http, { post, _fetch } from "./http";

export async function feedback(params: Feedback) {
  return http.post("/feedback", params);
}

interface OCRTest {
  image_url: string;
  user_id: number;
}

export async function ocrTest(params: OCRTest) {
  return http.post("/generalocr", params);
}

export async function invoiceOcr(params: OCRTest) {
  return http.post("/invoiceocr", params);
}

export async function templateVersionChange(params: {
  templateSign: string;
  version_id: string;
}) {
  return http.post("/iocr/template/switchVersion", params);
}

interface OCR {
  templateSign: string;
  file_type: number;
  file_url: string;
}

type CustomTemplateOcr = {
  img_url: string;
  temp_type: string;
  temp_id: string;
};

export async function callOCR(params: OCR) {
  return http.post("/iocr/template/ocr", params);
}

export async function copyTemplate(params: { templateSign: string }) {
  return http.post("/iocr/template/copyTemplate", params);
}

export function customTemplateOcrTest(params: CustomTemplateOcr) {
  return http.post("/sc_ocr/generate", params);
}

export async function tableTemplateOcrTest(params: OCR) {
  return http.post("/iocr/template/ocr", params);
}

export async function deleteTemplate(params: { templateSign: string }) {
  return http.delete("/iocr/template", params);
}

export function deleteCustomTemplate(params: { temp_name: string }) {
  return http.post("/sc_ocr/temp_del", params);
}

export async function getTemplate(params: { templateSign: string }) {
  return http.get("/iocr/template", params);
}

export async function disableTemplate(params: {
  templateSign: string;
  isDeactivate: number;
}) {
  return http.post("/iocr/template/disabled", params);
}

export async function updateTemplateBasicInfo(params: TemplateItem) {
  return http.post("/iocr/template/basicinfo", params);
}

export async function updateTemplate(params: TemplatePayload) {
  return http.put("/iocr/template/saveTemplate", params);
}

export async function saveTemplate(params: TemplatePayload) {
  return http.post("/iocr/template/saveTemplate", params);
}

export function saveCustomTemplate(params: CustomTemplatePayload) {
  return http.post("/sc_ocr/temp", params);
}

export function updateCustomTemplateInfo(params: CustomTemplateInfoPayload) {
  return http.post("/sc_ocr/temp_update", params);
}

export function customTemplateOcr(params: { url: string }) {
  return http.post("/ocr/general", params);
}

interface Segmention {
  file_type: number; // 1 -> pdf; 2 -> image
  file_url: string;
  table_segmentation_config: TableSegmentationConfig;
}

export async function segment(params: Segmention) {
  return http.post("/iocr/tablesegmentation/segmentation", params);
}

export async function deleteCategory(params: { id: number }) {
  return http.delete("/iocr/template/classify/info", params);
}

export async function updateCategory(params: {
  user_id: number;
  id: number;
  classifyName: string;
  description?: string;
}) {
  return http.patch("/iocr/template/classify/info", params);
}

export async function newCateGory(params: {
  classifyName: string;
  description?: string;
}) {
  return http.post("/iocr/template/classify/info", params);
}

export async function getCategory() {
  return http.get("/iocr/template/classify/list", {});
}

export async function templateList(params: {
  classifyId?: number;
  templateName?: string;
  templateStatus?: number;
}) {
  return http.get("/iocr/template/classify/template/list", params);
}

export function getCustomTemplateList(params: { name?: string }) {
  return http.get("/sc_ocr/temp_list ", params);
}

export async function upload(params: { file: Blob }) {
  const fd = new FormData();
  fd.append("file", params.file);
  const res = await fetch(uploadApi, {
    method: "POST",
    body: fd,
  });
  return res.json();
}

export async function register(payload: {
  username: string;
  password: string;
}) {
  return _fetch("/authentication/register", post(payload));
}

export async function logout() {
  return http.post("/authentication/logout", {});
}

export async function login(payload: { username: string; password: string }) {
  return _fetch("/authentication/login", post(payload));
}

export async function sso(payload: { user_id: string; secret: string }) {
  return http.post("/sso/authentication", payload);
}

export async function capabilityList() {
  return http.get("/capability/list", {});
}

export async function records(id: string) {
  return http.get(`/iocr/records/${id}`);
}

export async function getRecordList(params: {
  templateSign: string;
  status: number;
}) {
  return http.post("/iocr/records/list", params);
}

export async function getGeneralRecordList(id: number) {
  return http.post("/ocr/records/list", { template_id: id });
}

export async function getGeneralRecordItem(id: string) {
  return http.get(`/ocr/records/${id}`);
}

export async function getTaskResult(task_id: string) {
  return http.get(taskResultAPI, { task_id });
}

export async function updateData(params: {
  id: string;
  results: TemplateRecord[];
}) {
  return http.post("/iocr/data/verification", params);
}

export async function permission() {
  return http.get("/permission/module");
}
