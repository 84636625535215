import {
  CloseCircleOutlined,
  EnvironmentOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import {
  Form,
  message,
  Alert,
  Spin,
  Modal,
  Select,
  Input,
  Upload,
  Button,
  Collapse,
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useState, useRef, useEffect } from "react";
import {
  TemplateInfo,
  TableInfoPayload,
  SegmentationResponse,
  SegmentationTable,
  ImageDataWrapper,
  FeatureImage,
  AreaText,
  FilenameText,
  TableSegmentationConfig,
  FileResponse,
  TemplatePayload,
  TableFeature,
  TextFeatureItem,
  OcrText,
  CustomTemplateData,
  CustomTemplateDataInfo,
  CustomTemplatePayload,
} from "../../index.d";
import {
  segment,
  saveTemplate,
  getTaskResult,
  callOCR,
  customTemplateOcr,
  saveCustomTemplate,
  getCustomTemplateList,
} from "../../api";
import { toDataUrl, toBlobPromise } from "utils/canvasUtils";
import AdvancedSettings from "../../component/AdvancedSettings";
import { Panel } from "components";
import { TABLE_RECOGNITION_TOOLTIP, uploadApi } from "../../config";
import { useManipulatingState } from "hooks";
import { clearOpticalCharacterRecognitionResults, getOCR } from "../../ocr";
import {
  DEFAULT_ADVANCED_SETTINGS,
  isSegmentationEqual,
} from "../../segmentation";
import { handleBlobUpload } from "../../upload";
import {
  scrollIntoView,
  getTableRecognitionItemId,
  getTemplateTableId,
  getFileType,
  getFilenameTextItemId,
  getAreaTextItemId,
  getFeatureImageItemId,
  stringify,
  getCustomTemplateListFromResponse,
} from "../../utils";
import {
  NONE_FEATURES,
  NO_CONFIG,
  PICTURE_FEATURES,
  TEXT_FEATURES,
  FILE_FEATURES,
  TABLE_CONFIG_MODE_CUSTOM,
  TEXT_CONTAIN,
  TEXT_EQUAL,
  TABLE_FEATURES,
  TABLE_CELL,
  CUSTOM_TEMPLATE_DATA,
  PANEL_KEY_TEMPLATE_FEATURE,
  PANEL_KEY_REGONIZE_INFO,
  CUSTOM_TEMPLATE_ANCHOR,
} from "./constants";
import { clearTableRecognitionRatio } from "./ratioCache";
import SetupTableFileList from "./SetupTableFileList";
import TableRecognitionInfo from "./TableRecognitionInfo";
import TableRecognitionModal from "./TableRecognitionModal";
import TemplateFeatures from "./TemplateFeatures";
import TemplateFileList from "./TemplateFileList";
import { TemplateInfoComponent } from "./TemplateInfo";
import TemplateSaved from "./TemplateSaved";
import UploadTemplate from "./UploadTemplate";
import { log } from "@bewd/logger";
import { generateKey } from "./keyUtils";
import Steps from "./Stepts";
import { getToken } from "utils/auth";
import TableFeatureExtractModal from "./TableFeatureExtractModal";
import FileInfoFeatureModal from "./FileInfoFeatureModal";
import { isOk } from "../../http";
import CustomTemplateFeatures from "./CustomTemplateFeatures";
import SetupCustomTemplateData from "./SetupCustomTemplateData";
import CustomTemplateDataRecognitionInfo from "./CustomTemplateDataRecognitionInfo";
import CustomTemplateFileList from "./CustomTemplateFileList";
import { ImageIndicator } from "../../component/ImageIndicator";
import { CustomTemplateType, IMAGE_OBSERVER_CLASS } from "ai-constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Iconfont from "../../component/Iconfont";

export default function CustomTemplateEdit() {
  const navigate = useNavigate();

  const { sign } = useParams();
  const [searchParams] = useSearchParams();
  /*============================== Ref ==============================*/
  const alertMessage = useRef(TABLE_RECOGNITION_TOOLTIP);

  const container = useRef<HTMLDivElement>(null);

  const capturePageIndexRef = useRef(-1);

  const customTemplateDataRef = useRef<CustomTemplateDataInfo>({
    _key: -1,
    param: "",
    originImage: '',
    origin_coord_text: "",
    origin_coord: [0, 0, 0, 0, 0, 0, 0, 0],
    target_coord: [0, 0, 0, 0, 0, 0, 0, 0],
    image: "",
  });

  /*============================== State ==============================*/
  const [templateType, setTemplateType] = useState(CustomTemplateType[0].value);
  const [panelActiveKey, setPanelActiveKey] = useState(
    PANEL_KEY_TEMPLATE_FEATURE
  );
  const [enableOcrTextPick, setEnableOcrTextPick] = useState(false);
  const [tableFeatureExtractModalVisible, setTableFeatureExtractModalVisible] =
    useState(false);

  const [advancedSettingVisible, setAdvancedSettingVisible] = useState(false);

  const [advancedSetting, setAdvancedSetting] = useState(
    DEFAULT_ADVANCED_SETTINGS
  );

  const [testTemplateVisible, setTestTemplateVisible] = useState(false);
  const [testFiles, setTestFiles] = useState<UploadFile<any>[]>([]);

  const [testFileResult, setTestFileResult] = useState("");
  const [filenameRef] = Form.useForm();
  const [areaRef] = Form.useForm();
  const [current, setCurrent] = useState(0);

  const [spinning, setSpinning] = useState(false);

  const [saving, setSaving] = useState(false);

  const [querying, setQuerying] = useState(false);

  const queryingTimer = useRef<NodeJS.Timeout | null>(null);

  const [filename, setFilename] = useState("");

  const templateInfo = useRef<TemplateInfo>({
    name: "",
    icon: "",
    template_classify: 0,
    description: "",
  });

  const [
    templateDataList,
    setTemplateDataList,
    addTemplateDataItem,
    removeTemplateDataItem,
  ] = useManipulatingState<CustomTemplateDataInfo>([]);

  const [currentTableInfoPayload, setCurrentTableInfoPayload] =
    useState<TableInfoPayload>({
      _key: -1,
      table_name: "",
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: "",
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });
  const [
    textFeatureList,
    setTextFeatureList,
    addTextFeatureItem,
    removeTextFeatureItem,
  ] = useManipulatingState<TextFeatureItem>([]);

  const [
    tableInfoPayloadList,
    setTableInfoPayloadList,
    addTableInfoPayload,
    removeTableInfoPayload,
  ] = useManipulatingState<TableInfoPayload>([]);
  const segmentResult = useRef<SegmentationResponse>({
    file_type: 1,
    origin_file_url: "",
    image_url_list: [],
    page_table_list: [],
    table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
  });

  const [
    tableFeatureList,
    setTableFeatureList,
    addTableFeature,
    removeTableFeature,
  ] = useManipulatingState<TableFeature>([]);
  const [currentTableFeature, setCurrentTableFeature] = useState<TableFeature>({
    _key: -1,
    page_index: 0,
    table_index: 0,
    cell_text_list: [],
    head_foot_text_list: [],
  });

  const [captureType, setCaptureType] = useState(NONE_FEATURES);
  const [currentCapturePage, setCurrentCapturePage] = useState(-1);

  const [areaImage, setAreaImage] = useState("");

  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [textModalVisible, setTextModalVisible] = useState(false);
  const [list, setList] = useState<string[]>([
    "https://cdn.commander.aistreamrpa.com/jpg/da51690b75197b5bc9290a1f2f4a42ac.jpg",
  ]);
  const [tableList, setTableList] = useState<SegmentationTable[][]>([[]]);
  const [ocrTextList, setOcrTextList] = useState<OcrText[]>([]);

  const [tableInfo, setTableInfo] = useState<SegmentationTable>({
    id: "",
    table: [],
    table_boxes: [[]],
  });

  const imageDataRef = useRef<ImageDataWrapper[]>([]);

  const pointRef = useRef<number[]>([]);

  const [tableConfigVisible, setTableConfigVisible] = useState(false);

  const [tableConfigMode, setTableConfigMode] = useState(NO_CONFIG);

  const [currentPageSrc, setCurrentPageSrc] = useState("");

  const [pageIndex, setPageIndex] = useState(0);

  const [tableIndex, setTableIndex] = useState(0);

  const [uploaded, setUploaded] = useState(false);

  const [tableCount, setTableCount] = useState(0);

  const [showAddTableConfigInfo, setShowAddTableConfigInfo] = useState(false);

  const [
    featureImageList,
    setFeatureImageList,
    addFeatureImage,
    removeFeatureImageList,
  ] = useManipulatingState<FeatureImage>([]);

  const [areaTextList, setAreaTextList, addAreaText, removeAreaText] =
    useManipulatingState<AreaText>([]);

  const [
    filenameTextList,
    setFilenameTextList,
    addFilenameText,
    removeFilenameText,
  ] = useManipulatingState<FilenameText>([]);

  useEffect(() => {
    async function init() {
      setSpinning(true);
      try {
        // TODO: call api
        const res = await getCustomTemplateList({});
        if (isOk(res)) {
          const list = getCustomTemplateListFromResponse(res.data);
          const name = searchParams.get("name");
          const item = list.find((i) => i.temp_name === name);
          if (item) {
            setTextFeatureList(
              item.keyword_list.map((i) => ({ _key: generateKey(), text: i }))
            );
            setTemplateDataList(
              item.data_list.map((i) => ({
                ...i,
                _key: generateKey(),
                image: "",
                originImage: "",
              }))
            );
            setFilename(name || "");
            setList([item.img_url]);
          }
        }
      } catch (error) {}
      setSpinning(false);
    }
    if (searchParams) {
      init();
    }
  }, [searchParams]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < tableList.length; i += 1) {
      count += tableList[i].length;
    }

    setTableCount(count);
    log("table count", count);
  }, [tableList]);

  function beginTableConfigAdd(mode: number) {
    alertMessage.current = TABLE_RECOGNITION_TOOLTIP;
    setTableConfigMode(mode);
    setShowAddTableConfigInfo(true);
  }

  function handleTableConfigCancel() {
    setTableConfigVisible(false);
    setTableConfigMode(NO_CONFIG);
  }

  function addImageData(
    pageIndex: number,
    tableIndex: number,
    data: ImageData
  ) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );

    if (target) {
      return;
    }

    const payload = {
      pageIndex,
      tableIndex,
      data,
      uploaded: false,
      url: toDataUrl(data) || "",
    };

    imageDataRef.current.push(payload);
  }

  function getThumbnailUrl(pageIndex: number, tableIndex: number) {
    const target = imageDataRef.current.find(
      (i) => i.pageIndex === pageIndex && i.tableIndex === tableIndex
    );
    if (target) {
      return target.url;
    }
    return "";
  }

  function onTableInfoPayloadAdd(payload: TableInfoPayload) {
    const targetIndex = tableInfoPayloadList.findIndex(
      (item) => item._key === payload._key
    );
    if (targetIndex !== -1) {
      log(payload);

      const newTableInfoPayloadList = tableInfoPayloadList.map((i) => ({
        ...i,
      }));
      newTableInfoPayloadList[targetIndex] = payload;
      setTableInfoPayloadList(newTableInfoPayloadList);
      return;
    }
    payload.page_index = pageIndex;
    payload.table_index = tableIndex;
    payload.thumbnail_image_url = getThumbnailUrl(pageIndex, tableIndex);

    addTableInfoPayload(payload);
    setTimeout(() => {
      scrollIntoView(getTableRecognitionItemId(payload._key));
    }, 0);
  }

  function onTestTemplateModalCancel() {
    if (queryingTimer.current) {
      clearTimeout(queryingTimer.current);
    }
    setTestTemplateVisible(false);
  }

  async function handleImageOcr(image: string) {
    try {
      setSpinning(true);
      const result = await customTemplateOcr({ url: image });
      if (isOk(result)) {
        const { task_id } = result.data;
        let done = false;
        while (!done) {
          const res = await getTaskResult(task_id);
          if (isOk(res)) {
            if (res.data.status === "SUCCESS") {
              done = true;
              const { results } = res.data.results.data;
              setList([image]);
              setOcrTextList(
                results.map((item: OcrText) => ({
                  ...item,
                  _key: generateKey(),
                }))
              );
              setUploaded(true);
            }
          } else {
            done = true;
          }
          if (!done) {
            await new Promise((resolve) => setTimeout(resolve, 1e4));
          }
        }
      }
    } catch (error) {
      log(error);
    }
    setSpinning(false);
  }

  async function handleSegment(
    type: number,
    url: string,
    config: TableSegmentationConfig
  ) {
    try {
      setSpinning(true);
      const result: {
        errcode: number;
        data: { results: SegmentationResponse };
      } = await segment({
        file_type: type,
        file_url: url,
        table_segmentation_config: config,
      });
      if (result.errcode === 0) {
        const { results } = result.data;
        setList(results.image_url_list);
        setTableList(
          results.page_table_list.map((i, pageIndex) =>
            i.map((t, tableIndex) => ({
              ...t,
              id: getTemplateTableId(`${pageIndex}-${tableIndex}`),
            }))
          )
        );
        setAdvancedSetting(config);
        setTableInfoPayloadList([]);
        setUploaded(true);
        clearOpticalCharacterRecognitionResults();
        segmentResult.current = {
          file_type: results.file_type,
          origin_file_url: results.origin_file_url,
          image_url_list: results.image_url_list,
          page_table_list: results.page_table_list,
          table_segmentation_config: results.table_segmentation_config,
        };
      }
    } catch (error) {}
    setSpinning(false);
  }

  function onCollapseChange(key: string | string[]) {
    let k = key;
    if (Array.isArray(k)) {
      k = key[0];
    }

    setPanelActiveKey(k);
  }

  async function onFileUploaded(fileRes: FileResponse, name: string) {
    setFilename(name);
    setAreaTextList([]);
    setFeatureImageList([]);
    setFilenameTextList([]);
    handleImageOcr(fileRes.file_url);
  }

  function onAdvancedUpdate(config: TableSegmentationConfig) {
    setAdvancedSettingVisible(false);
    if (isSegmentationEqual(config, advancedSetting)) {
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
      }, 50);
      return;
    }
    handleSegment(
      segmentResult.current.file_type,
      segmentResult.current.origin_file_url,
      config
    );
  }

  async function handleTemplateSave() {
    try {
      // set loading
      // upload feature images
      if (templateDataList.some((item) => item.param === "")) {
        message.warn("请填写参数名称");
        return;
      }

      setSaving(true);

      // for (let i = 0; i < featureImageList.length; i += 1) {
      //   const item = featureImageList[i];
      //   const result = await toBlobPromise(item.imageData);
      //   if (result) {
      //     item.url = await handleBlobUpload(result);
      //   }
      // }
      //
      // for (let i = 0; i < tableInfoPayloadList.length; i += 1) {
      //   const item = tableInfoPayloadList[i];
      //   const target = imageDataRef.current.find(
      //     (i) =>
      //       i.pageIndex === item.page_index && i.tableIndex === item.table_index
      //   );
      //
      //   if (target) {
      //     if (!target.uploaded) {
      //       const blob = await toBlobPromise(target.data);
      //       if (blob) {
      //         target.url = await handleBlobUpload(blob);
      //         target.uploaded = true;
      //       }
      //     }
      //     item.thumbnail_image_url = target.url;
      //   }
      // }
      //
      // const areaTextListPayload = areaTextList.map((i) => ({
      //   data_type: i.data_type,
      //   text: i.text,
      //   area: i.area,
      //   page: i.page,
      // }));
      //
      const image = document.getElementsByClassName(
        "text-extration-image"
      )[0] as HTMLImageElement;
      const payload: CustomTemplatePayload = {
        temp_type: templateType,
        img_url: list[0],
        keyword_list: textFeatureList.map((i) => i.text),
        temp_name: templateInfo.current.name,
        img_width: image.naturalWidth,
        img_height: image.naturalHeight,
        data_list: templateDataList.map((item) => ({
          param: item.param,
          origin_coord_text: "",
          origin_coord: item.origin_coord,
          target_coord: item.target_coord,
        })),
      };

      const res = await saveCustomTemplate(payload);

      if (res && res.errcode === 0) {
        navigate("/");
        message.success(res.errmsg);
      } else {
        throw new Error(res.errmsg);
      }
    } catch (error: any) {
      message.error(error.message);
    }

    setSaving(false);
    clearTableRecognitionRatio();
  }

  async function queryTaskResult(id: string) {
    const res = await getTaskResult(id);
    if (res && res.errcode === 0) {
      if (res.data.status === "SUCCESS" || res.data.results) {
        setTestFileResult(stringify(res.data.results));
        setQuerying(false);
        return;
      }
      queryingTimer.current = setTimeout(() => {
        queryTaskResult(id);
      }, 1e4);
    } else {
      setQuerying(false);
      message.error(res.errmsg);
    }
  }

  //   async function onTestFileUploaded(fileRes: FileResponse) {
  //     setQuerying(true);
  //     try {
  //       const response = await callOCR({
  //         templateSign: sign,
  //         file_type: getFileType(fileRes.file_url),
  //         file_url: fileRes.file_url,
  //       });
  //       if (response && response.errcode === 0) {
  //         queryTaskResult(response.data.task_id);
  //       }
  //     } catch (error) {}
  //   }

  function handleFilenameTextAdd(e: any) {
    const payload = { ...e, key: generateKey() };
    addFilenameText(payload);
    filenameRef.resetFields();
    setTimeout(() => {
      scrollIntoView(getFilenameTextItemId(payload.key));
    }, 0);
    setFileModalVisible(false);
  }

  function handleFilenameTextRemove(key: number) {
    removeFilenameText((item) => item._key !== key);
  }

  function handleTableFeatureRemove(key: number) {
    removeTableFeature((item) => item._key !== key);
  }

  function handleAreaTextAdd(e: any) {
    const payload = {
      ...e,
      page: capturePageIndexRef.current,
      area: pointRef.current,
      dataUrl: areaImage,
      _key: generateKey(),
    };
    addAreaText(payload);
    setTimeout(() => {
      scrollIntoView(getAreaTextItemId(payload.key));
    }, 0);
    setTextModalVisible(false);
  }

  function handleAreaTextRemove(key: number) {
    removeAreaText((item) => item._key !== key);
  }

  async function onAreaTextAdd(dataUrl: string, data: ImageData) {
    let text = "";
    try {
      // try extract text from image
      const blob = await toBlobPromise(data);
      if (blob) {
        const res = await getOCR(blob);
        if (isOk(res)) {
          text = res.data.results;
        }
      }
    } catch (error) {}
    areaRef.setFieldsValue({ text });
    setTextModalVisible(true);
    setAreaImage(dataUrl);
  }

  function handleFeatureImageRemove(key: number) {
    removeFeatureImageList((item) => item._key !== key);
  }

  function onCaptureConfirm(data: ImageData, point: number[], page: number) {
    const dataUrl = toDataUrl(data);
    if (!dataUrl) {
      message.error("Unknow Error");
      return;
    }
    pointRef.current = point;

    if (captureType === PICTURE_FEATURES) {
      const payload = {
        dataUrl,
        hover: false,
        page: page,
        box: point,
        url: "",
        imageData: data,
        _key: generateKey(),
      };

      addFeatureImage(payload);

      setTimeout(() => {
        scrollIntoView(getFeatureImageItemId(payload._key));
      }, 0);
    } else if (captureType === TEXT_FEATURES) {
      onAreaTextAdd(dataUrl, data);
      capturePageIndexRef.current = page;
    }

    setCaptureType(NONE_FEATURES);
  }

  function next(info: TemplateInfo) {
    templateInfo.current = info;
    setCurrent(1);
  }

  function handleTableEdit(src: string, pageIndex: number, tableIndex: number) {
    setCurrentPageSrc(src);
    setPageIndex(pageIndex);
    setTableIndex(tableIndex);
    setTableInfo(tableList[pageIndex][tableIndex]);
  }

  function tableConfigStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (tableConfigMode === NO_CONFIG) {
      return;
    }

    setShowAddTableConfigInfo(false);

    setCurrentTableInfoPayload({
      _key: -1,
      table_name: "",
      page_index: 0,
      table_index: 0,
      thumbnail_image_url: "",
      table_features: {
        cell_text_list: [],
        head_foot_text_list: [],
      },
      table_configs: [],
      table_segmentation_config: DEFAULT_ADVANCED_SETTINGS,
    });
    const src = list[pageIndex];
    if (src !== undefined) {
      setTableConfigVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  function handleTemplateFeatureAdd(captureType: string) {
    // TODO:
    if (captureType === TABLE_FEATURES) {
      alertMessage.current = "请选择表格";
      setShowAddTableConfigInfo(true);
    }

    if (captureType === PICTURE_FEATURES || captureType === TEXT_FEATURES) {
      alertMessage.current = "在模板文件中框选图片";
      setShowAddTableConfigInfo(true);
      setTimeout(() => {
        setShowAddTableConfigInfo(false);
      }, 3e3);
    }

    if (captureType === FILE_FEATURES) {
      if (filenameTextList.length > 0) {
        // limit to one, may remove later
        message.info("已设置文件信息特征");
        return;
      }
      setFileModalVisible(true);
    }

    setCaptureType(captureType);
  }

  function openAdvanceSetting() {
    setAdvancedSettingVisible(true);
    // TODO:
  }

  function onTableInfoEdit(item: TableInfoPayload) {
    setCurrentTableInfoPayload(item);
    const target = item.table_configs.find(
      (i) => i.mode !== TABLE_CONFIG_MODE_CUSTOM
    );
    if (!target) {
      return;
    }

    const src = list[item.page_index];
    if (!src) {
      return;
    }

    setTableConfigVisible(true);

    setTableConfigMode(target.mode);

    handleTableEdit(src, item.page_index, item.table_index);
    // TODO:
  }

  function onTableInfoRemove(item: TableInfoPayload) {
    removeTableInfoPayload((i) => i._key !== item._key);
  }

  function handleTableFeatureExtractModalCancel() {
    setTableFeatureExtractModalVisible(false);
  }

  function onTableFeatureAdd(payload: TableFeature) {
    const index = tableFeatureList.findIndex((i) => i._key === payload._key);

    if (index !== -1) {
      const newTableFeatureList = tableFeatureList.map((i) => ({ ...i }));
      newTableFeatureList[index] = payload;
      setTableFeatureList(newTableFeatureList);
      return;
    }

    payload.page_index = pageIndex;
    payload.table_index = tableIndex;

    addTableFeature(payload);
  }

  function handleTableClick(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    if (captureType === TABLE_FEATURES) {
      tableFeatureStart(pageIndex, tableIndex, img);
      return;
    }
  }

  function tableFeatureStart(
    pageIndex: number,
    tableIndex: number,
    img: ImageData
  ) {
    setShowAddTableConfigInfo(false);
    setCurrentTableFeature({
      _key: -1,
      page_index: 0,
      table_index: 0,
      cell_text_list: [],
      head_foot_text_list: [],
    });

    const src = list[pageIndex];
    if (src !== undefined) {
      setTableFeatureExtractModalVisible(true);

      handleTableEdit(src, pageIndex, tableIndex);

      addImageData(pageIndex, tableIndex, img);
    }
  }

  function changeCustomTemplateDataAnchor(item: CustomTemplateDataInfo) {
    // enable ocr text pick
    customTemplateDataRef.current = { ...item };
    setCaptureType(CUSTOM_TEMPLATE_ANCHOR);
  }

  function onCustomTemplateDataAnchorConfirm(text: OcrText, img:ImageData) {
    const dataUrl = toDataUrl(img);
    if (!dataUrl) {
      message.error("Unknow Error");
      return;
    }
    const item = customTemplateDataRef.current;
    item.origin_coord_text = text.text[0];
    item.origin_coord = text.box;
    item.originImage = dataUrl;
    const index = templateDataList.findIndex((i) => i._key === item._key);
    if (index !== -1) {
      const newList = templateDataList.map((i) => ({ ...i }));
      newList[index] = item;
      setTemplateDataList(newList);
    }
    setEnableOcrTextPick(false);
  }

  // async function onCustomTemplateDataAnchorConfirm(
  //   data: ImageData,
  //   point: number[]
  // ) {
  //   let text = "";
  //   try {
  //     const blob = await toBlobPromise(data);
  //     if (blob) {
  //       const res = await getOCR(blob);
  //       if (isOk(res)) {
  //         text = res.data.results;
  //       }
  //     }
  //   } catch (error) {}
  //   // add ocr text
  //   const item = customTemplateDataRef.current;
  //   item.origin_coord = point;
  //   item.origin_coord_text = text;
  //   const index = templateDataList.findIndex((i) => i._key === item._key);
  //   if (index !== -1) {
  //     const newList = templateDataList.map((i) => ({ ...i }));
  //     newList[index] = item;
  //     setTemplateDataList(newList);
  //   }
  //   setEnableOcrTextPick(false);
  //   setCaptureType(NONE_FEATURES);
  // }

  function addCustomTemplateData() {
    // enable capture
    setCaptureType(CUSTOM_TEMPLATE_DATA);
  }

  function onCustomTemplateDataCaptureConfirm(
    data: ImageData,
    point: number[]
  ) {
    const dataUrl = toDataUrl(data);
    if (!dataUrl) {
      message.error("Unknow Error");
      return;
    }
    pointRef.current = point;
    if (captureType === CUSTOM_TEMPLATE_DATA) {
      addTemplateDataItem({
        _key: generateKey(),
        param: "",
        target_coord: point,
        origin_coord: [0, 0, 0, 0, 0, 0, 0, 0],
        origin_coord_text: "",
        originImage: "",
        image: dataUrl,
      });
    }
    if (captureType === CUSTOM_TEMPLATE_ANCHOR) {
      // onCustomTemplateDataAnchorConfirm(data, point);
    }
    setCaptureType(NONE_FEATURES);
  }

  function updateCustomTemplateData(item: CustomTemplateDataInfo) {
    const index = templateDataList.findIndex((i) => i._key === item._key);
    if (index !== -1) {
      const newList = templateDataList.map((i) => ({ ...i }));
      newList[index] = item;
      setTemplateDataList(newList);
    }
  }

  function removeCustomTemplateData(item: CustomTemplateDataInfo) {
    removeTemplateDataItem((i) => i._key !== item._key);
  }

  function addTextFeature() {
    addTextFeatureItem({
      _key: generateKey(),
      text: "",
    });
  }

  function updateTextFeature(item: TextFeatureItem) {
    const index = textFeatureList.findIndex((i) => i._key === item._key);
    if (index !== -1) {
      const newList = textFeatureList.map((i) => ({ ...i }));
      newList[index] = item;
      setTextFeatureList(newList);
    }
  }
  function removeTextFeature(item: TextFeatureItem) {
    removeTextFeatureItem((i) => i._key !== item._key);
  }

  const tableInfoListId = tableInfoPayloadList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableFeatureListId = tableFeatureList.map((i) =>
    getTemplateTableId(`${i.page_index}-${i.table_index}`)
  );

  const tableFeatureListIdSet = new Set(tableFeatureListId);

  const tableInfoListIdSet = new Set(tableInfoListId);

  const templateFeaturesCount = textFeatureList.length;

  return (
    <PageContainer
      style={{ backgroundColor: "#F0F2F5", minHeight: "100vh" }}
      title="修改自定义模板"
    >
      <div className="fixed w-full left-0 top-4">
        <div className="flex justify-center">
          {showAddTableConfigInfo && (
            <Alert
              className="mx-auto"
              message={alertMessage.current}
              type="info"
              showIcon
              closable
              onClose={() => setShowAddTableConfigInfo(false)}
            />
          )}
        </div>
      </div>
      <Spin spinning={spinning}>
        <div className="flex">
          <div className="flex-1 w-full bg-white">
            <SetupCustomTemplateData
              filename={filename}
              list={list}
              textList={ocrTextList}
              nextText="保存"
              heightOffset={190}
              previous={() => setCurrent(1)}
              onSave={handleTemplateSave}
              enableOcrTextPick={enableOcrTextPick}
              onAnchorConfirm={onCustomTemplateDataAnchorConfirm}
              captureType={captureType}
              onCaptureStop={() => setCaptureType(NONE_FEATURES)}
              onCaptureConfirm={onCustomTemplateDataCaptureConfirm}
            />
          </div>

          <div className="ml-4" style={{ width: 362 }}>
            <Collapse
              accordion
              onChange={onCollapseChange}
              activeKey={panelActiveKey}
              expandIconPosition="right"
            >
              <Collapse.Panel
                header={`模板特征（${templateFeaturesCount}）`}
                key={PANEL_KEY_TEMPLATE_FEATURE}
              >
                <div className="text-gray-700  border-2 border-dashed border-gray-700 hover:text-primary hover:border-primary mb-4">
                  <div
                    className="text-center cursor-pointer"
                    style={{ lineHeight: "36px" }}
                    onClick={() => addTextFeature()}
                  >
                    <PlusOutlined className="text-base" />
                    &nbsp; 添加特征
                  </div>
                </div>
                <div
                  className="overflow-y-auto "
                  style={{ maxHeight: `calc(100vh - 280px)` }}
                >
                  <ul>
                    {textFeatureList.map((item) => (
                      <li
                        key={item._key}
                        className="flex items-center mb-2 text-red-500"
                      >
                        <Input
                          value={item.text}
                          onChange={(e) =>
                            updateTextFeature({
                              text: e.target.value,
                              _key: item._key,
                            })
                          }
                        />
                        <CloseCircleOutlined
                          className="px-2 "
                          onClick={() => removeTextFeature(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header="识别信息（必填）"
                key={PANEL_KEY_REGONIZE_INFO}
              >
                <div
                  className="border-2 border-dashed border-gray-700 hover:text-primary hover:border-primary mb-4"
                  onClick={addCustomTemplateData}
                >
                  <div
                    className="text-center cursor-pointer"
                    style={{ lineHeight: "36px" }}
                  >
                    <PlusOutlined className="text-base" />
                    &nbsp; 添加识别字段
                  </div>
                </div>
                {templateDataList.length > 0 && (
                  <div className="mb-2 flex">
                    <div className="w-32">识别区域</div>
                    <div className="w-32 ml-2">参数名称</div>
                  </div>
                )}
                <ul className="overflow-y-auto bg-white">
                  {templateDataList.map((item) => (
                    <li key={item._key} className="mb-4">
                      <div className="flex items-center">
                        <div className="w-32 h-8">
                          <img
                            src={item.image}
                            alt=""
                            className="w-full h-full border object-cover"
                          />
                        </div>
                        <div className="w-32 ml-2">
                          <Input
                            value={item.param}
                            onChange={(e) =>
                              updateCustomTemplateData({
                                ...item,
                                param: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="ml-1">
                          <button
                            className="text-primary px-2"
                            onClick={() => changeCustomTemplateDataAnchor(item)}
                          >
                            <EnvironmentOutlined />
                          </button>
                          <button
                            className="text-red-500 px-2"
                            onClick={() => removeCustomTemplateData(item)}
                          >
                            <Iconfont type="iconclose-circle-o" />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
      </Spin>

      <Modal
        width="560px"
        visible={textModalVisible}
        title="添加文本特征"
        onOk={areaRef.submit}
        onCancel={() => setTextModalVisible(false)}
      >
        <Form
          form={areaRef}
          layout="inline"
          initialValues={{ data_type: TEXT_CONTAIN }}
          onFinish={handleAreaTextAdd}
        >
          <div className="w-32 h-8 mr-2 border border-gray-400 rounded-sm">
            <img
              className="w-full h-full object-cover"
              src={areaImage}
              alt="area text"
            />
          </div>
          <Form.Item
            style={{ marginRight: 8 }}
            name="data_type"
            className="w-28"
          >
            <Select>
              <Select.Option value={TEXT_CONTAIN}>文本包含</Select.Option>
              <Select.Option value={TEXT_EQUAL}>文本等于</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="text"
            rules={[{ required: true, message: "请输入文本特征" }]}
            style={{ flex: 1, marginRight: 8 }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <FileInfoFeatureModal
        visible={fileModalVisible}
        onCancle={() => setFileModalVisible(false)}
        onFinish={handleFilenameTextAdd}
      />

      <TableRecognitionModal
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        tableInfoPayload={currentTableInfoPayload}
        tableInfo={tableInfo}
        visible={tableConfigVisible}
        mode={tableConfigMode}
        src={currentPageSrc}
        onCancel={handleTableConfigCancel}
        onTableInfoPayloadAdd={onTableInfoPayloadAdd}
      />

      <TableFeatureExtractModal
        onTableFeatureAdd={onTableFeatureAdd}
        onCancel={handleTableFeatureExtractModalCancel}
        tableFeature={currentTableFeature}
        tableInfo={tableInfo}
        src={currentPageSrc}
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        mode={TABLE_CELL}
        visible={tableFeatureExtractModalVisible}
      />

      <AdvancedSettings
        visible={advancedSettingVisible}
        advancedSetting={advancedSetting}
        onConfirm={onAdvancedUpdate}
      />
    </PageContainer>
  );
}
