import { TemplateResult } from '../../../index.d';

interface CustomFieldRenderProps {
  item: TemplateResult;
}

export default function CustomFieldRender(props: CustomFieldRenderProps) {
  const { item } = props;

  return (
    <li className="flex mb-2">
      <span className="w-32 truncate">{item.key}</span>
      <span className="flex-1 ml-4 break-all">{item.changeless_content}</span>
    </li>
  );
}
