import { useRef } from "react";
import { ImageIndicator } from "../../component/ImageIndicator";
import { IMAGE_OBSERVER_CLASS } from "ai-constants";
import { Button } from "antd";
import CustomTemplateTextExtraction from "./CustomTemplateTextExtraction";
import { OcrText } from "../../index.d";
import { CUSTOM_TEMPLATE_DATA, NONE_FEATURES } from "./constants";

interface SetupCustomTemplateDataProps {
  filename: string;
  textList: OcrText[];
  list: string[];
  enableOcrTextPick: boolean;
  captureType: string;
  nextText?: string;
  heightOffset?: number;
  previous: () => void;
  onSave: () => void;
  onAnchorConfirm: (text: OcrText, img: ImageData) => void;
  onCaptureStop: () => void;
  onCaptureConfirm: (data: ImageData, point: number[]) => void;
}
export default function SetupCustomTemplateData(
  props: SetupCustomTemplateDataProps
) {
  const {
    enableOcrTextPick,
    captureType,
    list,
    filename,
    textList,
    previous,
    heightOffset,
    nextText,
    onSave,
    onCaptureStop,
    onAnchorConfirm,
    onCaptureConfirm,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  let height = `calc(100vh - ${heightOffset || 320}px)`;
  return (
    <div className="w-full bg-white relative">
      <ImageIndicator list={list} filename={filename} container={ref} />
      <div className="overflow-hidden">
        <div
          ref={ref}
          style={{
            maxHeight: height,
            minHeight: height,
          }}
          className="mx-auto overflow-auto"
        >
          <ul className="mx-auto" style={{ width: 1024 }}>
            {list.map((item, index) => (
              <li
                className={IMAGE_OBSERVER_CLASS}
                key={item}
                data-src={item}
                data-page={index + 1}
              >
                <CustomTemplateTextExtraction
                  src={item}
                  enableOcrTextPick={enableOcrTextPick}
                  enableCapture={captureType !== NONE_FEATURES}
                  textList={textList}
                  onOcrTextPick={onAnchorConfirm}
                  onCaptureConfirm={onCaptureConfirm}
                  onCaptureStart={() => {}}
                  onCaptureStop={onCaptureStop}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sticky left-0 bottom-0 w-full flex">
        <div
          className="flex h-12 items-center px-6 justify-end w-full bg-white"
          style={{ boxShadow: "0 -2px 4px 0 #ccc" }}
        >
          <span className="flex-1">
            {/* <Button
              className="text-gray-700"
              onClick={openAdvanceSetting}
              type="text"
            >
              <SettingOutlined />
              高级设置
              <Tooltip title="当表格识别效果不佳时，如出现表格单元格分割位置不准确，可尝试高级设置调整识别效果">
                <span className="text-gray-600 ml-1">
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            </Button> */}
          </span>
          <span>
            <Button onClick={previous} className="mr-4">
              上一步
            </Button>
            <Button type="primary" onClick={onSave}>
              {nextText || "下一步"}
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
