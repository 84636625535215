import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
const lib = [
    '#1EBCB2',
    '#077070',
    '#002124',
    '#52C41A',
    '#237804',
    '#092B00',
    '#A0D911',
    '#5B8C00',
    '#254000',
    '#1890FF',
    '#0050B3',
    '#002766',
    '#722ED1',
    '#391085',
    '#120338',
    '#2F54EB',
    '#10239E',
    '#030852',
    '#FF4D4F',
    '#CF1322',
    '#820014',
    '#FA541C',
    '#AD2102',
    '#610B00',
    '#EB2F96',
    '#9E1068',
    '#520339',
    '#FA8C16',
    '#AD4E00',
    '#612500',
    '#FAAD14',
    '#AD6800',
    '#613400',
    '#FADB14',
    '#AD8B00',
    '#614700',
];
export default function ColorLibrary(props) {
    const { onChange, className, color } = props;
    function onClick(color) {
        onChange(color);
    }
    return (React.createElement("ul", { className: className }, lib.map((item) => (React.createElement("li", { key: item, onClick: () => onClick(item), className: "text-center w-4 h-4 mx-1 mb-2 text-base leading-none", style: { backgroundColor: item } },
        React.createElement(CheckOutlined, { style: { color: item === color ? 'white' : item } }))))));
}
