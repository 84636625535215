import React from 'react';
import { atom, useRecoilState } from 'recoil';
import { enablePermissionCheck } from './featureFlags';

export const permissionState = atom({
  key: 'permissionState',
  default: -1,
});

export enum Permission {
  VIEW = 1,
  MANAGE,
}

interface PermissionGuard {
  mode: Permission;
  fallback?: JSX.Element;
}

export function permissionGuard<T>(Foo: React.FC<T>) {
  return function Guard(props: T & PermissionGuard) {
    const { mode, fallback } = props;
    const [current] = useRecoilState(permissionState);
    const has = current === mode;
    if (!enablePermissionCheck || has) {
      return <Foo {...props} />;
    }
    if (fallback) {
      return fallback;
    }
    return null;
  };
}
